import React, { Component } from "react";
import MyProfile from "Models/MyProfile";
import socket from "Helpers/Socket";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import StartLiveAuction from './StartLiveAuction';
import ScheduleLiveAuction from "./ScheduleLiveAuction";
import UpcomingLiveScheduleList from "./UpcomingLiveScheduleList";
import DisputeLiveAuction from "./DisputeLiveAuction";
import BoughtLiveAuction from "./BoughtLiveAuction";
import AchievementLiveAuction from "./AchievementLiveAuction";

export class AuctionShow extends Component {

  constructor(props) {

    super(props);

    this.state = {
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      socket: null,
      highestBidder: "",
      broughtLiveProductsCount: 0,
      soldLiveProductsCount: 0,
      disputeLiveProductsCount: 0,
      achievementLiveProductsCount: 0,
      upcomingLiveScheduleCount: 0,
      upcomingLiveStreamingTime: "",
      upcomingStreamingAds: [],
      completedStreamingAds: [],
      upcomingLiveSchedule: [],
      totalStartLiveAuction: 0,
      tabView: "start_live_auction",
      showLiveStreaming: false,
      editLiveSchedule: false,
      showStartLiveStreaming: false,
      boughtAuctionProducts: [],
      soldAuctionProducts: [],
      disputeAuctionProducts: [],
      achievementProducts: [],
      isLoading: false,
      loading: true,
    }

  }

  componentDidMount = () => {
    this.getLiveStreamingProducts();
    this.getAllScheduleProductCounters();
    socket.on("orderStatusHandlerResponse", (data) => {
      if (data) {
        console.log("tabView", this.state.tabView)
        this.handleTabes(this.state.tabView)
      }
    });
  };

  getLiveStreamingProducts = () => {
    socket.emit("GetUserUpcomingLiveStreaming", {
      userId: MyProfile.getAuthUserId(),
    });
    socket.on("GetUserUpcomingLiveStreamingResponse", (data1) => {
      if (data1 && data1.length > 0) {

        socket.emit("getLiveStreamingMulti", { id: data1 });
        socket.on("getLiveStreamingResponseMulti", async (respo) => {
          if (respo && respo.length > 0) {
            var upcomingStreamings = [];
            var completedStreamings = [];
            let totalStartLiveAuction = 0;
            for (let i = 0; i < respo.length; i++) {
              const data = respo[i];
              var parseTime = Date.parse(data.time);
              const streamingTime = new Date(parseTime);
              const streamingEndTime = new Date(parseTime + data.duration * 60 * 1000);
              data.streamingEndTime = streamingEndTime;
              data.streamingTime = streamingTime;
              data.adLengthInSeconds = data.duration * 60;
              data.liveStreamingId = data._id;

              // Before 10 mins :: Start
              const beforeTenMinutes = new Date(parseTime - 10 * 60 * 1000);

              const liveStreamingCompleteTime = new Date(
                parseTime + data.duration * 60 * 1000
              );

              if (new Date() > beforeTenMinutes) {
                data.showStartLiveStreaming = true;
              } else {
                data.showStartLiveStreaming = false;
              }
              // Before 10 mins :: End

              // Before 5 mins :: Start
              const beforeFiveMinutes = new Date(parseTime - 5 * 60 * 1000);
              if (new Date() > beforeFiveMinutes) {
                data.showStartLiveStreaming = true;
              }
              // Before 5 mins :: End

              // After LiveStreaming Complete:: Start

              if (new Date() > liveStreamingCompleteTime) {
                data.showStartLiveStreaming = false;
              }

              if (new Date() > streamingTime && new Date() < liveStreamingCompleteTime) {
                totalStartLiveAuction += 1;
              }
              // After LiveStreaming Complete:: End

              let endTime = data.streamingTime;

              let diff = Number(new Date(endTime).getTime()) - Number(Date.now());

              data.timerTimeStampMiliSeconds = Number(Date.now()) + Number(diff);

              if (new Date() <= streamingEndTime) {
                upcomingStreamings.push(data);
              } else {
                completedStreamings.push(data);
              }

              this.setState({
                upcomingStreamingAds: upcomingStreamings,
                completedStreamingAds: completedStreamings,
                totalStartLiveAuction: totalStartLiveAuction
              });
            }
          } else {
            this.setState({
              upcomingStreamingAds: [],
              completedStreamingAds: [],
              upcomingLiveStreamingTime: "",
              showLiveStreaming: false,
              showStartLiveStreaming: false,
              highestBidder: "",
              totalStartLiveAuction: 0
            });
          }
        });
      } else {
        this.setState({
          upcomingStreamingAds: [],
          completedStreamingAds: [],
          upcomingLiveStreamingTime: "",
          showLiveStreaming: false,
          showStartLiveStreaming: false,
          highestBidder: "",
        });
      }
    });
  };

  getAllScheduleProducts = () => {
    MyProfile.getApi(
      "API_GET_LIVE_SCHEDULE",
      async (response) => {
        this.setState({ upcomingLiveSchedule: response.upcomingLiveSchedule });
        this.setState({ isLoading: false });
      },
      true
    );
  };

  getAllScheduleProductCounters = () => {

    MyProfile.postApiByParams(
      "API_LIVE_AUCTIONS_COUNT_BY_ID",
      {},
      async (res) => {
        if (res) {
          this.setState({
            broughtLiveProductsCount: res.data.boughtCount,
            soldLiveProductsCount: res.data.soldCount,
            disputeLiveProductsCount: res.data.disputeCount,
            achievementLiveProductsCount: res.data.achievementCount,
            upcomingLiveScheduleCount: res.data.upcomingLiveScheduleCount,
          });
        }
      },
      true
    );
  };

  handleTabes = (tab) => {
    if (tab === "bought_auction") {
      this.getAllBoughtAuctionProducts();
    } else if (tab === "sold_auction") {
      this.getAllSoldAuctionProducts();
    } else if (tab === "dispute_auction") {
      this.getAllDisputeAuctionProducts();
    } else if (tab === "achievement_auction") {
      this.getAllAchievementAuctionProducts();
    }

    this.setState({
      tabView: tab
    });
  }

  getAllBoughtAuctionProducts = () => {
    MyProfile.postApi(
      "API_GET_USER_BOUGHT_AUCTION_PRODUCTS",
      {},
      async (response) => {
        this.setState({
          boughtAuctionProducts: response.boughtAuctionProducts,
        });
        this.setState({ isLoading: false });
      },
      true
    );
  };


  getAllSoldAuctionProducts = () => {
    MyProfile.postApi(
      "API_GET_USER_SOLD_AUCTION_PRODUCTS",
      {},
      async (response) => {
        this.setState({
          soldAuctionProducts: response.soldAuctionProducts,
        });
        this.setState({ isLoading: false });
      },
      true
    );
  };

  getAllDisputeAuctionProducts = () => {
    MyProfile.postApi(
      "API_GET_USER_DISPUTE_AUCTION_PRODUCTS",
      {},
      async (response) => {
        this.setState({
          disputeAuctionProducts: response.disputeAuctionProducts,
        });
        this.setState({ isLoading: false });
      },
      true
    );
  };

  getAllAchievementAuctionProducts = () => {
    MyProfile.postApi(
      "API_GET_USER_ACHIVEMENT_AUCTION_PRODUCTS",
      {},
      async (response) => {
        this.setState({
          achievementProducts: response.achievementProducts,
        });
        this.setState({ isLoading: false });
      },
      true
    );
  };
  // function AccordionUsage() 

  render() {
    const { tabView,
      upcomingStreamingAds,
      broughtLiveProductsCount,
      soldLiveProductsCount,
      disputeLiveProductsCount,
      achievementLiveProductsCount,
      upcomingLiveScheduleCount,
      boughtAuctionProducts,
      soldAuctionProducts,
      disputeAuctionProducts,
      achievementProducts,
      loading
    } = this.state;

    return (
      <>
        <div className="tab-pane active" id="subtab02" role="tabpanel">
          <div className="subtab_prodlist_NEW subtab_prodlist_NEW_forpadd paddint_toppage06 ">

            <div className="container">
              <div className="row">
                <div className="col-lg-3 subtab_firstmain paddingrigt_none_Cfilter desktop_view">
                  <div className="sidebarleft_newinner">

                    <div className="sideBar-category sideTabMenu">
                      <Accordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel2a-header"
                          className="product-listing-sideBar-title"
                        >
                          <strong>Schedule</strong>
                        </AccordionSummary>
                        <AccordionDetails>
                          <ul className="toggle-bx nav nav-tabs mt-0 ">
                            <li
                              className={
                                tabView ==
                                  "start_live_auction"
                                  ? "active show"
                                  : ""
                              }
                            >
                              <a
                                data-toggle="tab"
                                onClick={() => this.handleTabes('start_live_auction')}
                              >
                                Start Live Auction ({this.state.totalStartLiveAuction})
                              </a>
                            </li>
                            <li
                              className={
                                tabView ==
                                  "schedule_live_auction"
                                  ? "active show"
                                  : ""
                              }
                            >
                              <a
                                data-toggle="tab"
                                onClick={() => this.handleTabes('schedule_live_auction')}
                              >
                                Schedule Live Auction{""}
                              </a>
                            </li>
                            <li
                              className={
                                tabView ==
                                  "upcoming_auction"
                                  ? "active show"
                                  : ""
                              }
                            >
                              <a
                                data-toggle="tab"
                                onClick={() => this.handleTabes('upcoming_auction')}
                              >
                                My Upcoming Auction ({upcomingStreamingAds.length + upcomingLiveScheduleCount})
                              </a>
                            </li>
                            <li
                              className={
                                tabView ==
                                  "bought_auction"
                                  ? "active show"
                                  : ""
                              }
                            >
                              <a
                                href="javascript:void(0)"
                                data-toggle="tab"
                                onClick={() => this.handleTabes('bought_auction')}
                              >
                                Bought ({broughtLiveProductsCount})
                              </a>
                            </li>
                            <li
                              className={
                                tabView ==
                                  "sold_auction"
                                  ? "active show"
                                  : ""
                              }
                            >
                              <a
                                href="javascript:void(0)"
                                data-toggle="tab"
                                onClick={() => this.handleTabes('sold_auction')}
                              >
                                Sold ({soldLiveProductsCount})
                              </a>
                            </li>
                            <li
                              className={
                                tabView ==
                                  "dispute_auction"
                                  ? "active show"
                                  : ""
                              }
                            >
                              <a href="javascript:void(0)"
                                data-toggle="tab"
                                onClick={() => this.handleTabes('dispute_auction')}
                              >
                                Dispute ({disputeLiveProductsCount})
                              </a>
                            </li>
                            <li
                              className={
                                tabView ==
                                  "achievement_auction"
                                  ? "active show"
                                  : ""
                              }
                            >
                              <a
                                href="javascript:void(0)"
                                data-toggle="tab"
                                onClick={() => this.handleTabes('achievement_auction')}
                              >
                                Achievement ({achievementLiveProductsCount})
                              </a>
                            </li>
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    </div>



                    {/* <div className="product-listing-sideBar-colum sideBar-location">
                    <strong>Schedule</strong>
                    </div> */}
                  </div>{" "}
                </div>

                <div className="col-lg-9 subtab_secondmain">
                  <div className="fillter_box">
                    <div
                      id="filter-toggler"
                      className={
                        tabView ==
                          "start_live_auction"
                          ? "btnx btn-secondaryx mx-2 active"
                          : "btnx btn-secondaryx mx-2 btn-inactive"
                      }
                      onClick={() => this.handleTabes('start_live_auction')}
                    >
                      Start Live Auction ({this.state.totalStartLiveAuction})
                    </div>
                    <div
                      id="filter-toggler"
                      className={
                        tabView ==
                          "schedule_live_auction"
                          ? "btnx btn-secondaryx mx-2 active"
                          : "btnx btn-secondaryx mx-2 btn-inactive"
                      }
                      onClick={() => this.handleTabes('schedule_live_auction')}
                    >
                      Schedule Live Auction{""}
                    </div>
                    <div
                      id="filter-toggler"
                      className={
                        tabView ==
                          "upcoming_auction"
                          ? "btnx btn-secondaryx mx-2 active"
                          : "btnx btn-secondaryx mx-2 btn-inactive"
                      }
                      onClick={() => this.handleTabes('upcoming_auction')}
                    >
                      My Upcoming Auction ({upcomingStreamingAds.length + upcomingLiveScheduleCount})
                    </div>
                    <div
                      id="filter-toggler"
                      className={
                        tabView ==
                          "bought_auction"
                          ? "btnx btn-secondaryx mx-2 active"
                          : "btnx btn-secondaryx mx-2 btn-inactive"
                      }
                      onClick={() => this.handleTabes('bought_auction')}
                    >
                      Bought ({broughtLiveProductsCount})
                    </div>
                    <div
                      id="filter-toggler"
                      className={
                        tabView ==
                          "sold_auction"
                          ? "btnx btn-secondaryx mx-2 active"
                          : "btnx btn-secondaryx mx-2 btn-inactive"
                      }
                      onClick={() => this.handleTabes('sold_auction')}
                    >
                      Sold ({soldLiveProductsCount})
                    </div>
                    <div
                      id="filter-toggler"
                      className={
                        tabView ==
                          "dispute_auction"
                          ? "btnx btn-secondaryx mx-2 active"
                          : "btnx btn-secondaryx mx-2 btn-inactive"
                      }
                      onClick={() => this.handleTabes('dispute_auction')}
                    >
                      Dispute ({disputeLiveProductsCount})
                    </div>
                    <div
                      id="filter-toggler"
                      className={
                        tabView ==
                          "achievement_auction"
                          ? "btnx btn-secondaryx mx-2 active"
                          : "btnx btn-secondaryx mx-2 btn-inactive"
                      }
                      onClick={() => this.handleTabes('achievement_auction')}
                    >
                      Achievement ({achievementLiveProductsCount})
                    </div>

                  </div>
                  <div className="formcontdetail_inner mt-4 mt-md-0">
                    <div className="tab-content subtab_tabcont">
                      {(tabView === 'start_live_auction') ?
                        <StartLiveAuction upcomingStreamingAds={upcomingStreamingAds} handleTabes={this.handleTabes} getLiveStreamingProducts={this.getLiveStreamingProducts} />
                        : (tabView === 'schedule_live_auction') ?
                          <ScheduleLiveAuction getAllScheduleProducts={this.getAllScheduleProducts} getLiveStreamingProducts={this.getLiveStreamingProducts} handleTabes={this.handleTabes} />
                          : (tabView === 'upcoming_auction') ?
                            <UpcomingLiveScheduleList getAllScheduleProducts={this.getAllScheduleProducts} getLiveStreamingProducts={this.getLiveStreamingProducts} />
                            : (tabView === 'bought_auction') ?
                              <div
                                className="tab-pane active"
                                role="tabpanel"
                              >
                                {boughtAuctionProducts &&
                                  boughtAuctionProducts.length > 0 ? (
                                  boughtAuctionProducts.map((product) => (
                                    <BoughtLiveAuction
                                      productData={product}
                                      loginUser={this.state.id}
                                      canEdit={false}
                                      key={product._id}
                                    />
                                  ))
                                ) : (
                                  <div className="alert alert-danger col-lg-12">
                                    <strong>Info!</strong> Nothing here.
                                  </div>
                                )}
                              </div>
                              : (tabView === 'sold_auction') ?
                                <div
                                  className="tab-pane active"
                                  role="tabpanel"
                                >
                                  {soldAuctionProducts &&
                                    soldAuctionProducts.length > 0 ? (
                                    soldAuctionProducts.map((product) => (
                                      <BoughtLiveAuction
                                        productData={product}
                                        loginUser={this.state.userId}
                                        canEdit={false}
                                        key={`sold_${product._id}`}
                                      />
                                    ))
                                  ) : (
                                    <div className="alert alert-danger col-lg-12">
                                      <strong>Info!</strong> Nothing here.
                                    </div>
                                  )}
                                </div>
                                : (tabView === 'dispute_auction') ?
                                  <div
                                    className="tab-pane active"
                                    role="tabpanel"
                                  >
                                    {disputeAuctionProducts &&
                                      disputeAuctionProducts.length > 0 ? (
                                      disputeAuctionProducts.map((product) => (
                                        <DisputeLiveAuction
                                          productData={product}
                                          loginUser={this.state.userId}
                                          canEdit={false}
                                          key={product._id}
                                        />
                                      ))
                                    ) : (
                                      <div className="alert alert-danger col-lg-12">
                                        <strong>Info!</strong> Nothing here.
                                      </div>
                                    )}
                                  </div>
                                  : <div
                                    className="tab-pane active"
                                    role="tabpanel"
                                  >
                                    {achievementProducts &&
                                      achievementProducts.length > 0 ? (
                                      achievementProducts.map((product) => (
                                        <AchievementLiveAuction
                                          productData={product}
                                          loginUser={this.state.userId}
                                          canEdit={false}
                                          key={product._id}
                                        />
                                      ))
                                    ) : (
                                      <div className="alert alert-danger col-lg-12">
                                        <strong>Info!</strong> Nothing here.
                                      </div>
                                    )}
                                  </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AuctionShow;
