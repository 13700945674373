import React, { Component } from "react";
import Page from "Models/Page";

class AboutUsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      aboutUSContent: localStorage.getItem('aboutUSContent')?JSON.parse(localStorage.getItem('aboutUSContent')):[],
      ourMissionContent: localStorage.getItem('ourMissionContent')?JSON.parse(localStorage.getItem('ourMissionContent')):[],
      whyChooseContent: localStorage.getItem('whyChooseContent')?JSON.parse(localStorage.getItem('whyChooseContent')):[],
    }
  }

  componentWillMount = () => {
      var self = this;

      Page.getApiByParams("APIS_GET_CMS_PAGE",'about-us',async(response)=>{
        localStorage.setItem('aboutUSContent', JSON.stringify(response));
        self.setState({aboutUSContent:response});
      }, false);

      Page.getApiByParams("APIS_GET_CMS_PAGE",'our-mission',async(response)=>{
        localStorage.setItem('ourMissionContent', JSON.stringify(response));
        self.setState({ourMissionContent:response});
      }, false);

      Page.getApiByParams("APIS_GET_CMS_PAGE",'why-choose',async(response)=>{
        localStorage.setItem('whyChooseContent', JSON.stringify(response));
        self.setState({whyChooseContent:response});
      }, false);
  }

  render() {
    const {aboutUSContent, ourMissionContent, whyChooseContent } = this.state;
    return (
      <React.Fragment>        
        {/* <section className="abouttop_banner">
          <div className="image_abtbanner">
            <img src="/images/about-banner01.png" alt="Image"/>
            {
              aboutUSContent && (
                <div className="aboutbcont">
                <h2 className="bannerheading">{aboutUSContent.pageTitle}</h2>
                <div
                    dangerouslySetInnerHTML={{
                      __html: aboutUSContent.pageDescription
                    }}></div>
              </div>
              )
            }            
          </div>
        </section> */}
        <section className="repeat-section abouttop_sectwo">
          <div className="container">
          <div className="about_section">
            {
              ourMissionContent && (
                <div className="row rowfirst_about">
                  <div className="col-sm-4 display-contact d-none  d-md-block">
                    <img src="/images/nolongthing-02.png" alt="Image"/>
                  </div>
                  <div className="col-sm-8">
                    <h2 className="sectionhead mobile-font-size-18">{ourMissionContent.pageTitle}</h2>
                    <div className="about-description"
                      dangerouslySetInnerHTML={{
                        __html: ourMissionContent.pageDescription
                      }}></div>
                  </div>
                </div>
              )
            }
            {
              whyChooseContent && (
                <div className="row rowsec_about">
                  <div className="col-sm-8">
                    <h2 className="sectionhead mobile-font-size-18">{whyChooseContent.pageTitle}</h2>
                    <div className="about-description"
                      dangerouslySetInnerHTML={{
                        __html: whyChooseContent.pageDescription
                      }}></div>
                  </div>
                  <div className="col-sm-4 display-contact d-none d-md-block">
                    <img src="/images/nolongthing-03.png" alt="Images"/>
                  </div>
                </div> 
              )
            }  
            

            </div>



          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default AboutUsComponent;
