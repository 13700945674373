import React, { Component } from "react";
import General from "Helpers/General";
import Product from "Models/Product";
import CategorySearch from "Models/CategorySearch";
import Category from "Models/Category";
import SubCategory from "Models/SubCategory";
import { Link } from "react-router-dom";
import Fields from "Models/Fields";
import SlideToggle from "react-slide-toggle";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ContentLoader from "react-content-loader";
import Countdown from "react-countdown";
import Moment from "react-moment";
import moment from "moment";
import { Rating } from 'react-simple-star-rating'
import PlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from "react-places-autocomplete";

class AuctionList extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    const categoryslug = props.categoryslug;
    const subCategoryListLS = "subCategoryList_" + categoryslug;
    const allCatFilterProductsLS = "allCatFilterProducts_" + categoryslug;
    const singleCategoryDataLS = "singleCategoryData_" + categoryslug;
    const totalRecordsLS = "totalRecords_" + categoryslug;
    const categoriesLoopData = localStorage.getItem("categoriesLoopData")
      ? JSON.parse(localStorage.getItem("categoriesLoopData"))
      : [];
    const fieldsLoopData = localStorage.getItem("fieldsLoopData")
      ? JSON.parse(localStorage.getItem("fieldsLoopData"))
      : [];
    const categoryFieldData = localStorage.getItem("CategoriesFieldData")
      ? JSON.parse(localStorage.getItem("CategoriesFieldData")).find((cat) => {
        return cat.slug === categoryslug;
      })
      : null;
    const fields =
      categoryFieldData && categoryFieldData.fields
        ? categoryFieldData.fields.filter((field) => field.isMain === true)
        : [];
    const subCat = localStorage.getItem("PostCategories")
      ? Object.assign(
        {},
        ...JSON.parse(localStorage.getItem("PostCategories")).filter(
          (cat) => cat.slug === categoryslug
        )
      )
      : {};

    this.state = {
      categoryFilers: CategorySearch.dbSchema(),
      categoryCustomFilers: {},
      categoryslug: categoryslug,
      singleCategoryData: subCat,
      subCategoryList: [],
      allCustomFields: fields,
      allCatFilterProducts: [],
      totalRecords: localStorage.getItem(totalRecordsLS)
        ? localStorage.getItem(totalRecordsLS)
        : "0",
      categoryId: "",
      showHideListData: false,
      nothingD: false,
      showHideGridData: true,
      productSkeleton: [
        { id: 0 },
        { id: 340 },
        { id: 680 },
        { id: 1020 },
        { id: 1360 },
      ],
      loadMoreSubCat: false,
      loadAllSubCatBtn: true,
      openProductFilter: false,
      userLoginId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
    };

    // Bind the handlers
    this.handleCatMinPrice = this.handleCatMinPrice.bind(this);
    this.handleCatMaxPrice = this.handleCatMaxPrice.bind(this);

    // Create debounced versions of the handlers
    this.debouncedHandleCatMinPrice = General.debounce(this.handleCatMinPrice, 500);
    this.debouncedHandleCatMaxPrice = General.debounce(this.handleCatMaxPrice, 500);

    this.hideComponent = this.hideComponent.bind(this);
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handlePriceData = this.handlePriceData.bind(this);
  }

  hideComponent(name) {
    switch (name) {
      case "showHideListData":
        this.setState({ showHideListData: !this.state.showHideListData });
        this.setState({ showHideGridData: false });
        break;
      case "showHideGridData":
        this.setState({ showHideGridData: !this.state.showHideGridData });
        this.setState({ showHideListData: false });
        break;
      default:
        this.setState({ showHideListData: !this.state.showHideListData });
        break;
    }
  }

  componentDidMount = () => {
    var self = this;
    const singleCategoryDataLS =
      "singleCategoryData_" + self.state.categoryslug;
    let categoryslug = this.state.categoryslug;
    Category.postApi(
      "APIS_GET_CATEGORY_DETAIL",
      { params: { categoryslug } },
      async (response) => {
        localStorage.setItem(singleCategoryDataLS, JSON.stringify(response));
        self.setState({ singleCategoryData: response });
        self.setState({ categoryId: response._id });
        let { categoryFilers } = this.state;
        categoryFilers["categoryId"] = "";
        // categoryFilers["categoryId"] = this.state.singleCategoryData._id; 
        self.setState({ categoryFilers: categoryFilers });
        this.getDataByfilters();
        this.getCategoryByCatId();
      },
      false
    );
    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.addEventListener('click', this.handleOverlayClick);
    }
  };

  handleOverlayClick() {
    document.body.classList.remove('filter-open');
    this.setState({ openProductFilter: false });
  }

  componentWillUnmount() {
    const overlayElement = document.querySelector('.nodataOverlay');
    if (overlayElement) {
      overlayElement.removeEventListener('click', this.handleOverlayClick);
    }
  }

  getCategoryByCatId() {
    var self = this;
    this.mounted = true;
    SubCategory.postApiByParams(
      "APIS_GET_AUCTION_CATEGORY_BY_CAT_ID",
      { id: this.state.categoryId },
      async (response, status) => {
        if (status == "success") {
          self.setState({ subCategoryList: response.data.subCatData });
        }
      },
      false
    );
  }

  getDataByfilters() {
    var self = this;
    // if (localStorage.getItem("location")) {
    //   let { categoryFilers } = this.state;
    //   categoryFilers["lat"] = JSON.parse(localStorage.getItem("location"))[
    //     "latitude"
    //   ];
    //   categoryFilers["lng"] = JSON.parse(localStorage.getItem("location"))[
    //     "longitude"
    //   ];
    //   this.setState({ categoryFilers: categoryFilers });
    // }
    if (this.props.lat && this.props.lng) {
      let { categoryFilers } = this.state;
      categoryFilers["lat"] = this.props.lat;
      categoryFilers["lng"] = this.props.lng;
      this.setState({ categoryFilers: categoryFilers });
    }
    if (this.props.keyword) {
      let { categoryFilers } = this.state;
      categoryFilers["keyword"] = this.props.keyword;
      this.setState({ categoryFilers: categoryFilers });
    }

    if (Product.isLogin()) {
      let { categoryFilers } = this.state;
      categoryFilers["user_id"] = Product.getAuthUserId();
      this.setState({ categoryFilers: categoryFilers });
    }
    this.setState({ openProductFilter: false });
    const allCatFilterProductsLS =
      "allCatFilterProducts_" + self.state.categoryslug;
    const totalRecordsLS = "totalRecords_" + self.state.categoryslug;
    Product.postApiByParams(
      "APIS_GET_PRODUCTS_BY_AUCTION_CATEGORY_FILTERS",
      this.state.categoryFilers,
      async (response, status) => {
        if (response.status) {
          localStorage.setItem(
            allCatFilterProductsLS,
            JSON.stringify(response.data)
          );
          localStorage.setItem(totalRecordsLS, response.totalRecords);
          self.setState({ allCatFilterProducts: response.data });
          self.setState({ totalRecords: response.totalRecords });
          self.setState({ next_page_number: response.next_page_number });
          self.setState({ limit: response.limit });
          self.setState({ nothingD: true });
        }
      },
      false
    );
  }

  loadAllSubCategories() {
    const { loadMoreSubCat } = this.state;
    const { loadAllSubCatBtn } = this.state;
    this.setState({
      loadMoreSubCat: !loadMoreSubCat,
      loadAllSubCatBtn: !loadAllSubCatBtn,
    });
  }

  getDatabyPageno(next_page_number) {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["page_number"] = next_page_number;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  }

  getFltersdataFetch = (event) => {
    var keyType = event.currentTarget.dataset.name;
    var fValue = "";
    if (event.currentTarget.dataset.type == "Dropdown") {
      var fValue = event.currentTarget.dataset.id;
    } else {
      var fValue = event.target.value;
    }
    var self = this;
    let { categoryCustomFilers } = this.state;
    categoryCustomFilers["customFieldData." + keyType] = fValue;

    let { categoryFilers } = this.state;
    categoryFilers["customFields"] = categoryCustomFilers;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleChange = (address) => {
    this.setState({ address });
    if (!address) {
      let { categoryFilers } = this.state;
      categoryFilers["location"] = "";
      categoryFilers["lat"] = "";
      categoryFilers["lng"] = "";
      this.getDataByfilters();
    }
  };

  handleSelect = (address) => {
    geocodeByAddress(address).then((results) => {
      const address = results[0].formatted_address;
      var self = this;
      self.setState({ address });
      let { categoryFilers } = this.state;
      categoryFilers["location"] = address;
      self.setState({ categoryFilers: categoryFilers });
      getLatLng(results[0])
        .then((latLng) => {
          var self = this;
          let { categoryFilers } = this.state;
          categoryFilers["lat"] = latLng.lat;
          categoryFilers["lng"] = latLng.lng;
          self.setState({ categoryFilers: categoryFilers });
        })
        .catch((error) => console.error("Error", error));
    });
  };

  handleCatKeyword = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["keyword"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleFilterSorting = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["sortType"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
    this.handleOverlayClick();
  };

  handleRadiusFilter = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["radius"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
  };

  handleCatMinPrice = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["min_price"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
    // this.getDataByfilters();
  };

  handleCatMaxPrice = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["max_price"] = event.target.value;
    self.setState({ categoryFilers: categoryFilers });
    // this.getDataByfilters();
  };

  handlePriceData = () => {
    this.getDataByfilters();
    this.handleOverlayClick();
  }

  handleCatNegotiation = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["avaliableNegotiation"] = event.target.checked;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
    this.handleOverlayClick();
  };

  handleExchange = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["avaliableExchange"] = event.target.checked;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
    this.handleOverlayClick();
  };

  handleLiveActionSorting = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers[event.target.name] = event.target.checked;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
    this.handleOverlayClick();
  };

  handleLeasing = (event) => {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["availableLeasing"] = event.target.checked;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  };

  handleCatSubCategory(subCategoryId) {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["subCategoryId"] = subCategoryId;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  }

  handleCatSellerType(sellerType) {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["sellerType"] = sellerType;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  }

  handleCatAdType(adType) {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["adType"] = adType;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  }

  handleCatrecruiterType(recruiterType) {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["recruiterType"] = recruiterType;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  }

  handleCatproviderType(providerType) {
    var self = this;
    let { categoryFilers } = this.state;
    categoryFilers["providerType"] = providerType;
    self.setState({ categoryFilers: categoryFilers });
    this.getDataByfilters();
  }

  addWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_ADD_TO_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  removeWishlistHandler = (id) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_REMOVE_FROM_WISHLIST",
        { productId: id },
        async (response) => {
          this.getDataByfilters();
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  getCategoryCount = (catId) => {
    if (localStorage.getItem("subCategoriesProductCount")) {
      const catData = JSON.parse(
        localStorage.getItem("subCategoriesProductCount")
      ).find((cat) => cat._id === catId);

      if (catData) {
        return catData.productCount;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  };

  render() {
    var context = this;
    const {
      showHideListData,
      showHideGridData,
      allCatFilterProducts,
      singleCategoryData,
      totalRecords,
      subCategoryList,
      allCustomFields,
      productSkeleton,
    } = this.state;

    return (
      <React.Fragment>
        <section className="product-header special_prheadr_new">
          <div className="container">
            <div className="row justify-content-between align-items-center ad-track">
              <div className="col-md-6 col-12">
                <div className="breadcrumb-bx">
                  <h2>
                    {totalRecords} Items in {singleCategoryData.categoryName}
                  </h2>
                  <div className="breadcrumb-bx-inner">
                    <Link to="/" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </Link>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li className="breadcrumb-item">
                        {singleCategoryData.categoryName}
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div className="col-5 d-none d-md-block">
                <div className="new_placetab_icon">
                  <div className="form-group-icon-bx">
                    <select
                      className="form-control-line"
                      onChange={context.handleFilterSorting}
                    >
                      <option value="">Most Recent First</option>
                      <option value="LowToHigh">Price: Low to High</option>
                      <option value="HighToLow">Price: High to Low</option>
                      <option value="RatingLowToHigh">Rating: Low to High</option>
                      <option value="RatingHighToLow">Rating: High to Low</option>
                    </select>
                    <span className="form-group-icon">
                      <img src="/images/down-arrow-icon.png" alt="icon" />
                    </span>
                  </div>
                  <div className="showing-result">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="repeat-section product-listing-main-bx bg-light-dark forsalepage_listing">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 paddingrigt_none_Cfilter">
                <div
                  className={`product-sideBar-parent new_perenttop_forspecals ${this.state.openProductFilter ? "active" : ""
                    }`}
                >
                  <div className="product-listing-sideBar desktop_fillter" id="side-bar-fixed">
                    <div className="sidebarleft_newinner">
                      <div id="filter-closer">
                        <img
                          src="/images/cancel.svg"
                          alt="icon"
                          onClick={() =>
                            this.setState({
                              openProductFilter: false,
                            })
                          }
                        />
                      </div>
                      <div className="filter-clear">
                        <button
                          className="btn btn-sm"
                          onClick={() =>
                            window.location.reload()
                          }
                        >
                          Clear Filter
                        </button>
                      </div>
                      <div className="product-listing-sideBar-colum sideBar-location">
                        <h2>Location</h2>
                        <div className="form-group">
                          <PlacesAutocomplete
                            searchOptions={{
                              componentRestrictions: {
                                country: "UK",
                              },
                            }}
                            value={this.state.address}
                            onChange={this.handleChange}
                            onSelect={this.handleSelect}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "location-search-input form-control-line",
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion) => {
                                    const className = suggestion.active
                                      ? "suggestion-item--active"
                                      : "suggestion-item";
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: "#fafafa",
                                        cursor: "pointer",
                                      }
                                      : {
                                        backgroundColor: "#ffffff",
                                        cursor: "pointer",
                                      };
                                    return (
                                      <div
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                        key={suggestion.placeId}
                                      >
                                        <span>{suggestion.description}</span>
                                      </div>
                                    );
                                  })}
                                </div>
                                <div class="form-group mb-0 sideBar-choose-distance">
                                  <div class="form-group-icon-bx">
                                    <input
                                      type="number"
                                      name="radius"
                                      onChange={context.handleRadiusFilter}
                                      class="form-control-line"
                                      placeholder="Distance"
                                    />

                                  </div>
                                  <button
                                    class="go-btn"
                                    onClick={context.handleCatNegotiation}
                                  >
                                    GO
                                  </button>
                                </div>
                              </div>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                      <div className="product-listing-sideBar-colum sideBar-category">
                        <h2>Category</h2>
                        <ul class="toggletab-bx">
                          <li className="innerfilt_topcat_perent">
                            <span>
                              <i className="fas fa-angle-double-left"></i>
                            </span>{" "}
                            <a href={`/category/${singleCategoryData.slug}`}>
                              {singleCategoryData.categoryName}
                            </a>

                            <ul className="innerfilt_topcat">
                              {this.state.subCategoryList.map(function (
                                rowsubcat,
                                i
                              ) {
                                return i <= 3 ? (
                                  <li>
                                    <a
                                      href={"/live-auction/" + rowsubcat.slug}
                                      id="GoFarm_machinery"
                                      className="goclass01"
                                      name="subCategoryId"
                                    >
                                      {" "}
                                      {rowsubcat.categoryName} (
                                      {rowsubcat.tasksCount})
                                    </a>
                                  </li>
                                ) : (
                                  ""
                                );
                              })}

                              {this.state.loadMoreSubCat &&
                                this.state.subCategoryList.map(function (
                                  rowsubcat,
                                  i
                                ) {
                                  return i > 3 ? (
                                    <li>
                                      <a
                                        href={"/live-auction/" + rowsubcat.slug}
                                        id="GoFarm_machinery"
                                        className="goclass01"
                                        name="subCategoryId"
                                      >
                                        {" "}
                                        {rowsubcat.categoryName} (
                                        {rowsubcat.tasksCount}
                                        )
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  );
                                })}
                              {this.state.subCategoryList.length > 4 &&
                                this.state.loadAllSubCatBtn ? (
                                <li>
                                  <a
                                    href
                                    name="load_all"
                                    onClick={context.loadAllSubCategories.bind(
                                      context
                                    )}
                                  >
                                    <strong>Load All</strong>
                                  </a>
                                </li>
                              ) : (
                                ""
                              )}
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div class="product-listing-sideBar-colum sideBar-category">
                        <h2>Price</h2>
                        <div class="row">
                          <div class="col-5 pr-1">
                            <div class="form-group">
                              <input
                                type="number"
                                name="min_price"
                                onChange={this.debouncedHandleCatMinPrice}
                                class="form-control-line"
                                placeholder="Min"
                              />
                            </div>
                          </div>
                          <div class="col-5 pr-1">
                            <div class="form-group">
                              <input
                                type="number"
                                name="max_price"
                                onChange={this.debouncedHandleCatMaxPrice}
                                class="form-control-line"
                                placeholder="Max"
                              />
                            </div>
                          </div>
                          <div className="col-2 px-0">
                            <button className="go-btn" onClick={context.handlePriceData}>GO</button>
                          </div>
                        </div>
                      </div>
                      {allCustomFields &&
                        allCustomFields.map(function (row) {
                          return (
                            <React.Fragment>
                              {row.fieldType == "Dropdown" ? (
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    {row.fieldName}
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <ul
                                      class="toggletab-bx nav nav-tabs toggle-bx"
                                      role="tablist"
                                    >
                                      {row.fieldValues.map(function (rowfield) {
                                        return (
                                          <li
                                            onClick={context.getFltersdataFetch}
                                            data-id={rowfield._id}
                                            data-name={row.slug}
                                            data-type={row.fieldType}
                                          >
                                            <a href>{rowfield.name}</a>
                                          </li>
                                        );
                                      })}
                                    </ul>
                                  </AccordionDetails>
                                </Accordion>
                              ) : (
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    {row.fieldName}
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <input
                                      type="text"
                                      onChange={context.getFltersdataFetch}
                                      data-id={row._id}
                                      data-name={row.slug}
                                      data-type={row.fieldType}
                                      class="form-control-line"
                                      placeholder={row.fieldName}
                                    />
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </React.Fragment>
                          );
                        })}
                      {context.state.categoryslug == "property" &&
                        context.state.categoryslug == "services" &&
                        context.state.categoryslug == "jobs" && (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel1a-content"
                              id="panel1a-header"
                            >
                              Ad Type
                            </AccordionSummary>
                            <AccordionDetails>
                              <ul
                                class="toggletab-bx nav nav-tabs toggle-bx"
                                role="tablist"
                              >
                                <li>
                                  <a
                                    href
                                    name="adType"
                                    onClick={context.handleCatAdType.bind(
                                      context,
                                      "Featured"
                                    )}
                                  >
                                    Featured
                                  </a>
                                </li>
                              </ul>
                            </AccordionDetails>
                          </Accordion>
                        )}

                      {context.state.categoryslug == "property" && (
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            Seller Type
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul
                              class="toggletab-bx nav nav-tabs toggle-bx"
                              role="tablist"
                            >
                              <li>
                                <a
                                  href
                                  name="sellerType"
                                  onClick={context.handleCatSellerType.bind(
                                    context,
                                    "Company"
                                  )}
                                >
                                  Company
                                </a>
                              </li>
                              <li>
                                <a
                                  href
                                  name="sellerType"
                                  onClick={context.handleCatSellerType.bind(
                                    context,
                                    "Private"
                                  )}
                                >
                                  Private
                                </a>
                              </li>
                              <li>
                                <a
                                  href
                                  name="sellerType"
                                  onClick={context.handleCatSellerType.bind(
                                    context,
                                    "Agent"
                                  )}
                                >
                                  Agent
                                </a>
                              </li>
                            </ul>
                          </AccordionDetails>
                        </Accordion>
                      )}
                    </div>
                    <div class="product-listing-sideBar-colum other-option otheropt_newcls">
                      <h2>Other Options</h2>
                      <div class="otheroption_cheperent ">
                        <div class="check-box">
                          <input
                            type="checkbox"
                            value="Yes"
                            name="liveAuction"
                            id="live-auction"
                            onClick={context.handleLiveActionSorting}
                          />
                          <label for="live-auction">
                            Live Auction
                          </label>
                        </div>
                        <div class="check-box">
                          <input
                            type="checkbox"
                            value="Yes"
                            name="upcomingLiveAuction"
                            id="upcoming-auction"
                            onClick={context.handleLiveActionSorting}
                          />
                          <label for="upcoming-auction">
                            Upcoming Live Auction
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="product-listing-sideBar mobile_fillter" id="side-bar-fixed">
                    <div className="fillter_preant">
                      <div className="sidebarleft_newinner test2">
                        <div id="filter-closer">
                          <img
                            src="/images/cancel.svg"
                            alt="icon"
                            onClick={() => {
                              this.setState({ openProductFilter: false });
                              document.body.classList.remove('filter-open');
                            }}
                          />
                        </div>
                        <div className="filter-clear">
                          <button
                            className="btn btn-sm"
                            onClick={() =>
                              window.location.reload()
                            }
                          >
                            Clear Filter
                          </button>
                        </div>
                        {this.state.filterKeyWord === "location" && <div className="product-listing-sideBar-colum sideBar-location">
                          <h2>Location</h2>
                          <div className="form-group">
                            <PlacesAutocomplete
                              searchOptions={{
                                componentRestrictions: {
                                  country: "UK",
                                },
                              }}
                              value={this.state.address}
                              onChange={this.handleChange}
                              onSelect={this.handleSelect}
                            >
                              {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                              }) => (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: "Search Places ...",
                                      className: "location-search-input",
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                      const className = suggestion.active
                                        ? "suggestion-item--active"
                                        : "suggestion-item";

                                      const style = suggestion.active
                                        ? {
                                          backgroundColor: "#fafafa",
                                          cursor: "pointer",
                                        }
                                        : {
                                          backgroundColor: "#ffffff",
                                          cursor: "pointer",
                                        };
                                      return (
                                        <div
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                          key={suggestion.placeId}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                  <div className="form-group mb-0 sideBar-choose-distance">
                                    <div className="form-group-icon-bx">
                                      <input
                                        type="number"
                                        name="radius"
                                        onChange={context.handleRadiusFilter}
                                        className="form-control-line"
                                        placeholder="Distance"
                                      />
                                    </div>
                                    <button
                                      className="go-btn"
                                      onClick={context.handleCatNegotiation}
                                    >
                                      GO
                                    </button>
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                          </div>
                        </div>}
                        {console.log("FilterKeyword", this.state.filterKeyWord)}
                        {this.state.filterKeyWord === "category" && <div className="product-listing-sideBar-colum sideBar-category">
                          <h2>Category</h2>
                          <ul class="toggletab-bx">
                            <li className="innerfilt_topcat_perent">
                              <span>
                                <i className="fas fa-angle-double-left"></i>
                              </span>{" "}
                              <a href={`/category/${singleCategoryData.slug}`} onClick={context.handleOverlayClick}>
                                {singleCategoryData.categoryName}
                              </a>

                              <ul className="innerfilt_topcat">
                                {this.state.subCategoryList.map(function (
                                  rowsubcat,
                                  i
                                ) {
                                  return i <= 3 ? (
                                    <li>
                                      <a
                                        href={"/live-auction/" + rowsubcat.slug}
                                        id="GoFarm_machinery"
                                        className="goclass01"
                                        name="subCategoryId"
                                        onClick={context.handleOverlayClick}
                                      >
                                        {" "}
                                        {rowsubcat.categoryName} (
                                        {rowsubcat.tasksCount})
                                      </a>
                                    </li>
                                  ) : (
                                    ""
                                  );
                                })}

                                {this.state.loadMoreSubCat &&
                                  this.state.subCategoryList.map(function (
                                    rowsubcat,
                                    i
                                  ) {
                                    return i > 3 ? (
                                      <li>
                                        <a
                                          href={"/live-auction/" + rowsubcat.slug}
                                          id="GoFarm_machinery"
                                          className="goclass01"
                                          name="subCategoryId"
                                          onClick={context.handleOverlayClick}
                                        >
                                          {" "}
                                          {rowsubcat.categoryName} (
                                          {rowsubcat.tasksCount}
                                          )
                                        </a>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                {this.state.subCategoryList.length > 4 &&
                                  this.state.loadAllSubCatBtn ? (
                                  <li>
                                    <a
                                      href
                                      name="load_all"
                                      onClick={context.loadAllSubCategories.bind(
                                        context
                                      )}
                                    >
                                      <strong>Load All</strong>
                                    </a>
                                  </li>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </li>
                          </ul>
                        </div>}
                        {this.state.filterKeyWord === "price" && <div className="product-listing-sideBar-colum sideBar-category">
                          <h2>Price</h2>
                          <div class="row">
                            <div className="col-5 pr-1">
                              <div class="form-group">
                                <input
                                  type="number"
                                  name="min_price"
                                  onChange={this.debouncedHandleCatMinPrice}
                                  class="form-control-line"
                                  placeholder="Min"
                                />
                              </div>
                            </div>
                            <div className="col-5 pr-1">
                              <div class="form-group">
                                <input
                                  type="number"
                                  name="max_price"
                                  onChange={this.debouncedHandleCatMaxPrice}
                                  class="form-control-line"
                                  placeholder="Max"
                                />
                              </div>
                            </div>
                            <div className="col-2 px-0">
                              <button className="go-btn" onClick={context.handlePriceData}>GO</button>
                            </div>
                          </div>
                        </div>}
                        {this.state.filterKeyWord === "other" && <div className="product-listing-sideBar-colum other-option otheropt_newcls">
                          <h2>Other Options</h2>
                          <div class="otheroption_cheperent ">
                            <div class="check-box">
                              <input
                                type="checkbox"
                                value="Yes"
                                name="liveAuction"
                                id="live-auction"
                                onClick={context.handleLiveActionSorting}
                              />
                              <label for="live-auction">
                                Live Auction
                              </label>
                            </div>
                            <div class="check-box">
                              <input
                                type="checkbox"
                                value="Yes"
                                name="upcomingLiveAuction"
                                id="upcoming-auction"
                                onClick={context.handleLiveActionSorting}
                              />
                              <label for="upcoming-auction">
                                Upcoming Live Auction
                              </label>
                            </div>
                          </div>
                        </div>}
                        {this.state.filterKeyWord === "sort" && <div className="form-group-icon-bx">
                          <select
                            className="form-control-line"
                            onChange={context.handleFilterSorting}
                          >
                            <option value="">Most Recent First</option>
                            <option value="LowToHigh">Price: Low to High</option>
                            <option value="HighToLow">Price: High to Low</option>
                            <option value="RatingLowToHigh">Rating: Low to High</option>
                            <option value="RatingHighToLow">Rating: High to Low</option>
                          </select>
                          <span className="form-group-icon">
                            <img src="/images/down-arrow-icon.png" alt="icon" />
                          </span>
                        </div>}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-9">
                <div className="fillter_btn_box">
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "category" });
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Category</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "location" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Location</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "price" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Price</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "sort" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Sort</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    id="filter-toggler"
                    className="btnx btn-secondaryx fillter_btn_track"
                    onClick={() => {
                      this.setState({ openProductFilter: true, filterKeyWord: "other" })
                      document.body.classList.add('filter-open');
                    }}
                  >
                    <div className="fillter_trackMobile">
                      <span>Other Options</span>
                      <span className="dropdown_arrow">
                        <svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M2.04518 6.61626C2.27959 6.38192 2.59748 6.25027 2.92893 6.25027C3.26039 6.25027 3.57827 6.38192 3.81268 6.61626L10.0002 12.8038L16.1877 6.61626C16.4234 6.38856 16.7392 6.26257 17.0669 6.26541C17.3947 6.26826 17.7082 6.39972 17.94 6.63148C18.1717 6.86324 18.3032 7.17676 18.306 7.50451C18.3089 7.83225 18.1829 8.148 17.9552 8.38376L10.8839 15.455C10.6495 15.6893 10.3316 15.821 10.0002 15.821C9.66873 15.821 9.35084 15.6893 9.11643 15.455L2.04518 8.38376C1.81084 8.14935 1.6792 7.83146 1.6792 7.50001C1.6792 7.16855 1.81084 6.85067 2.04518 6.61626Z" fill="white" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
                {/* <div
                  id="filter-toggler"
                  className="btnx btn-secondaryx mx-3"
                  onClick={() => this.setState({ openProductFilter: true })}
                >
                  All Filter
                </div> */}
                <div className="Jcompny_allprod">
                  {showHideListData && (
                    <div className="Jtaballp_content atlistingside_new">
                      {allCatFilterProducts.length > 0 ? (
                        <>
                          {allCatFilterProducts.map(function (row) {
                            return (
                              <div className="row">
                                {console.log("john2")}
                                <div
                                  className="col-lg-12 listside_newperent"
                                  key={"allCatFilterProducts" + row._id}
                                >
                                  <div className="product-listing-product">
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="product-img-bx">
                                          <Link
                                            to={Product.isLogin() ? {
                                              pathname: "/livestreamingProduct/" +
                                                row?.liveStreaming.streamId,
                                              query: {
                                                productId: row.productSlug,
                                              },
                                            } : "/upcomingLiveStreamingProduct/" +
                                            row.productSlug +
                                            "/" +
                                            row?.liveStreaming.streamId
                                            }
                                          >
                                            {row.adType == "Featured" && (
                                              <div className="specialtags">
                                                <span className="black_special">
                                                  {row.adType}
                                                </span>
                                              </div>
                                            )}
                                            {row.productMainImage ? (
                                              <img
                                                src={General.showImage(
                                                  "products/resize-180-180",
                                                  row.productMainImage
                                                )}
                                                alt="uploaded image"
                                              />
                                            ) : (
                                              <img
                                                src="/images/no-image.jpg"
                                                alt="product"
                                              />
                                            )}

                                          </Link>
                                        </div>
                                      </div>
                                      <div className="col-9">
                                        <div className="product-outer-bx productBig-outer-bx">
                                          <div className="product-name-bx content_gobottom_new">
                                            <div className="displayInlineTime">
                                              <time>{row.time}</time>
                                              {moment(row?.liveStreaming?.startTime).diff(
                                                moment(),
                                                "seconds"
                                              ) > 0 ? (
                                                <Countdown
                                                  date={row?.liveStreaming?.startTime}
                                                  zeroPadTime={2}
                                                  renderer={({ days, hours, minutes, seconds }) => (
                                                    <time>
                                                      {days  +
                                                        "days" +
                                                        " " +
                                                        hours +
                                                        ":" +
                                                        minutes +
                                                        ":" +
                                                        seconds}{" "}
                                                      to go
                                                    </time>
                                                  )}
                                                />) : <time> Live </time>}
                                            </div>

                                            <Link
                                              to={Product.isLogin() ? {
                                                pathname: "/livestreamingProduct/" +
                                                  row?.liveStreaming.streamId,
                                                query: {
                                                  productId: row.productSlug,
                                                },
                                              } : "/upcomingLiveStreamingProduct/" +
                                              row.productSlug +
                                              "/" +
                                              row?.liveStreaming.streamId
                                              }
                                            >
                                              <h4>{row.productName}</h4>
                                            </Link>
                                            <h5>
                                              {row?.location}
                                            </h5>
                                            {General.showWeekendDeals() &&
                                              row.isWeekEndDeal ? (
                                              <div class="d-flex align-items-center">
                                                <del>
                                                  {General.showPriceDecimal(
                                                    row.productPrice
                                                  )}
                                                </del>
                                                <span className="discountt mr-2">
                                                  -{row?.discountPercent}%
                                                </span>
                                                <h6 className="mt-0">
                                                  <span>
                                                    {General.showPriceDecimal(
                                                      row.weekendPrice
                                                    )}
                                                  </span>
                                                </h6>
                                              </div>
                                            ) : (
                                              <h6>
                                                <span>
                                                  {General.showPriceDecimal(
                                                    row.productPrice
                                                  )}
                                                </span>
                                              </h6>
                                            )}

                                            {row.productDescription && (
                                              <p>{`${row.productDescription.substring(
                                                0,
                                                200
                                              )}...`}</p>
                                            )}
                                            {row.isWishlist === 1 ? (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.removeWishlistHandler(
                                                    row._id
                                                  )
                                                }
                                              >
                                                <i className="fas fa-heart"></i>
                                              </button>
                                            ) : (
                                              <button
                                                className="add-to-wishlist"
                                                onClick={() =>
                                                  context.addWishlistHandler(
                                                    row._id
                                                  )
                                                }
                                              >
                                                <i className="far fa-heart"></i>
                                              </button>
                                            )}
                                            {row?.condition &&
                                              (
                                                <>
                                                  <div className="usednew_button">
                                                    <button
                                                      type="button"
                                                      className="btnnew_used"
                                                    >
                                                      {row.condition}
                                                    </button>
                                                  </div>
                                                </>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {this.state.nothingD ? (
                            <div className="alert alert-danger col-lg-12">
                              {console.log("john1")}
                              <strong>Info!</strong> Nothing here.
                            </div>
                          ) : (
                            <>
                              {this.state.productSkeleton.map(function (
                                rowsskeleton
                              ) {
                                return (
                                  <ContentLoader
                                    viewBox="0 0 1380 300"
                                    height={340}
                                    width={1380}
                                    key={rowsskeleton.id}
                                  >
                                    <rect
                                      x="20"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="300"
                                      height="300"
                                    />
                                    <rect
                                      x="360"
                                      y="{rowsskeleton.id}"
                                      rx="10"
                                      ry="10"
                                      width="1000"
                                      height="300"
                                    />
                                  </ContentLoader>
                                );
                              })}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {showHideGridData && (
                    <div className="Jtaballp_content02 auctionplist_main ">
                      <div className="col-sm-12 actionpage_list">
                        <div
                          className={
                            this.state.nothingD ? "row" : "row low-margin"
                          }
                        >
                          {allCatFilterProducts.length > 0 ? (
                            <>
                              {allCatFilterProducts.map(function (row) {
                                return (
                                  <div
                                    className="col-sm-3"
                                    key={"allCatFilterProducts" + row._id}
                                  >
                                    <div className="product-outer-bx product_card_box produc-grid-product">
                                      <Link
                                        to={Product.isLogin() ? {
                                          pathname: "/livestreamingProduct/" +
                                            row?.liveStreaming.streamId,
                                          query: {
                                            productId: row.productSlug,
                                          },
                                        } : "/upcomingLiveStreamingProduct/" +
                                        row.productSlug +
                                        "/" +
                                        row?.liveStreaming.streamId
                                        }
                                      >
                                        <div className="product-img-bx">
                                          {moment(row?.liveStreaming?.startTime).diff(
                                            moment(),
                                            "seconds"
                                          ) <= 0 ? (<span className="live-tag">
                                            <i className="fas fa-circle"></i> Live
                                          </span>) : ""}
                                          {row.productMainImage ? (
                                            <img
                                              src={General.showImage(
                                                "products/resize-180-180",
                                                row.productMainImage
                                              )}
                                              alt="uploaded image"
                                            />
                                          ) : (
                                            <img
                                              src="/images/no-image.jpg"
                                              alt="product"
                                            />
                                          )}
                                          {row.adType == "Featured" && (
                                            <div className="specialtags">
                                              <span className="black_special">
                                                {row.adType}
                                              </span>
                                            </div>
                                          )}

                                        </div>
                                        <div className="product-name-bx">
                                          {moment(row?.liveStreaming?.startTime).diff(
                                            moment(),
                                            "seconds"
                                          ) > 0 ? (
                                            <Countdown
                                              date={row?.liveStreaming?.startTime}
                                              zeroPadTime={2}
                                              renderer={({ days, hours, minutes, seconds }) => (
                                                <time>
                                                  {days  +
                                                    "days" +
                                                    " " +
                                                    hours +
                                                    ":" +
                                                    minutes +
                                                    ":" +
                                                    seconds}{" "}
                                                  to go
                                                </time>
                                              )}
                                            />) : ""}
                                          <h6>
                                            <span>
                                              {General.showPriceDecimal(
                                                row.starting_price
                                              )}
                                            </span>
                                          </h6>
                                          {/* <Rating
                                            fillColor="#2f9ee4"
                                            className="rating-product"
                                            initialValue={row?.rating}
                                            readonly={true}
                                            size={15}
                                          /> */}
                                          <h4>{row.productName}</h4>
                                          {row?.location && <h3>
                                            {row?.location}
                                          </h3>}
                                          {row?.condition &&
                                            (
                                              <>
                                                <div className="item-condition" style={{ marginTop: "5px" }}>
                                                  <span className="item-conditionTag">{row?.condition}</span>
                                                </div>
                                              </>
                                            )
                                          }
                                          <div className="d-inline-flex- sd d-none">
                                            <div className="musr_img">
                                              {row?.userId &&
                                                row.userId
                                                  ?.profilePicture ? (
                                                <img
                                                  src={General.showImageNew(
                                                    "profile",
                                                    row.userId
                                                      ?.profilePicture
                                                  )}
                                                  onError={
                                                    (e) => e.target.src = '/images/user.png'
                                                  }
                                                  alt={row.userId.firstName}
                                                  className="mCS_img_loaded"
                                                />
                                              ) : (
                                                <img
                                                  src="/images/user.png"
                                                  alt="image"
                                                  className="mCS_img_loaded"
                                                />
                                              )}
                                            </div>
                                            {row?.userId && <h4 className="ml-2">{`${row?.userId?.firstName} ${row?.userId?.lastName}`}</h4>}
                                          </div>
                                        </div>
                                      </Link>
                                      {row.isWishlist === 1 ? (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.removeWishlistHandler(
                                              row._id
                                            )
                                          }
                                        >
                                          <i className="fas fa-heart"></i>
                                        </button>
                                      ) : (
                                        <button
                                          className="add-to-wishlist"
                                          onClick={() =>
                                            context.addWishlistHandler(row._id)
                                          }
                                        >
                                          <i className="far fa-heart"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {this.state.nothingD ? (
                                <div className="alert alert-danger col-lg-12">
                                  <strong>Info!</strong> Nothing here.
                                </div>
                              ) : (
                                <>
                                  {this.state.productSkeleton.map(function (
                                    rowsskeleton
                                  ) {
                                    return (
                                      <ContentLoader
                                        viewBox="0 0 1380 300"
                                        height={340}
                                        width={1380}
                                        key={rowsskeleton.id}
                                      >
                                        <rect
                                          x="20"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="360"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="700"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                        <rect
                                          x="1040"
                                          y="{rowsskeleton.id}"
                                          rx="10"
                                          ry="10"
                                          width="300"
                                          height="300"
                                        />
                                      </ContentLoader>
                                    );
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="loading-bx">
                  {this.state.totalRecords > 0 && (
                    <>
                      <p>
                        <span>
                          You have viewed {this.state.limit} of{" "}
                          {this.state.totalRecords} Ads
                        </span>
                      </p>
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          aria-valuenow="70"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          <span className="sr-only">70% Complete</span>
                        </div>
                      </div>
                    </>
                  )}

                  {this.state.next_page_number > 0 && (
                    <button
                      onClick={context.getDatabyPageno.bind(
                        context,
                        this.state.next_page_number
                      )}
                    >
                      LOAD MORE
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="side-bar-stoper"></div>
          </div>
        </section>
        <section className="bg-light-dark">
          <div></div>
        </section>
      </React.Fragment>
    );
  }
}

export default AuctionList;
