import React, { Component } from "react";
import { Link } from "react-router-dom";
import Product from "Models/Product";
import PopularCategoriesSlider from "Elements/PopularCategoriesSlider";
import General from "Helpers/General";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Countdown from "react-countdown";
import Menu from "Partials/Menu";
import ContentLoader from "react-content-loader";
import Category from "Models/Category";

class Home extends Component {
  constructor(props) {
    super(props);
    const nthngD = localStorage.getItem("LiveStreamings") ? true : false;

    const liveStreamingCatId =
      localStorage.getItem("LiveStreamingCategorySetting") &&
      JSON.parse(localStorage.getItem("LiveStreamingCategorySetting"));

    const LiveStreamingCat =
      liveStreamingCatId &&
      localStorage.getItem("PostCategories") &&
      JSON.parse(localStorage.getItem("PostCategories")).find(
        (cat) => cat._id === liveStreamingCatId
      );

    this.checkStorageData();
    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      mobileLogo: localStorage.getItem("MobileLogo"),
      MainCategories: localStorage.getItem("MainCategories")
        ? JSON.parse(localStorage.getItem("MainCategories"))
        : [],
      PostCategories: localStorage.getItem("PostCategories")
        ? JSON.parse(localStorage.getItem("PostCategories"))
        : [],
      LiveStreamings: localStorage.getItem("LiveStreamings")
        ? JSON.parse(localStorage.getItem("LiveStreamings"))
        : [],
      UpcomingStreamings: localStorage.getItem("UpcomingStreamings")
        ? JSON.parse(localStorage.getItem("UpcomingStreamings"))
        : [],
      LatestProducts: localStorage.getItem("LatestProducts")
        ? JSON.parse(localStorage.getItem("LatestProducts"))
        : [],
      WeeklyDeals: localStorage.getItem("WeeklyDeals")
        ? JSON.parse(localStorage.getItem("WeeklyDeals"))
        : [],
      Brands: localStorage.getItem("Brands")
        ? JSON.parse(localStorage.getItem("Brands"))
        : [],
      PopularCategories: localStorage.getItem("PopularCategories")
        ? JSON.parse(localStorage.getItem("PopularCategories"))
        : [],
      PremiumProducts: localStorage.getItem("PremiumProducts")
        ? JSON.parse(localStorage.getItem("PremiumProducts"))
        : [],
      userLoginId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      LiveStreamingCategoryName: LiveStreamingCat,
      nothingD: nthngD,
      responsive: {
        0: {
          items: 2,
        },
        550: {
          items: 2,
        },
        600: {
          items: 3,
        },
        700: {
          items: 4,
        },
        1000: {
          items: 4,
        },
        1220: {
          items: 4,
        },
      },
      responsive1: {
        0: {
          items: 1,
        },
        550: {
          items: 2,
        },
        600: {
          items: 2,
        },
        700: {
          items: 4,
        },
        1000: {
          items: 4,
        },
        1220: {
          items: 4,
        },
      },
      openMobileMenu: false,
      isLoading: false,
      homeBlock: {},
      activeCategory: "",
    };
  }

  checkStorageData() {
    if (window.localStorage.length == 0) {
      document.body.classList.add("nodata");
    } else {
      document.body.classList.remove("nodata");
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.LatestProducts !== this.state.LatestProducts) {
      this.forceUpdate(); // This forces a re-render
    }
  }

  componentDidMount = () => {
    Product.getApi(
      "APIS_GET_GEO_LOCATION_AUTO_DETECT",
      async (response) => {
        if (response && response[0]["common_setting"]) {
          if (response[0]["common_setting"][0]["autoDetection"] === true) {
            navigator.geolocation.getCurrentPosition((position, error) => {
              if (error) {
                console.log(error);
              }
              if (position) {
                let data = {
                  longitude: position.coords.longitude,
                  latitude: position.coords.latitude,
                };
                localStorage.setItem("location", JSON.stringify(data));
              }
            });
          }
        }
      },
      false
    );
  };

  getHomePageData = () => {
    var self = this;
    Product.getApi(
      "APIS_GET_HOME_DATA",
      async (response) => {
        localStorage.setItem(
          "PopularCategories",
          JSON.stringify(response.allCategories)
        );
        localStorage.setItem(
          "LiveStreamings",
          JSON.stringify(response.liveStreaming)
        );
        localStorage.setItem("Brands", JSON.stringify(response.allBrands));
        localStorage.setItem(
          "WeeklyDeals",
          JSON.stringify(response.weeklyDeals)
        );
        localStorage.setItem(
          "LatestProducts",
          JSON.stringify(response.latestProducts)
        );
        localStorage.setItem(
          "UpcomingStreamings",
          JSON.stringify(response.upComingStreaming)
        );
        localStorage.setItem(
          "PremiumProducts",
          JSON.stringify(response.premiumProducts)
        );

        self.setState({ PopularCategories: response.allCategories });
        self.setState({ Brands: response.allBrands });
        self.setState({ WeeklyDeals: response.weeklyDeals });
        self.setState({ LatestProducts: response.latestProducts });
        self.setState({ LiveStreamings: response.liveStreaming });
        self.setState({ UpcomingStreamings: response.upComingStreaming });
        self.setState({ PremiumProducts: response.premiumProducts });
        self.setState({ homeBlock: response.homeBlock });
        self.setState({ nothingD: true });
        self.checkStorageData();
      },
      false
    );
  };

  componentWillMount = () => {
    var self = this;
    this.getHomePageData();
    Category.getApi(
      "APIS_ALL_CATEGORIES_DATA",
      async (response) => {
        localStorage.setItem("MainCategories", JSON.stringify(response));

        self.setState({ MainCategories: response });
      },
      false
    );
    Category.getApi(
      "APIS_ALL_CATEGORIES",
      async (response) => {
        localStorage.setItem("PostCategories", JSON.stringify(response));
        self.setState({ PostCategories: response });
      },
      false
    );
  };

  weeklydealTimer = ({ days, hours, minutes, seconds, completed }) => {
    console.log('Test', days, hours, minutes, seconds);
    // Check if the countdown is completed
    if (completed) {
      return <span>Deal Ended!</span>;
    }
    return (
      <>
        <span>{days}</span>
        <i>:</i>
        <span>{hours}</span>
        <i>:</i>
        <span>{minutes}</span>
        <i>:</i>
        <span>{seconds}</span>
      </>
    );
  };

  allCategoryProductHandler = () => {
    this.getHomePageData();
  };

  categoryProductHandler = (e, id = "") => {
    e.preventDefault();
    this.setState({ isLoading: true, activeCategory: id }); // Update activeCategory
    Product.postApi(
      "APIS_GET_LATEST_PRODUCT_BY_CATEGORY",
      { categoryId: id },
      (response) => {
        if (response) {
          console.log("response", response);
          this.setState({ LatestProducts: response, isLoading: false });
        }
      }
    );
  };

  notifyHandler = (id, productId) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_NOTIFY_UPCOMING_STREAMING",
        { liveStreamingId: id, productId },
        async (response) => {
          this.getHomePageData();
        },
        true
      );
    } else {
      Product.toastAlert("error", "Please Login First");
    }
  };

  removeNotifyHandler = (id, productId) => {
    if (Product.isLogin()) {
      Product.postApi(
        "APIS_REMOVE_NOTIFY_UPCOMING_STREAMING",
        { liveStreamingId: id, productId },
        async (response) => {
          this.getHomePageData();
        },
        true
      );
    } else {
      Product.toastAlert("error", "Please Login First");
    }
  };

  toggleMobileMenu = () => {
    this.setState({ openMobileMenu: !this.state.openMobileMenu }, () => {
      const mobileNav = document.getElementById("mobileNav");
      if (this.state.openMobileMenu) {
        mobileNav.classList.add("showMenu");
      } else {
        mobileNav.classList.remove("showMenu");
      }
    });
  };

  render() {
    var context = this;
    const {
      LatestProducts,
      PopularCategories,
      LiveStreamings,
      UpcomingStreamings,
      isLoading,
      LiveStreamingCategoryName,
      homeBlock,
      activeCategory
    } = this.state;
    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="main-menu-bx">
          <div className="container">
            <div className="main-menu-bx-inner">
              <div className="mobile-stuff logo-bx">
                <h6 className="mob-text" style={{ color: "white" }}>All categories</h6>
              </div>
              <div
                className={`menu-toggle mobile-stuff ${this.state.openMobileMenu ? "switch-icon" : ""
                  }`}
                onClick={this.toggleMobileMenu}
              ></div>
              <Menu />
            </div>
          </div>
        </section>
        <section className="repeat-section live-auction-section">
          <div className="container live-auction-minH">
            <div className="row">
              <div className="col-lg-9">
                <div className="section-heading">
                  {/* {LiveStreamingCategoryName && (
                    <h2>{LiveStreamingCategoryName.categoryName}</h2>
                  )} */}
                  <h2>Live Auction Show </h2>
                  <Link to="/category/live-auction" className="see-all-btn font-size-14">
                    See All <i className="fas fa-arrow-right"></i>
                  </Link>
                </div>
                <div className="row low-margin desktop_fillter">
                  {LiveStreamings && LiveStreamings.length > 0 ? (
                    LiveStreamings.slice(0, 4).map((row) => (
                      <div className="col-lg-3 col-6" key={row.productId}>
                        <div className="product-outer-bx fullImgBox">
                          <Link
                            to={Product.isLogin() ? {
                              pathname: "/livestreamingProduct/" +
                                row.id,
                              query: {
                                productId: row.productSlug,
                              },
                            } : "/upcomingLiveStreamingProduct/" +
                            row.productSlug +
                            "/" +
                            row.id
                            }
                          /*   to={{
                              pathname: Product.isLogin()
                                ? "/livestreamingProduct/" + row.id
                                : "",
                              query: {
                                productId: row.productSlug,
                              },
                            }} */
                          >
                            <div className="product-img-bx HoverEffect">
                              <span className="live-tag">
                                <i className="fas fa-circle"></i> Live
                              </span>
                              {row.productMainImage ? (
                                <img
                                  src={General.showImage(
                                    "products/resize-180-180",
                                    row.productMainImage
                                  )}
                                  alt={row.productName}
                                />
                              ) : (
                                <img src="/images/no-image.jpg" alt="product" />
                              )}
                            </div>
                            <div className="product-name-bx">
                              <h4>{row.productName}</h4>
                              <h6 className="font-size-14">
                                Starting Price:{" "}
                                <span className="font-size-16">
                                  {" "}
                                  {General.showPriceDecimal(row.startingPrice)}
                                </span>
                              </h6>

                            </div>
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : this.state.nothingD ? (
                    <div className="col-12 col-xl-3">
                      <div className="alert alert-danger mb-2 mb-md-0">
                        <strong>Info!</strong> Nothing here.
                      </div>
                    </div>
                  ) : (
                    <ContentLoader
                      viewBox="0 0 1380 300"
                      height={360}
                      width={1380}
                    >
                      <rect
                        x="20"
                        y="0"
                        rx="10"
                        ry="10"
                        width="310"
                        height="240"
                      />
                      <rect
                        x="360"
                        y="0"
                        rx="10"
                        ry="10"
                        width="310"
                        height="240"
                      />
                      <rect
                        x="700"
                        y="0"
                        rx="10"
                        ry="10"
                        width="310"
                        height="240"
                      />
                      <rect
                        x="1040"
                        y="0"
                        rx="10"
                        ry="10"
                        width="310"
                        height="240"
                      />

                      <rect
                        x="20"
                        y="260"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="360"
                        y="260"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="700"
                        y="260"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="1040"
                        y="260"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />

                      <rect
                        x="20"
                        y="275"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="360"
                        y="275"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="700"
                        y="275"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="1040"
                        y="275"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />

                      <rect
                        x="20"
                        y="290"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="360"
                        y="290"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="700"
                        y="290"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="1040"
                        y="290"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />

                      <rect
                        x="100"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="440"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="780"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="1120"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />

                      <rect
                        x="180"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="520"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="860"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="1200"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                    </ContentLoader>
                  )}
                </div>
                <div className="row low-margin mobile_fillter">
                  {LiveStreamings && LiveStreamings.length > 0 ? (
                    LiveStreamings.slice(0, 2).map((row) => (
                      <div className="col-lg-3 col-6" key={row.productId}>
                        <div className="product-outer-bx fullImgBox">
                          <Link
                            to={Product.isLogin() ? {
                              pathname: "/livestreamingProduct/" +
                                row.id,
                              query: {
                                productId: row.productSlug,
                              },
                            } : "/upcomingLiveStreamingProduct/" +
                            row.productSlug +
                            "/" +
                            row.id
                            }
                          >
                            <div className="product-img-bx HoverEffect">
                              <span className="live-tag">
                                <i className="fas fa-circle"></i> Live
                              </span>
                              {row.productMainImage ? (
                                <img
                                  src={General.showImage(
                                    "products/resize-180-180",
                                    row.productMainImage
                                  )}
                                  alt={row.productName}
                                />
                              ) : (
                                <img src="/images/no-image.jpg" alt="product" />
                              )}
                            </div>
                            <div className="product-name-bx">
                              <h4>{row.productName}</h4>
                              <h6 className="font-size-16">
                                Starting Price:{" "}
                                <span className="font-size-16">
                                  {" "}
                                  {General.showPriceDecimal(row.startingPrice)}
                                </span>
                              </h6>
                            </div>
                          </Link>
                        </div>
                      </div>
                    ))
                  ) : this.state.nothingD ? (
                    <div className="col-12 col-xl-3">
                      <div className="alert alert-danger mb-2 mb-md-0">
                        <strong>Info!</strong> Nothing here.
                      </div>
                    </div>
                  ) : (
                    <ContentLoader
                      viewBox="0 0 1380 300"
                      height={360}
                      width={1380}
                    >
                      <rect
                        x="20"
                        y="0"
                        rx="10"
                        ry="10"
                        width="310"
                        height="240"
                      />
                      <rect
                        x="360"
                        y="0"
                        rx="10"
                        ry="10"
                        width="310"
                        height="240"
                      />
                      <rect
                        x="700"
                        y="0"
                        rx="10"
                        ry="10"
                        width="310"
                        height="240"
                      />
                      <rect
                        x="1040"
                        y="0"
                        rx="10"
                        ry="10"
                        width="310"
                        height="240"
                      />

                      <rect
                        x="20"
                        y="260"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="360"
                        y="260"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="700"
                        y="260"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="1040"
                        y="260"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />

                      <rect
                        x="20"
                        y="275"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="360"
                        y="275"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="700"
                        y="275"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="1040"
                        y="275"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />

                      <rect
                        x="20"
                        y="290"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="360"
                        y="290"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="700"
                        y="290"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="1040"
                        y="290"
                        rx="10"
                        ry="10"
                        width="310"
                        height="10"
                      />
                      <rect
                        x="100"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="440"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="780"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="1120"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />

                      <rect
                        x="180"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="520"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="860"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                      <rect
                        x="1200"
                        y="320"
                        rx="10"
                        ry="10"
                        width="70"
                        height="30"
                      />
                    </ContentLoader>
                  )}
                </div>
              </div>
              <div className="col-lg-3">
                <div className="upcoming-live-auction-bx">
                  <h2>Upcoming Live Auction Show</h2>
                  {UpcomingStreamings && UpcomingStreamings.length > 0 ? (
                    UpcomingStreamings.slice(0, 2).map((row) => (
                      <div
                        className="upcoming-live-auction-colum newUpcoming-live"
                        key={"UpcomingStreamings-" + row.productId}
                      >
                        <div className="upcoming-live-img">
                          <Link
                            to={Product.isLogin() ? {
                              pathname: "/livestreamingProduct/" +
                                row?.id,
                              query: {
                                productId: row.productSlug,
                              },
                            } : "/upcomingLiveStreamingProduct/" +
                            row.productSlug +
                            "/" +
                            row?.id
                            }
                          >
                            {row.productMainImage ? (
                              <img
                                src={General.showImage(
                                  "products/resize-180-180",
                                  row.productMainImage
                                )}
                                alt={row.productName}
                              />
                            ) : (
                              <img src="/images/no-image.jpg" alt="product" />
                            )}
                          </Link>
                        </div>
                        <div className="upcoming-live-name upcoming_mobile">
                          <Link
                            to={Product.isLogin() ? {
                              pathname: "/livestreamingProduct/" +
                                row?.id,
                              query: {
                                productId: row.productSlug,
                              },
                            } : "/upcomingLiveStreamingProduct/" +
                            row.productSlug +
                            "/" +
                            row?.id
                            }
                          >
                            <Countdown
                              date={row?.time}
                              zeroPadTime={2}
                              renderer={({ days, hours, minutes, seconds }) => (
                                <time>
                                  {days +
                                    " days" +
                                    " " +
                                    hours +
                                    ":" +
                                    minutes +
                                    ":" +
                                    seconds}{" "}
                                  to go
                                </time>
                              )}
                            />
                            <h5>{(row.productName.length > 16) ? row.productName.substring(0, 16) + "..." : row.productName}</h5>
                            <h6>
                              Starting Price:{" "}
                              <span>{General.showPriceDecimal(row.startingPrice)}</span>
                            </h6>
                          </Link>
                          {row.isNotified ? (
                            <button
                              className="reminder-btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Notify me"
                              onClick={() =>
                                this.removeNotifyHandler(row.id, row.productId)
                              }
                            >
                              <i className="fas fa-bell" aria-hidden="true"></i>
                            </button>
                          ) : (
                            <button
                              className="reminder-btn"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Notify me"
                              onClick={() =>
                                this.notifyHandler(row.id, row.productId)
                              }
                            >
                              <i className="far fa-bell" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <>
                      {this.state.nothingD ? (
                        <div className="alert alert-danger col-lg-12 ml-0">
                          <strong>Info!</strong> Nothing here.
                        </div>
                      ) : (
                        <ContentLoader
                          viewBox="0 0 500 475"
                          height={475}
                          width={500}
                        >
                          <circle cx="70.2" cy="73.2" r="41.3" />
                          <rect x="129.9" y="29.5" width="125.5" height="17" />
                          <rect x="129.9" y="64.7" width="296" height="17" />
                          <rect x="129.9" y="97.8" width="253.5" height="17" />
                          <rect x="129.9" y="132.3" width="212.5" height="17" />
                          <circle cx="70.7" cy="243.5" r="41.3" />
                          <rect x="130.4" y="199.9" width="125.5" height="17" />
                          <rect x="130.4" y="235" width="296" height="17" />
                          <rect x="130.4" y="268.2" width="253.5" height="17" />
                          <rect x="130.4" y="302.6" width="212.5" height="17" />
                          <circle cx="70.7" cy="412.7" r="41.3" />
                          <rect x="130.4" y="369" width="125.5" height="17" />
                          <rect x="130.4" y="404.2" width="296" height="17" />
                          <rect x="130.4" y="437.3" width="253.5" height="17" />
                          <rect x="130.4" y="471.8" width="212.5" height="17" />
                        </ContentLoader>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* As per latest feedback -- 1_functionality_September_delivery.png this weekly section added*/}
        {General.showWeekendDeals() && (
          <>
            {
              this.state.WeeklyDeals.length > 0 && (
                <section className="repeat-section flash-sale-section bg-light-dark weeklydeal_homep">
                  <div className="container weeklydeal-minH">
                    <div className="section-heading">
                      <h2>
                        Weekend Deals
                        <div className="flash-sale-timer">
                          <h5>Ending in</h5>
                          <Countdown
                            date={Date.now() + 10000000}
                            renderer={this.weeklydealTimer}
                            daysInHours={false}
                            zeroPadTime={2}
                          />
                        </div>
                      </h2>
                      <Link to="/search?weeklydeals=true" className="see-all-btn font-size-14">
                        See All <i className="fas fa-arrow-right"></i>
                      </Link>
                    </div>
                    <div className="repeat-section pt-0">
                      <OwlCarousel
                        className="owl-theme"
                        margin={20}
                        nav
                        items={4}
                        dots={false}
                        responsive={this.state.responsive1}
                      >
                        {this.state.WeeklyDeals.map(function (row) {
                          return (
                            <div className="item" key={row._id}>
                              <div className="product-outer-bx productBig-outer-bx">
                                <Link to={`/product/${row?.product?.productSlug}`}>
                                  <div className="product-img-bx HoverEffect">
                                    {row.product.adType && (
                                      <div className="specialtags">
                                        <span
                                          className={
                                            (row.product.adType === "Featured"
                                              ? "black"
                                              : row.product.adType === "Urgent"
                                                ? "red"
                                                : "green") + "_special"
                                          }
                                        >
                                          {row.product.adType}{" "}
                                        </span>
                                      </div>
                                    )}
                                    {row.product.productMainImage ? (
                                      <img
                                        src={General.showImage(
                                          "products/resize-180-180",
                                          row.product.productMainImage
                                        )}
                                        alt={row?.product?.productName}
                                      />
                                    ) : (
                                      <img src="/images/no-image.jpg" alt="product" />
                                    )}
                                  </div>
                                  <div className="product-name-bx">
                                    <h4 className="justify-content-center">
                                      {row.product.productName}
                                    </h4>
                                    <p>
                                      <del>
                                        {General.showPriceOneDecimal(row.product.productPrice)}
                                      </del>
                                      <span className="discountt">
                                        -{row.discount}%
                                      </span>
                                    </p>
                                    <h6>
                                      <span>
                                        {General.showPriceDecimal(
                                          row.product.productPrice -
                                          (row.product.productPrice *
                                            row.discount) /
                                          100
                                        )}
                                      </span>
                                    </h6>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    </div>{" "}
                  </div>
                </section>
              )
            }
          </>
        )}
        <section className=" flash-sale-section bg-light-dark weeklydeal_homep latest_items_section">
          <div className="container latest-product-container">
            <div className="section-heading break-on-767">
              <div className="latest_product_block">
                <h2>
                  Latest Items
                </h2>
                <Link to={"/search"} className="see-all-btn d-md-none">
                  See All <i className="fas fa-arrow-right"></i>
                </Link>
              </div>
              <ul className="latest-product-categories">
                <li>
                  <Link
                    to="/search"
                    onClick={(e) => context.categoryProductHandler(e)}
                    className={activeCategory === "" ? "active" : ""}
                  >
                    All
                  </Link>
                </li>
                {PopularCategories.map((cat) => (
                  <li key={cat._id}>
                    <Link
                      to={`/category/${cat.slug}`}
                      onClick={(e) => context.categoryProductHandler(e, cat._id)}
                      className={activeCategory === cat._id ? "active" : ""}
                    >
                      {cat.categoryName}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="latest-product-minH">
              {
                context.state.LatestProducts.length > 0 ? (
                  <OwlCarousel
                    className="owl-theme"
                    margin={20}
                    nav
                    items={4}
                    dots={false}
                    responsive={context.state.responsive1}
                    key={context.state.LatestProducts.length} // Use a key to trigger re-render
                  >
                    {context.state.LatestProducts &&
                      context.state.LatestProducts.slice(0, 10).map((row) => (
                        <div className="item" key={"LatestProducts" + row._id}>
                          <div className="product-outer-bx productBig-outer-bx">
                            <Link to={`product/${row.productSlug}`}>
                              <div className="product-img-bx HoverEffect">
                                {row.productMainImage ? (
                                  <img
                                    src={General.showImage(
                                      "products/resize-566-400",
                                      row.productMainImage
                                    )}
                                    alt={row.productName}
                                  />
                                ) : (
                                  <img
                                    src="/images/no-image.jpg"
                                    alt="product"
                                  />
                                )}
                              </div>
                              <div className="product-name-bx">
                                <h4>{row.productName}</h4>
                                <h6>
                                  <span>
                                    {General.showPriceDecimal(row.productPrice)}
                                  </span>
                                </h6>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                  </OwlCarousel>
                ) : (
                  <div class="alert alert-info">
                    <strong>Nothing here!</strong>
                  </div>
                )
              }
            </div>
          </div>
        </section>
        {/* )} */}
        <section className="repeat-section popular-categories-section  popular_categories_box">
          <div className="container">
            <div className="section-heading">
              <h2 className="text-center text-md-left w-100">Popular Categories</h2>
            </div>
            <div className="popular-categories-minH">
              <OwlCarousel
                className="owl-carousel"
                margin={42}
                loop={false}
                autoplay={true}
                autoplayTimeout={3000}
                autoplayHoverPause={true}
                // nav
                items={5}
                dots={true}
                responsive={this.state.responsive}
              >
                {PopularCategories.map(function (row) {
                  return (
                    <div className="item" key={"PopularCategories" + row._id}>
                      <div className="popular-categories-bx">
                        <Link to={`/category/${row.slug}`}>
                          <div className="popular-categories-img">
                            <img
                              src={General.showImageNew(
                                "categories",
                                row.categoryImage
                              )}
                              alt={row.categoryName}
                            // width="180"
                            // height="180"
                            />
                          </div>
                          <h2>{row.categoryName}</h2>
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </OwlCarousel>
            </div>
          </div>

        </section>
        <section className="repeat-section featured-brand-section pt-0 d-none">
          <div className="container">
            <div className="section-heading text-center">
              <h2 className="w-100">Featured Brands</h2>
            </div>
          </div>
          <div className="featured-brand-minH">
            <OwlCarousel
              className="owl-theme"
              // loop
              autoplay={true}
              autoplayTimeout={2000}
              autoplayHoverPause={true}

              margin={20}
              // nav
              items={7}
              responsive={this.state.responsive}
            >
              {this.state.Brands.map(function (row) {
                return (
                  <div className="item" key={"Brands-" + row._id}>
                    <div className="featured-brand-bx">
                      <div className="featured-brand-img">
                        <img
                          src={General.showImage("brands", row.imageUrl)}
                          alt={row.brandName}
                          width="110"
                          height="31"
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        </section>
        <section className="repeat-section brand-promotion-section pt-0 newjeet_indexPproduct d-none">
          <div className="container">
            <div className="row m-p-0-row">
              <div className="col-lg-7">
                <div className="addne_index">
                  <img
                    src="https://dlevel.b-cdn.net/images/new_secimg04.png"
                    alt="Image"
                    width="632"
                    height="164"
                  />
                </div>
              </div>
              <div className="col-lg-5">
                <div className="addnenew_electric">
                  <div className="brand-promotion-bx electric-bicycle">
                    <div className="brand-promotion-img">
                      <img
                        src="https://dlevel.b-cdn.net/images/new_secimg03.png"
                        alt="Image"
                        width="237"
                        height="151"
                      />
                    </div>
                    <div className="brand-promotion-txt">
                      {homeBlock &&
                        <>
                          <h6>{homeBlock?.title}</h6>
                          <h2>{homeBlock?.subTitle}</h2>
                          <div dangerouslySetInnerHTML={{ __html: homeBlock?.description }}></div>
                          <a href={`/category/${homeBlock?.categoryId?.slug}`} className="see-all-btn">
                            Shop Now <i className="fas fa-arrow-right"></i>
                          </a>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" featured-product-section bg-light-dark">
          <div className="container last-child-p">
            <div className="section-heading">
              <h2>Featured</h2>
              <Link to={"/search?premium=true"} className="see-all-btn featured_all font-size-14">
                See All <i className="fas fa-arrow-right"></i>
              </Link>
            </div>
            <div className="row low-margin">
              {this.state.PremiumProducts.map(function (row) {
                return (
                  <div className="col" key={"PremiumProducts-" + row._id}>
                    <div className="product-outer-bx">
                      <Link to={`product/${row.productSlug}`}>
                        <div className="product-img-bx HoverEffect">
                          {row.productMainImage ? (
                            <img
                              src={General.showImage(
                                "products/resize-180-180",
                                row.productMainImage
                              )}
                              alt={row.productName}
                            />
                          ) : (
                            <img src="/images/no-image.jpg" alt="product" />
                          )}
                          {row.adType && row.adType === "Featured" && (
                            <div className="specialtags">
                              <span
                                className={
                                  (row.adType === "Featured"
                                    ? "black"
                                    : "green") + "_special"
                                }
                              >
                                {row.adType}{" "}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="product-name-bx">
                          <h4>{row.productName}</h4>
                          <h6>
                            <span>{General.showPriceDecimal(row.productPrice)}</span>
                          </h6>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Home;
