import React, { Component } from "react";
import Rating from "@material-ui/lab/Rating";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import General from "Helpers/General";
import Category from "Models/Category";
import Product from "Models/Product";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import Countdown from "react-countdown";
import Order from "Models/Order";
import Validator from "validator";
import Modal from "react-modal";
// import { loadStripe } from "@stripe/stripe-js";
import {
  OpenMessageBox,
  MessageData,
  SetSelectedChannel,
  SetOutgoingCallState,
  SetQuickbloxSession,
  SetSelectedUser,
  SetContactList,
} from "../../Helpers/Actions";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import StarRatings from "react-star-ratings";
import { LinearProgress } from "@material-ui/core";
import ProductReview from "Models/ProductReview";
import Storage from "Helpers/Storage";
import Moment from "react-moment";
import moment from "moment";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Report from "Models/Report";
import { ChannelList } from "./ChannelList";
import { MessagesPanel } from "./MessagesPanel";
import socketClient from "socket.io-client";
import QB, { playCallAudio } from "Helpers/Quickblox";
import socket from "Helpers/Socket";
import MyProfile from "Models/MyProfile";
import CheckoutModel from "Models/CheckoutModel";
import CardTokenPopUp from "../Stripe/CardTokenForm";
const SERVER = process.env.REACT_APP_SERVER;
var qs = require("qs");

const BuyerProtectionModal = ({ open, handleClose, generalSettingsData }) => {
  return (
    <Modal
      isOpen={open}
      onRequestClose={handleClose}
      contentLabel="Buyer Protection"
      className="modal-content protection_modal"
      overlayClassName="modal-overlay"
    >
      <button onClick={handleClose} className="shipCloseBtn">
        <i className="fa fa-times "></i>
      </button>
      <div className="modal-header">
        {/* <img src="/images//right-icon.png" /> */}
        <h2 id="buyer-protection-title" className="modal_title">
          Buyer Protection
        </h2>
        <span>
          <i className="fa-regular fa-circle-xmark" onClick={handleClose} />
        </span>
      </div>
      <div className="modal-body">
        <p className="" style={{ color: "#409b7f", textAlign: "center" }}>
          <u>Learn how we calculate the Buyer Protection fee.</u>
        </p>
        <div className="list_group_box">
          <div className="list_header">
            {/* <i className="fa fa-money-bill protectionModalIcon"></i> */}
            <h6 className="head_title">Refund policy</h6>
          </div>
          {generalSettingsData["miscellaneous"]
            ? generalSettingsData["miscellaneous"][0]["buyerProtection"]
            : ""}
        </div>
      </div>
    </Modal>
  );
};

class AuctionRegisterToBid extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);

    let session_id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).session_id;
    let session_resp = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).response;

    const liveStreamingId = props.match.params.livestreamingId;
    const liveProId = props.match.params.productId;
    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      currentIndex: 0,
      items: [1, 2, 3, 4, 5],
      liveStreamingId: liveStreamingId,
      productData: {},
      productDataId: "",
      // products: localStorage.getItem("recentlyViewProducts")
      //   ? Object.assign(
      //       {},
      //       ...JSON.parse(Storage.getLocalData("recentlyViewProducts")).filter(
      //         (product) =>
      //           product.productSlug === this.props.match.params.productId
      //       )
      //     )
      //   : {},
      products: {},
      isWishlist: 0,
      email: Product.isLogin() ? Storage.getLocalData("username") : "",
      profilePicture: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["profilePicture"]
        : "",
      id: "",
      name: "",
      rating: 0,
      title: "",
      review: "",
      showPhoneNumber: false,
      showReplyAsSeller: "",
      reply: "",
      reports: [],
      showReport: false,
      selectedReports: [],
      reportEmail: "",
      reportName: "",
      session_id: session_id ? session_id : "",
      session_resp: session_resp ? session_resp : "",
      allowedBidUser: [],
      productSafetyTips: localStorage.getItem("ProductSafetyTips")
        ? JSON.parse(localStorage.getItem("ProductSafetyTips"))
        : "",
      generalSettingsData: localStorage.getItem("generalSettingsData")
        ? JSON.parse(localStorage.getItem("generalSettingsData"))
        : {},
      minLength: process.env.REACT_APP_MIN_CHARACTERS_LENGTH_COMMENT,
      maxLength: process.env.REACT_APP_MAX_CHARACTERS_LENGTH_COMMENT,
      showCommentHourMinute: false,
      showMapModuleSetting: false,
      defaultZoomMap: 0,
      loadClient: true,
      refresh: false,
      isLoading: false,
      deliveryAddress: "",
      allCategories: localStorage.getItem("MainCategories")
        ? JSON.parse(localStorage.getItem("MainCategories"))
        : [],
      auctionProduct:
        localStorage.getItem("UpcomingStreamings") &&
          JSON.parse(localStorage.getItem("UpcomingStreamings")).length > 0
          ? JSON.parse(localStorage.getItem("UpcomingStreamings")).find(
            (pro) =>
              pro.id === liveStreamingId && pro.productSlug === liveProId
          )
          : localStorage.getItem("LiveStreamings") &&
            JSON.parse(localStorage.getItem("LiveStreamings")).length > 0
            ? JSON.parse(localStorage.getItem("LiveStreamings")).find(
              (pro) =>
                pro.id === liveStreamingId && pro.productSlug === liveProId
            )
            : null,
      notify: false,
      isAllowNewsletter: false,
      paymentAmount: localStorage.getItem("LiveStreamingRegisterBidAmount")
        ? JSON.parse(localStorage.getItem("LiveStreamingRegisterBidAmount"))
        : 0,
      isDisabled: true,
      checkOutPopup: false,
      transaction_type: "registerBid",
      showDeliveryCharge: true,
      tokenReq: true,
      adminCost: 0,
      postageDetail: {},
      countries: [],
      regions: [],
      cities: [],
      isModalOpen: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  // socket;

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  startVideoCall = (email) => {
    if (email) {
      QB.users.get({ login: email }, (error, user) => {
        if (!error) {
          this.initiateCall(user.id);
        } else {
          console.log("Couldn't find user");
        }
      });
    }
  };

  initiateCall = (userId) => {
    this.props.SetOutgoingCallState(true);
    var calleesIds = [userId]; // Users' ids
    var sessionType = QB.webrtc.CallType.VIDEO; // AUDIO is also possible
    var additionalOptions = {};

    var session = QB.webrtc.createNewSession(
      calleesIds,
      sessionType,
      null,
      additionalOptions
    );

    var mediaParams = {
      audio: true,
      video: { min: 320, ideal: 720, max: 1024 },
      options: {
        muted: true,
        mirror: true,
      },
      elemId: "localVideoElem",
    };
    this.props.SetQuickbloxSession(session);

    session.getUserMedia(mediaParams, (error, stream) => {
      if (error) {
        this.props.SetOutgoingCallState(false);
        this.props.SetQuickbloxSession(null);
        session.stop({});
        console.log("Get user stream error => ", error);
      } else {
        if (stream) {
          const callee = JSON.parse(localStorage.getItem("QB_USER"));
          session.call({ callee: callee?.full_name }, (error1) => {
            playCallAudio();
            console.log(error1);
          });
        }
      }
    });
  };

  setClassActive = async () => {
    if (localStorage.getItem("AuthIdentity")) {
      const selectedContact = JSON.parse(
        localStorage.getItem("QB_CONTACT_LIST")
      ).filter((u) => u.user.login === this.state.products.userId.email);

      if (selectedContact && selectedContact.length > 0) {
        const contacts = JSON.parse(localStorage.getItem("QB_CONTACT_LIST"));

        const restContacts = contacts.filter(
          (user) => user.user.id !== selectedContact[0].user.id
        );

        let allContacts = [];
        allContacts = [selectedContact[0], ...restContacts];
        localStorage.setItem("QB_CONTACT_LIST", JSON.stringify(allContacts));
        this.props.SetContactList(allContacts);
      }

      this.props.OpenMessageBox(true);
      localStorage.setItem("isOpenMessageBox", JSON.stringify(true));
      localStorage.setItem("isClosedMessageBox", JSON.stringify(false));
    }
  };

  componentDidMount = () => {
    console.log("products");
    Product.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        console.log("response", response);
        if (response) {
          this.setState({ name: response.firstName + " " + response.lastName });
          this.setState({ id: response._id });
          this.setState({ postageDetail: response.postageDetail });
        }
      },
      false
    );

    Product.getApi(
      "APIS_GET_LIVESTREAM_SETTING",
      async (response) => {
        if (response[0] && response[0]["common_setting"][0]) {
          localStorage.setItem(
            "LiveStreamingRegisterBidAmount",
            JSON.stringify(
              response[0]["common_setting"][0]["registerBidAmount"]
            )
          );
          this.setState({
            paymentAmount:
              response[0]["common_setting"][0]["registerBidAmount"],
          });
        }
      },
      false
    );

    this.getProductById();
    this.getRegisteredStatus();
    this.getAllCountries();
    this.setState({ session_id: this.state.session_id });
    this.setState({ session_resp: this.state.session_resp });
    console.log("stripe_code", this.state.session_id);
    console.log("stripe_resp", this.state.session_resp);
    if (this.state.session_resp == "success") {
      this.setState({ isLoading: true });
      this.updateRegistrationDetails();
    } else if (this.state.session_resp == "error") {
      //this.getRegisteredStatus();
      const currentUrl = window.location.href;
      const newUrl = currentUrl.split("?")[0];
      window.history.replaceState({ path: newUrl }, "", newUrl);
      this.getRegisteredStatus();
      this.setState({ isLoading: false });
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.match.params.productId !== prevProps.match.params.productId
    ) {
      this.getProductById();
    }
  };
  handlerRegisterAuction = async (e) => {
    e.preventDefault();
    if (Product.isLogin()) {
      if (!Validator.trim(this.state.deliveryAddress)) {
        Product.toastAlert("error", "Please enter delivery address!");
        return;
      }

      Product.postApi(
        "APIS_REGISTER_BID",
        {
          liveStreamingId: this.props.match.params.livestreamingId,
          productId: this.state.products._id,
          userId: Product.getAuthUserId(),
          deliveryAddress: this.state.deliveryAddress,
          notifyMe: this.state.notify,
        },
        async (response) => {
          this.setState({ deliveryAddress: "", notify: false });
        },
        true
      );
    } else {
      Product.toastAlert("error", "Please Login !");
    }
  };

  getProductById = () => {
    console.log("hello");
    const productId = this.props.match.params.productId;
    const liveStreamingId = this.state.liveStreamingId;
    console.log("productId", productId, "liveStreamingId", liveStreamingId);
    Product.postApi(
      "APIS_GET_LIVE_PRODUCT_BY_SLUG",
      { productSlug: productId, streamId: liveStreamingId },
      async (response, status) => {
        if (response) {
          console.log("response", response);
          window.scrollTo(0, 0);
          this.setState({ productData: response });
          this.setState({ productDataId: productId });
          this.setState({ products: response.products });
          this.setState({ isWishlist: response.is_wishlist });
          this.setState({ weekendData: response.weekendDealData });

          if (response.products.delivery === 1) {
            this.setState({ showDeliveryCharge: false });
          }

          // sorting Images order
          if (
            response.products.productMainImage &&
            response.products.productImages
          ) {
            response.products.productImages.sort(function (x, y) {
              return x === response.products.productMainImage
                ? -1
                : y === response.products.productMainImage
                  ? 1
                  : 0;
            });
          }

          if (
            this.state.generalSettingsData &&
            this.state.generalSettingsData?.miscellaneous
          ) {
            let adminCost = this.state.generalSettingsData.miscellaneous.map(
              (item) => {
                //return ((this.state.products.productPrice*item.adminServiceCharge)/100);
                return item.adminServiceCharge;
              }
            );
            this.setState({ adminCost: Number(adminCost) });
          }

          // Recently View Product::Start

          const oldRecentlyViewProducts = JSON.parse(
            Storage.getLocalData("recentlyViewProducts")
          );
          var recentlyViewProducts;
          if (oldRecentlyViewProducts !== null) {
            recentlyViewProducts = [
              ...oldRecentlyViewProducts,
              response.products,
            ];
          } else {
            recentlyViewProducts = [response.products];
          }

          const removeDuplicateRecords = this.removeDuplicates(
            recentlyViewProducts,
            (item) => item._id
          );

          Storage.setLocalData(
            "recentlyViewProducts",
            JSON.stringify(removeDuplicateRecords)
          );
          // Recently View Product::End

          this.setState({ showPhoneNumber: false });

          // Get All Reports
          Product.getApi(
            "APIS_GET_ALL_REPORTS",
            async (response) => {
              this.setState({ reports: response });
            },
            false
          );
        } else {
          // this.props.history.goBack();
          let productDataId = this.state.productDataId;
          let liveStreamingId = this.state.liveStreamingId;
          Product.redirectTo(
            `upcomingLiveStreamingProduct/${productDataId}/${liveStreamingId}`
          );
        }
      },
      true
    );
  };

  removeDuplicates = (data, key) => {
    return [...new Map(data.map((item) => [key(item), item])).values()];
  };

  addWishlistHandler = (id) => {
    // const { products } = this.state.productData;

    if (Product.isLogin()) {
      this.setState({ isLoading: true });
      Product.postApi(
        "APIS_ADD_TO_WISHLIST",
        { productId: id },
        async (response) => {
          this.getProductById();
          this.setState({ isLoading: false });
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  removeWishlistHandler = (id) => {
    if (Product.isLogin()) {
      this.setState({ isLoading: true });
      Product.postApi(
        "APIS_REMOVE_FROM_WISHLIST",
        { productId: id },
        async (response) => {
          this.getProductById();
          this.setState({ isLoading: false });
        },
        true
      );
    } else {
      window.location = `/login`;
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { minLength, maxLength } = this.state;
    const { name, email, review, title, rating, productData } = this.state;
    const data = {
      review,
      rating,
      productId: productData.products._id,
    };

    if (
      (await ProductReview.checkValidation(
        await ProductReview.validationRules(minLength, maxLength),
        data
      )) === true
    ) {
      Product.postApi(
        "APIS_ADD_PRODUCT_REVIEW",
        data,
        async (response) => {
          this.setState({
            review: "",
            title: "",
            rating: 0,
            name: Product.isLogin() ? name : "",
            email: Product.isLogin() ? Storage.getLocalData("username") : "",
          });
          this.getProductById();
        },
        true
      );
    }
  };

  // Reveal Phone Number
  /* revealPhoneNumberHandler = () => {
    if (Product.isLogin()) {
      this.setState({ showPhoneNumber: !this.state.showPhoneNumber });
    } else {
      Product.toastAlert("warn", "Please Login");
    }
  }; */

  // Toggle Reply as Seller
  toggleReplyAsSeller = (e, id) => {
    e.preventDefault();
    const { showReplyAsSeller } = this.state;

    showReplyAsSeller === id
      ? this.setState({ showReplyAsSeller: "" })
      : this.setState({ showReplyAsSeller: id, reply: "" });
  };

  // Submit Reply
  handleReplySubmit = async (e, id) => {
    e.preventDefault();
    const { reply } = this.state;
    if (
      (await ProductReview.checkValidation(
        await ProductReview.validationRules(),
        { reply }
      )) === true
    ) {
      this.setState({ isLoading: true });
      Product.postApi(
        "APIS_POST_REPLY_REVIEW",
        { id, reply },
        async (response) => {
          this.setState({ showReplyAsSeller: "", reply: "" });
          this.getProductById();
          this.setState({ isLoading: false });
        },
        true
      );
    }
  };

  // Report handler
  reportCheckHandler = (e) => {
    const { selectedReports } = this.state;
    if (e.target.checked) {
      var newValues = [...selectedReports, e.target.value];
      this.setState({ selectedReports: newValues });
    } else {
      this.setState({
        selectedReports: selectedReports.filter((id) => id !== e.target.value),
      });
    }
  };

  reportSubmitHandle = async (e) => {
    e.preventDefault();
    const { id, reportName, reportEmail, selectedReports, productData } =
      this.state;

    if (Report.isLogin()) {
      this.setState({ isLoading: true });
      Report.postApi(
        "APIS_POST_REPORT",
        {
          id,
          report_id: selectedReports,
          product_id: productData.products._id,
        },
        async (response) => {
          this.setState({ selectedReports: [] });
          this.setState({ isLoading: false });
        },
        true
      );
    } else {
      if (
        (await Report.checkValidation(await Report.validationRules(), {
          reportName,
          reportEmail,
        })) === true
      ) {
        var data = {
          name: reportName,
          email: reportEmail,
          report_id: selectedReports,
          product_id: productData.products._id,
        };
        this.setState({ isLoading: true });
        Report.postApi(
          "APIS_POST_REPORT",
          data,
          async (response) => {
            this.setState({
              reportName: "",
              reportEmail: "",
              selectedReports: [],
            });
            this.setState({ isLoading: false });
          },
          false
        );
      }
    }
  };

  getPostingFor = (givenTime) => {
    if (givenTime) {
      var days = moment().diff(givenTime, "days");

      if (days > 30) {
        var months = moment().diff(givenTime, "months");
        if (months > 12) {
          var years = moment().diff(givenTime, "years");
          return years + "+" + " years";
        } else {
          return months + "+" + " months";
        }
      } else {
        return days + "+" + " days";
      }
    }
  };

  getRegisteredStatus = () => {
    const { auctionProduct, liveStreamingId } = this.state;

    Product.postApi(
      "APIS_GET_REGISTERED_BID_STATUS",
      { liveStreamingId: liveStreamingId, userId: Product.getAuthUserId() },
      async (response) => {
        if (response) {
          if (moment(auctionProduct?.time).diff(moment(), "seconds") < 0) {
            Product.redirectTo(`livestreamingProduct/${liveStreamingId}`);
          } else {
            Product.toastAlert("info", "You have already registered");
            Product.redirectTo("");
          }
        } else {
          this.setState({ isDisabled: false });
        }
      },
      true
    );
  };

  bidAllowHandle = (e) => {
    let { allowedBidUser } = this.state;
    let { checked, value } = e.target;
    let index = allowedBidUser.indexOf(value);

    if (checked && index === -1) {
      allowedBidUser.push(value);
    } else {
      allowedBidUser.splice(index, 1);
    }
    this.setState(allowedBidUser);
    console.log("this.state.allowedBidUser", this.state.allowedBidUser);
  };

  submitRegistarBidForm = async () => {
    let frontUrl = process.env.REACT_APP_FRONTEND_URL;
    let { allowedBidUser, notify, isAllowNewsletter } = this.state;
    const productId = this.props.match.params.productId;
    const liveStreamingId = this.props.match.params.livestreamingId;
    let buyer_id = Order.getAuthUserId();
    console.log("buyerid", allowedBidUser);

    if (allowedBidUser.length === 0) {
      Product.toastAlert("error", "One ad must be sellected as minimum!");
      return;
    }

    if (
      (await CheckoutModel.checkValidation(CheckoutModel.validationRules())) ===
      true
    ) {
      Order.postApi(
        "APIS_REGISTER_BID",
        {
          liveStreamingId: liveStreamingId,
          productId: productId,
          userId: buyer_id,
          allowedBidUser: allowedBidUser,
          notifyMe: notify,
          isAllowNewsletter: isAllowNewsletter,
        },
        async (response) => {
          if (response) {
            console.log("save response", response);
            Order.postApi(
              "APIS_STRIPE_SETUP",
              {
                buyer_id: buyer_id,
                success_url: `${frontUrl}/auctionRegisterToBid/${productId}/${liveStreamingId}`,
                cancel_url: `${frontUrl}/auctionRegisterToBid/${productId}/${liveStreamingId}`,
              },
              async (response, status) => {
                if (status == "success" && response) {
                  window.location.href = `${response.url}`;
                } else {
                  console.log("error");
                }
              },
              true
            );
          } else {
            Order.toastAlert("error", "Something went wrong!");
          }
        },
        true
      );
    } else {
      console.log("error");
    }
  };
  updateRegistrationDetails = async () => {
    let liveStreamingId = this.props.match.params.livestreamingId;
    let session_id = this.state.session_id;
    let buyer_id = Order.getAuthUserId();
    try {
      if (session_id) {
        Order.postApi(
          "APIS_UPDATE_REGISTER_BID",
          {
            liveStreamingId: liveStreamingId,
            session_id: session_id,
            userId: buyer_id,
          },
          async (response) => {
            if (response) {
              console.log("stripe response", response);
              this.onSuccess();
            } else {
              this.onClose();
              Order.toastAlert("error", "Something went wrong!");
            }
          },
          true
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  onSuccess = () => {
    let { auctionProduct } = this.state;
    this.setState({ isLoading: false });
    Product.redirectTo(`livestreamingProduct/${auctionProduct.id}`);
  };

  onClose = () => {
    this.setState({ isLoading: false });
    // this.setState({checkOutPopup:false});
  };

  onLoading = (value) => {
    this.setState({ isLoading: value });
  };

  getAllCountries = () => {
    MyProfile.postApiByParams(
      "APIS_GET_COUNTRIES",
      {},
      async (response, status) => {
        if (status === "success") {
          this.setState({
            countries: response.data,
          });
        }

        this.getAllRegions();
        this.getAllCities();
      },
      true
    );
  };

  getAllRegions = () => {
    let country_id = this.state.postageDetail?.country_id
      ? this.state.postageDetail.country_id
      : "";
    if (country_id != undefined && country_id != "") {
      MyProfile.postApiByParams(
        "APIS_GET_STATE",
        { country_id: country_id },
        async (response, status) => {
          if (status === "success") {
            this.setState({
              regions: response.data,
            });
          }
        },
        true
      );
    }
  };

  getAllCities = () => {
    let state_id = this.state.postageDetail?.region_id
      ? this.state.postageDetail.region_id
      : "";
    if (state_id != undefined && state_id != "") {
      MyProfile.postApiByParams(
        "APIS_GET_CITIES",
        { state_id: state_id },
        async (response, status) => {
          if (status === "success") {
            this.setState({
              cities: response.data,
            });
          }
        },
        true
      );
    }
  };

  handelCountryDetail = (event) => {
    const { postageDetail } = this.state;
    postageDetail[event.target.name] = event.target.value;
    this.setState(postageDetail, () => {
      this.getAllRegions();
    });
  };

  handelRegionDetail = (event) => {
    const { postageDetail } = this.state;
    postageDetail[event.target.name] = event.target.value;
    this.setState(postageDetail, () => {
      this.getAllCities();
    });
  };

  handlePostageAddress = (event) => {
    const { postageDetail } = this.state;
    postageDetail[event.target.name] = event.target.value;
    this.setState(postageDetail);
  };

  getDiffInDayHour = (givenTime) => {
    if (givenTime) {
      let hours = Math.abs(moment().diff(givenTime, "hours"));
      if (hours > 23) {
        let days = hours / 24;
        let day = parseInt(days.toString().split(".")[0]);
        let hour = hours - day * 24;
        return day + "d " + hour + "h ";
      } else {
        if (hours == 0) {
          let min = Math.abs(moment().diff(givenTime, "minutes"));
          return min + " min";
        } else {
          return hours + "h";
        }
      }
    }
  };
  goback = () => {
    console.log("back");
    let productDataId = this.state.productDataId;
    let liveStreamingId = this.state.liveStreamingId;
    Product.redirectTo(
      `upcomingLiveStreamingProduct/${productDataId}/${liveStreamingId}`
    );
    // `${process.env.REACT_APP_FRONTEND_URL}/auctionRegisterToBid/${productDataId}/${liveStreamingId}`
  };

  render() {
    const {
      products,
      reviews,
      user,
      avgRating,
      similarProducts,
      productCustomFields,
      weekendDealData,
      streamAllProducts,
    } = this.state.productData;

    const {
      isLoading,
      productDataId,
      auctionProduct,
      allCategories,
      paymentAmount,
      postageDetail,
      countries,
      cities,
      regions,
      liveStreamingId,
      isModalOpen,
    } = this.state;

    // const onSuccess = () => {
    //   this.setState({checkOutPopup:false});

    //   this.setState({isLoading:false});
    //   //if (moment(auctionProduct?.time).diff(moment(), "seconds") < 0) {
    //     Product.redirectTo(`livestreamingProduct/${auctionProduct.id}`);
    //   /* } else {
    //     Product.redirectTo("");
    //   } */

    //   /* Product.postApi(
    //     "APIS_REGISTER_BID",
    //     {
    //       liveStreamingId: this.props.match.params.livestreamingId,
    //       productId: this.state.products._id,
    //       userId: Product.getAuthUserId(),
    //       deliveryAddress: this.state.deliveryAddress,
    //       token:token
    //     },
    //     async (response) => {
    //       if (response) {
    //         this.setState({isLoading:false});
    //         if (moment(auctionProduct?.time).diff(moment(), "seconds") < 0) {
    //           Product.redirectTo(`livestreamingProduct/${auctionProduct.id}`);
    //         } else {
    //           Product.redirectTo("");
    //         }
    //       }
    //     },
    //     true
    //   ); */
    // };

    // const onClose = () => {
    //   this.setState({isLoading:false});
    //   this.setState({checkOutPopup:false});
    // };

    // const onLoading = (value) => {
    //   this.setState({isLoading:value});
    // };

    return (
      <React.Fragment>
        {/* <video id="localVideoElem" autoPlay width="200" height="200"></video> */}
        {isLoading && (
          <div className="loader-wrapper" id="loader_imgs">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        {products ? (
          <>
            <section className="video-chat-header">
              <div className="container">
                <Link to="#!" className="video-chat-btn">
                  <span>
                    <img src="/images/video-chat-icon.png" alt="icon" />
                  </span>{" "}
                  Open Video Chat
                </Link>
                {/* <a href="#" className="number-reveal-btn ml-2">
                  <img src="/images/phone-icon.png" alt="icon" />{" "}
                  <span>Reveal Phone Number</span>
                </a> */}
              </div>
            </section>

            <section className="product-header bg-light-dark">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="breadcrumb-bx">
                      <div className="breadcrumb-bx-inner">
                        <a
                          href={void 0}
                          onClick={() => this.goback()}
                          className="btnx btn-primaryx"
                        >
                          <i className="fas fa-long-arrow-alt-left"></i> Back
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="repeat-section product-detail-main-bx jproductdmainbx bg-light-dark">
              <div className="container">
                <div className="row">
                  <div className="col-xl-7 col-lg-7 m-auto">
                    <div className="product-detail-heading">
                      <div className="">
                        {/* <h2>{products.productName}</h2> */}
                        {/* {console.log("product", products)} */}
                        <p className="font-size-14 font-weight-500">{products.location}</p>
                      </div>
                      {/* <div className="livestrimingV text-right">
                        <h5>
                          Live Auction
                        </h5>
                        <h6>Video Streaming</h6>
                      </div> */}
                    </div>
                    {/* <div className="starting_estimate">
                      <div className="starting_left">
                        <p>
                          Starting Price:{" "}
                          <strong>
                            {General.showPriceDecimal(products.starting_price)}
                          </strong>
                        </p>
                      </div>
                    </div> */}
                    <div className="bidding_registertion">
                      <form onSubmit={this.handlerRegisterAuction}>
                        <h4 className="compltr_title">
                          Complete Bidding Registration
                        </h4>
                        <div className="auctiontime">
                          <div className="auctionlef">
                            Auction Starting Time
                          </div>
                          <div className="auctionrig font-size-16">
                            <Moment format="LLL">{auctionProduct?.time}</Moment>
                          </div>
                        </div>
                        <div className="auctiontime01">
                          <div className="auctionlef01">
                            Auction Remaning Time
                          </div>
                          <div className="auctionrig01">
                            {moment(auctionProduct?.time).diff(
                              moment(),
                              "seconds"
                            ) > 0 ? (
                              <>
                                <Countdown
                                  date={auctionProduct?.time}
                                  renderer={({
                                    days,
                                    hours,
                                    minutes,
                                    seconds,
                                  }) => (
                                    <time>
                                      {days +
                                        "days" +
                                        " " +
                                        hours +
                                        ":" +
                                        minutes +
                                        ":" +
                                        seconds}{" "}
                                    </time>
                                  )}
                                />
                              </>
                            ) : (
                              <time>Live</time>
                            )}
                          </div>
                        </div>
                        <div className="getafree">
                          <label className="notif06">
                            You can bid on the following Items from this seller.
                          </label>
                          {console.log("mahesh", streamAllProducts)}
                        </div>
                        <div className="productBoxBid row">
                          {streamAllProducts &&
                            streamAllProducts.map((row, index) => {
                              return (
                                <div
                                  className="col-lg-6 col-12 mt-3"
                                  key={row._id}
                                >
                                  <div className="product-outer-bx product-outer-live biding_Main_box">
                                    <div className="product-img-bx">
                                      {console.log("jonty", row)}
                                      {row.productMainImage ? (
                                        <img
                                          src={General.showImage(
                                            "products/resize-180-180",
                                            row.productMainImage
                                          )}
                                          alt={row.productName}
                                        />
                                      ) : (
                                        <img
                                          src="/images/no-image.jpg"
                                          alt="product"
                                        />
                                      )}
                                    </div>
                                    <div className="product-name-bx">
                                      <h4>{row.productName}</h4>
                                      <h6 className="protection_track">
                                        Starting Price:{" "}
                                        <span className="font-size-16">
                                          {" "}
                                          {General.showPriceDecimal(
                                            row.starting_price
                                          )}
                                        </span>
                                      </h6>
                                      <h6 className="protection_track">
                                        Selling Estimate:{" "}
                                        <span className="font-size-16">
                                          {" "}
                                          {General.showPriceDecimal(
                                            row.selling_estimate
                                          )}
                                        </span>
                                      </h6>
                                      <div className="protection_track">
                                        <label
                                          for={`noBid_${index}`}
                                          className="mr-2 mt-2 mb-0"
                                        >
                                          {" "}
                                          Bidding
                                        </label>
                                        <input
                                          id={`noBid_${index}`}
                                          name="bidAllow"
                                          value={row.productId}
                                          type="checkbox"
                                          onChange={this.bidAllowHandle}
                                        />
                                      </div>
                                      <div className="bidingBlock_section">
                                        <h6 className="protection_track">
                                          <div className="protection_price_track">
                                            <span className="dot"></span>
                                            Buyer protection
                                            <span className="modal_icon" onClick={this.handleOpenModal}>
                                              <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  clip-rule="evenodd"
                                                  d="M11.25 17.262L11.202 12H9.375V10.5H12.9765L13.0215 15.762H14.7V17.262H11.25ZM10.875 7.959C10.8643 7.64145 10.9754 7.33181 11.1855 7.0935C11.3915 6.8635 11.6865 6.749 12.0705 6.75C12.4545 6.75 12.7515 6.865 12.9615 7.095C13.1715 7.324 13.276 7.612 13.275 7.959C13.275 8.301 13.17 8.585 12.96 8.811C12.75 9.037 12.4535 9.15 12.0705 9.15C11.6875 9.15 11.3925 9.037 11.1855 8.811C10.9745 8.57854 10.8631 8.27268 10.875 7.959ZM12 19.5C13.9891 19.5 15.8968 18.7098 17.3033 17.3033C18.7098 15.8968 19.5 13.9891 19.5 12C19.5 10.0109 18.7098 8.10322 17.3033 6.6967C15.8968 5.29018 13.9891 4.5 12 4.5C10.0109 4.5 8.10322 5.29018 6.6967 6.6967C5.29018 8.10322 4.5 10.0109 4.5 12C4.5 13.9891 5.29018 15.8968 6.6967 17.3033C8.10322 18.7098 10.0109 19.5 12 19.5ZM12 21C10.8181 21 9.64778 20.7672 8.55585 20.3149C7.46392 19.8626 6.47177 19.1997 5.63604 18.364C4.80031 17.5282 4.13738 16.5361 3.68508 15.4442C3.23279 14.3522 3 13.1819 3 12C3 10.8181 3.23279 9.64778 3.68508 8.55585C4.13738 7.46392 4.80031 6.47177 5.63604 5.63604C6.47177 4.80031 7.46392 4.13738 8.55585 3.68508C9.64778 3.23279 10.8181 3 12 3C14.3869 3 16.6761 3.94821 18.364 5.63604C20.0518 7.32387 21 9.61305 21 12C21 14.3869 20.0518 16.6761 18.364 18.364C16.6761 20.0518 14.3869 21 12 21Z"
                                                  fill="#25324B"
                                                />
                                              </svg>
                                            </span>
                                          </div>
                                          <span className="font-size-16">{General.showPercentDecimal(row?.buyerProtectionCharge)} + {General.showPriceDecimal(this.state.adminCost)}</span>
                                        </h6>
                                        <BuyerProtectionModal
                                          open={isModalOpen}
                                          handleClose={this.handleCloseModal}
                                          generalSettingsData={
                                            this.state.generalSettingsData
                                          }
                                        />
                                      </div>
                                      <h6 className="protection_track">
                                        <div className="protection_price_track">
                                          <span className="dot"></span>
                                          {row.delivery !== 1 ?
                                            <>Shipping :</>
                                            :
                                            <>Local collection from seller</>
                                          }
                                        </div>
                                        {row.delivery !== 1 &&
                                          <span>{General.showPriceDecimal(row?.deliveryCost)}</span>
                                        }
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                        <div className="checkout_cards mb-3">
                        </div>
                        <div className="getafree">
                          <div className="check-box">
                            <input
                              type="checkbox"
                              name="option"
                              id="notif01"
                              onChange={(e) =>
                                this.setState({ notify: e.target.checked })
                              }
                            />
                            <label htmlFor="notif01">
                              You will get notificaiton when the auction slot
                              has started
                            </label>
                          </div>

                          <div className="check-box">
                            <input
                              type="checkbox"
                              name="option"
                              id="notif06"
                              onChange={(e) =>
                                this.setState({
                                  isAllowNewsletter: e.target.checked,
                                })
                              }
                            />
                            <label htmlFor="notif06">
                              Get a free daily newsletter about live and
                              upcomming auctions
                            </label>
                          </div>
                        </div>
                        <div className="priv_policy">
                          <p className="font-size-14">
                            By clicking “Register to Bid” I agree that I have
                            the site’s{" "}
                            <a href="/privacy-policy" target="_blank">
                              {" "}
                              Privacy Policy
                            </a>{" "}
                            and accept the{" "}
                            <a href="/term-conditions" target="_blank">
                              {" "}
                              Terms of Services
                            </a>
                            .
                          </p>
                        </div>

                        <div className="registerbtn">
                          <div className="form-group">
                            <button
                              type="button"
                              onClick={() => {
                                this.submitRegistarBidForm();
                              }}
                              className="btnx btn-secondaryx font-size-16"
                              disabled={this.state.isDisabled}
                            >
                              Register to Bid
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="bg-light-dark">
              <div></div>
            </section>
          </>
        ) : (
          <section className="product-listing-main-bx bg-light-dark actionpage_topO mobiledisplay_oneline">
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <ContentLoader
                    speed={2}
                    width={400}
                    height={460}
                    viewBox="0 0 400 460"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  // {...props}
                  >
                    <circle cx="31" cy="31" r="15" />
                    <rect x="58" y="18" rx="2" ry="2" width="140" height="10" />
                    <rect x="58" y="34" rx="2" ry="2" width="140" height="10" />
                    <rect x="0" y="60" rx="2" ry="2" width="400" height="260" />
                  </ContentLoader>
                </div>

                <div className="col-lg-5">
                  <ContentLoader
                    speed={2}
                    width={400}
                    height={460}
                    viewBox="0 0 400 460"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  // {...props}
                  >
                    <rect
                      x="58"
                      y="58"
                      rx="2"
                      ry="2"
                      width="350"
                      height="500"
                    />
                  </ContentLoader>
                </div>
              </div>
            </div>
          </section>
        )}
        {/* <CardTokenPopUp parentState={this.state} onClose={onClose} onSuccess={onSuccess} onLoading={onLoading}/> */}
      </React.Fragment>
    );
  }
}

export default connect(null, {
  OpenMessageBox,
  MessageData,
  SetSelectedChannel,
  SetOutgoingCallState,
  SetQuickbloxSession,
  SetSelectedUser,
  SetContactList,
})(AuctionRegisterToBid);
