import React, { Component } from "react";
import General from "Helpers/General";
import MyProfile from "Models/MyProfile";
import Storage from "Helpers/Storage";
import swal from 'sweetalert';
import Settings from "Models/Settings";
import { withRouter } from 'react-router-dom';
import Modal from "react-modal";
var qs = require("qs");

export class MyTotalBalance extends Component {

  constructor(props) {
    super(props);
    let code_token = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).code;
    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      bankDetail: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["bankDetail"]
        : {},
      firstName: "",
      lastName: "",
      code_token: (code_token) ? code_token : "",
      isActivateBalance: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["isActivateBalance"]
        : false,
      totalPendingBalance: 0,
      totalWithdrawlBalance: 0,
      businessType: 0,
      showBusinessTypeModel: false,
      buttonDisable: false,
      isLoading: false,
      generalSettingsData: localStorage.getItem("generalSettingsData")
        ? JSON.parse(localStorage.getItem("generalSettingsData"))
        : {},
    }
  }

  componentDidMount = async () => {
    let stripe_code = this.state.code_token;
    if (stripe_code) {
      this.setState({ buttonDisable: true, isLoading: true });
      const stripeRespo = await this.saveStripeAccountDetails(stripe_code);
      if (stripeRespo.status === "success") {
        this.setState({ isLoading: false });
        MyProfile.redirectTo("my-profile/balance");
      } else {
        this.setState({ isLoading: false });
      }
    }
    this.getUserByEmail();
    this.getTotalBalance();
    this.getAdminSettings();
  };

  getAdminSettings = (stripe_code) => {
    Settings.getApi(
      "APIS_GET_GENERAL_SETTING",
      async (response) => {
        console.log("generalSettingsData", JSON.stringify(response[0]));
        localStorage.setItem(
          "generalSettingsData",
          JSON.stringify(response[0])
        );
        this.setState({ generalSettingsData: response[0] });
      },
      false
    );
  };

  saveStripeAccountDetails = (stripe_code) => {
    return new Promise((resolve) => {
      MyProfile.postApi(
        "SAVE_STRIPE_ACCOUNT_DETAILS",
        { code: stripe_code },
        (response, status) => {
          resolve({ 'status': status });
        },
        true
      );
    });
  };

  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          Storage.setLocalData("UserData", JSON.stringify(response));
          this.setState({
            firstName: (response?.firstName) ? response.firstName : '',
            lastName: (response?.lastName) ? response.lastName : '',
            bankDetail: (response?.bankDetail) ? response.bankDetail : {},
            isActivateBalance: (response.isActivateBalance) ? response.isActivateBalance : false,
          });

          if (response?.stripe_account_id && response.businessType === 0 && response.stripe_account_id != null && response.stripe_account_id) {
            this.setState({
              showBusinessTypeModel: true,
            });
          }

        }
      },
      true
    );
  };

  getTotalBalance = () => {
    const { userId } = this.state;
    MyProfile.getApi(
      "API_GET_SELLER_TOTAL_BALANCE",
      async (res) => {
        if (res) {
          this.setState({
            totalPendingBalance: res.totalPendingBalance,
            totalWithdrawlBalance: res.totalWithdrawlBalance
          });
        }
      },
      { params: { seller_id: userId } },
      true
    );
  }

  handleSubmitStripe = () => {

    this.setState({ buttonDisable: true, isLoading: true });

    let stripeUrl = process.env.REACT_APP_STRIPE_AUTH_URL;
    let email = localStorage.getItem("username");
    let firstName = this.state.firstName;
    let lastName = this.state.lastName;
    let frontUrl = process.env.REACT_APP_FRONTEND_URL;
    let redirectUrl = `${frontUrl}/my-profile/balance`;
    let clientId = process.env.REACT_APP_STRIPE_CLIENT_ID;

    window.location.href = `${stripeUrl}?response_type=code&client_id=${clientId}&scope=read_write&stripe_user[email]=${email}&stripe_user[first_name]=${firstName}&stripe_user[last_name]=${lastName}&redirect_uri=${redirectUrl}`;

  };


  submitBusinessTypeDetail = async (event) => {
    // event.preventDefault();
    const { businessType } = this.state;
    if (
      (await MyProfile.checkValidation(await MyProfile.validationRules(), {
        businessType
      })) === true
    ) {
      this.setState({ isLoading: true });
      MyProfile.postApi(
        "API_UPDATE_ACCOUNT_DETAIL",
        {
          businessType,
        },
        async (response, err) => {
          if (response) {
            this.setState({ isLoading: false, showBusinessTypeModel: false });
          }
        },
        true
      );
    }
  };


  handelWithdrawPayment = () => {
    const { totalWithdrawlBalance } = this.state;
    swal({
      title: "Are you sure. You want to withdraw your funds?",
      text: "Please confirm your account information!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.confirmWithdrawAmount()
      }
    });
  }

  confirmWithdrawAmount = () => {
    const { totalWithdrawlBalance } = this.state;
    swal({
      title: "Confirm Withdrawal Amount",
      text: "Please confirm the withdrawal amount:",
      content: {
        element: "input",
        attributes: {
          type: "number",
          value: totalWithdrawlBalance.toString(),
          min: 0,
          max: totalWithdrawlBalance // Maximum withdrawal amount allowed
        }
      },
      buttons: {
        cancel: "Cancel",
        confirm: {
          text: "OK",
          closeModal: false // Keep the modal open after clicking "OK"
        }
      },
      closeOnClickOutside: false,
    }).then((value) => {
      if (value === null) return; // Check if user clicked outside or pressed cancel button
      const withdrawalAmount = parseFloat(document.querySelector('.swal-content__input').value);
      let mimimumWithdrawAmount = this.state.generalSettingsData["miscellaneous"][0]['mimumWithdraw'];
      if (withdrawalAmount >= mimimumWithdrawAmount) {
        console.log("mimimumWithdrawAmount", typeof mimimumWithdrawAmount);
        console.log("withdrawalAmount", withdrawalAmount);
        if (isNaN(withdrawalAmount) || withdrawalAmount <= 0 || withdrawalAmount > totalWithdrawlBalance) {
          console.log("totalWithdrawlBalance", totalWithdrawlBalance)
          console.log("withdrawalAmount", withdrawalAmount);
          swal("Error", "Enter a valid amount less than or equal to your balance.", "error");
          return;
        }
        this.setState({ isLoading: true });
        MyProfile.postApi(
          "API_WITHDRAW_AMOUNT",
          { totalWithdrawlBalance: withdrawalAmount },
          async (response, status) => {
            this.setState({ isLoading: false });
            if (status == "success") {
              let balance = totalWithdrawlBalance - withdrawalAmount
              this.setState({ totalWithdrawlBalance: balance });
              swal("Success", "Withdrawal successful!", "success");
            } else {
              swal("Error", response.message || "Failed to withdraw funds.", "error");
            }
          },
          true
        );
      } else {
        swal("Error", 'Please enter minimum ' + mimimumWithdrawAmount + ' amount for withdrawal' || "Failed to withdraw funds.", "error");
      }
    }).catch((err) => {
      console.log("Error:", err);
    });
  }

  render() {
    const { totalWithdrawlBalance, isActivateBalance, isLoading, showBusinessTypeModel } = this.state;
    return (
      <> 
      {isLoading && (
        <div className="loader-wrapper">
          <div className="loader">
            <img src={this.state.headerLogo} alt="" />
            <div className="material-spinner"></div>
          </div>
        </div>
      )} 

      <div
            className="tab-pane active in this_subManage_Listings"
            id="total-balance"
            role="tabpanel"
          >
            <div className="subtab_prodlist_NEW_forpadd">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 d-none d-md-block">
                            <div className="subcateleft_imgsec">
                                <img
                                src="/images/account-settings-limg.png"
                                alt="icon"
                                />
                            </div>
                        </div>
                        <div className="col-lg-9">
                            <div className="formnotification_inner withbg_white border-radius border-bottom_1 ">
                                  <div className="noti_enable repeatSec_innerr border-0 text-center"> 
                                    {General.showPriceDecimal(totalWithdrawlBalance)}
                                    {totalWithdrawlBalance > 0 ? ( 
                                          <button
                                          className="withdraw-btn"
                                          onClick={this.handelWithdrawPayment}
                                          >
                                          Withdraw
                                        </button>) : null}
                                        {/* {if(totalWithdrawlBalance > 0){
                                          <button
                                          className="withdraw-btn"
                                          onClick={this.handelWithdrawPayment}
                                          >
                                          Withdraw
                                        </button>
                                        }} */}
                                        
                                    
                                    {isActivateBalance === false && ( 
                                      <button
                                        className="withdraw-btn"
                                        onClick={this.handleSubmitStripe}
                                        disabled={this.state.buttonDisable}
                                        >
                                        Activate Balance
                                      </button>
                                    )}
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
      
       
        <Modal
          isOpen={showBusinessTypeModel}
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showBusinessTypeModel: false })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
          className="shipModal"
        >
          <button onClick={() => this.setState({ showBusinessTypeModel: false })} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
          <div
            className="col-lg-12 listside_newperent"
          >
            <div className="formnotification_inner withbg_white border-radius">
              <div className="col-12">
                <div className="logo-bx modal-title">
                  {" "}
                  <a href="/">
                    {" "}
                    <img
                      src={this.state.headerLogo}
                      alt="logo.png"
                      width="250px"
                    />{" "}
                  </a>{" "}
                </div>
              </div>
              <div>
                <div className="mt-3 onlyformob_seller">
                  <p className="popup-info info-header">Please select your account type on stripe</p>
                  <ul className="businesstype">
                    <li>
                      <div className="check-box big">
                        <input
                          className="form-control-line New_control"
                          type="radio"
                          value="2"
                          onChange={() => this.setState({ businessType: 1 })}
                          name="sellerType"
                          id="Company"
                        />
                        <label htmlFor="Company">Company</label>
                      </div>
                    </li>
                    <li>
                      <div className="check-box big">
                        <input
                          className="form-control-line New_control"
                          type="radio"
                          value="2"
                          onChange={() => this.setState({ businessType: 2 })}
                          name="sellerType"
                          id="Soletrader"
                        />
                        <label htmlFor="Soletrader">Soletrader</label>
                      </div>
                    </li>
                    <li>
                      <div className="check-box big">
                        <input
                          className="form-control-line New_control"
                          type="radio"
                          value="1"
                          name="sellerType"
                          onChange={() => this.setState({ businessType: 3 })}
                          id="Private"
                        />
                        <label htmlFor="Private">Private</label>
                      </div>
                    </li>
                  </ul>
                  <div className="bottonsend text-center">
                    <button
                      type="submit"
                      className="btnx btn-secondaryx"
                      onClick={() => this.submitBusinessTypeDetail()}
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default withRouter(MyTotalBalance);
