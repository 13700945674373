import React, { Component } from "react";
import General from "Helpers/General";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Link, Redirect } from "react-router-dom";
import MyProfile from "Models/MyProfile";
import Storage from "Helpers/Storage";
import Modal from "react-modal";

export class ManageAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      userId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      goesOffline: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["goesOffline"]
        : 0,
      terminationOfUserAccount: false,
      deactivateAccountReasonSelected: "",
      deactivateAccountReasons: [],
      isModalOpen: false,
      generalSettingsData: localStorage.getItem("generalSettingsData")
        ? JSON.parse(localStorage.getItem("generalSettingsData"))
        : {},
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ isModalOpen: true });
  }

  handleCloseModal() {
    this.setState({ isModalOpen: false });
  }

  componentDidMount = () => {
    MyProfile.getApi(
      "APIS_GET_ACCOUNT_DEACTIVATE_REASON",
      async (response) => {
        this.setState({ deactivateAccountReasons: response });
      },
      false
    );

    MyProfile.getApi(
      "APIS_GET_ACCOUNT_SETTING",
      async (response) => {
        if (response && response[0]["common_setting"]) {
          this.setState({
            terminationOfUserAccount:
              response[0]["common_setting"][0]["terminationOfUserAccount"],
          });
        }
      },
      false
    );

    this.getUserByEmail();
  };

  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          Storage.setLocalData("UserData", JSON.stringify(response));
          this.setState({
            userId: response._id,
            goesOffline: response.goesOffline ? response.goesOffline : 0,
          });
        }
      },
      true
    );
  };

  deactivateAccountHandler = (e) => {
    e.preventDefault();
    const { deactivateAccountReasonSelected } = this.state;
    confirmAlert({
      title: "Confirm to deactivate account",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ isLoading: true });
            MyProfile.postApiByParams(
              "APIS_POST_ACCOUNT_DEACTIVATE",
              { reason: deactivateAccountReasonSelected },
              async (response) => {
                if (response.data === 200) {
                  this.setState({ isLoading: false });
                  MyProfile.logout();
                }
              },

              true
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  handleSubmitChangeGoOfflineStatus = async (event) => {
    event.preventDefault();
    const { goesOffline } = this.state;
    this.setState({ isLoading: true });
    MyProfile.postApi(
      "API_UPDATE_ACCOUNT_DETAIL",
      {
        goesOffline,
      },
      async (response, err) => {
        if (response) {
          this.getUserByEmail();
          this.setState({ isLoading: false });
        }
      },
      true
    );
  };

  render() {
    const { deactivateAccountReasons, isModalOpen, generalSettingsData } = this.state;

    return (
      <>
      {  console.log("generalSettingsData", generalSettingsData["miscellaneous"][0])}
        <div
          className="tab-pane allcom_rodtab active"
          id="subtab024"
          role="tabpanel"
        >
          <div className="subtab_prodlist_NEW_forpadd">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-3 subtab_firstmain noneat_mobile paddingrigt_none_Cfilter d-none d-md-block"
                  id="subtab_secondfixed"
                >
                  <div className="subcateleft_imgsec">
                    <img src="/images/account-settings-limg.png" alt="Image" />
                  </div>
                </div>
                <div className="col-lg-9 subtab_secondmain">
                  <div className="formnotification_inner withbg_white border-radius">
                    <div className="noti_enable repeatSec_innerr repeatSec_innerr_mobile">
                      <div className="box_perentrepet">
                        <form onSubmit={this.handleSubmitChangeGoOfflineStatus}>
                          <div className="contdetail_group">
                            <div className="contact_label">
                              
                                online status{" "}
                                <span
                                  className="statuslabb Go_online"
                                  style={{
                                    color: `${
                                      this.state?.goesOffline === 1
                                        ? "#249ee4"
                                        : "rgb(203, 211, 227)"
                                    }`,
                                  }}
                                >
                                  <i className="fas fa-circle"></i>
                                </span>
                              
                              {/* <p>
                                When online, your Gigs are visible
                                under the Online search filter.
                            </p> */}
                            </div>
                            <div className="cont_fuild">
                              <div className="selectbx_perent">
                                <select
                                  name="goesOffline"
                                  className="social_red_fuild"
                                  value={this.state?.goesOffline}
                                  onChange={(e) =>
                                    this.setState({
                                      goesOffline: e.target.value,
                                    })
                                  }
                                >
                                  <option value="1">Online</option>
                                  <option value="0">Offline</option>
                                </select>
                                <span className="dwnaro">
                                  <i className="fas fa-angle-down"></i>
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="contdetail_group">
                            <div className="bottonsend text-center">
                              <button
                                type="submit"
                                className="btnx btn-secondaryx font-size-14"
                              >
                                Save Changes
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {this.state.terminationOfUserAccount === true && (
                      <div className="noti_enable repeatSec_innerr border_bott_none repeatSec_innerr_mobile">
                        <div className="box_perentrepet">
                          <div className="contdetail_group">
                            <div className="contact_label">
                               deactivate account{" "}
                              <span onClick={this.handleOpenModal}>
                                <i className="fas fa-question-circle"></i>
                              </span>
                            </div>
                            <div className="cont_fuild">
                              <div className="selectbx_perent">
                                <select
                                  name=""
                                  className="social_red_fuild"
                                  onChange={(e) =>
                                    this.setState({
                                      deactivateAccountReasonSelected:
                                        e.target.value,
                                    })
                                  }
                                >
                                  <option value="">Choose a reason</option>
                                  {deactivateAccountReasons &&
                                    deactivateAccountReasons.map((r) => (
                                      <option key={r._id} value={r._id}>
                                        {r.reason}
                                      </option>
                                    ))}
                                </select>
                                <span className="dwnaro">
                                  <i className="fas fa-angle-down"></i>
                                </span>
                              </div>
                            </div>
                            <Modal
                              isOpen={isModalOpen}
                              onRequestClose={this.handleCloseModal}
                              contentLabel="Buyer Protection"
                              className="modal-content"
                              overlayClassName="modal-overlay"
                            >
                              <button
                                onClick={this.handleCloseModal}
                                className="shipCloseBtn"
                              >
                                <i className="fa fa-times "></i>
                              </button>
                              <div className="product-outer-bx productBig-outer-bx">
                                <div className="product-name-bx content_gobottom_new prdNewRow_row">
                                  <div className="cont_fuild">
                                    {generalSettingsData["miscellaneous"]
                                      ? generalSettingsData["miscellaneous"][0]["deactivateAccount"]
                                      : ""}
                                  </div>
                                </div>
                              </div>
                            </Modal>
                          </div>
                          <div className="contdetail_group">
                            <div className="bottonsend text-center">
                              <Link
                                to="#!"
                                onClick={this.deactivateAccountHandler}
                                className="btnx btn-secondaryx font-size-14"
                              >
                                Deactivate Account
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ManageAccount;
