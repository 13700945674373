import React, { Component, useEffect } from "react";
import General from "Helpers/General";
import AddProduct from "Models/AddProduct";
import AdsAddFormTerm from "Elements/AdsAddFormTerm";
import formData from "form-data";
import axios from "axios";
import ApiEndPoints from "Helpers/ApiEndPoints";
import SubCategory from "Models/SubCategory";
import Fields from "Models/Fields";
import Plans from "Models/Plans";
import { Link } from "react-router-dom";
import Order from "Models/Order";
import CheckoutModel from "Models/CheckoutModel";

import validator from "validator";
import Dropzone from "react-dropzone";
import PaymentPopUp from "../Stripe/CheckoutForm";
import PurchaseSubscriptionPopUp from "../Stripe/PurchaseSubscription";
import MyProfile from "Models/MyProfile";

class PostAdForm extends Component {
  constructor(props) {
    super(props);
    const CatData = localStorage.getItem("PostsubCategories")
      ? Object.assign(
        {},
        ...JSON.parse(localStorage.getItem("PostsubCategories")).filter(
          (item) => item._id === props.subCategoryId
        )
      )
      : {};

    this.featuredPlan = React.createRef();
    this.urgentPlan = React.createRef();
    this.spotlightPlan = React.createRef();

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      AddProduct: AddProduct.dbSchema(),
      AddProductCustomField: {},
      subCategoryId: props.subCategoryId,
      recordType: "",
      categoryType: "",
      remainingTitleChars: 90,
      remainingDescriptionChars: 1000,
      categoryName:
        CatData && CatData.categoryId ? CatData.categoryId.categoryName : "",
      subCategoryName: CatData ? CatData.subCategoryName : "",
      parentCatName:
        CatData && CatData.parentId ? CatData.parentId.subCategoryName : "",
      categoryId: "",
      mainImage: "",
      current_package: "",
      allowBuyNow: localStorage.getItem("allowBuyNow")
        ? JSON.parse(localStorage.getItem("allowBuyNow"))
        : [],
      singleSubCategoryData: localStorage.getItem("singleSubCategoryData")
        ? JSON.parse(localStorage.getItem("singleSubCategoryData"))
        : [],
      adTypeDataUrgent: localStorage.getItem("adTypeDataUrgent")
        ? JSON.parse(localStorage.getItem("adTypeDataUrgent"))
        : [],
      adTypeDataSpotlight: localStorage.getItem("adTypeDataSpotlight")
        ? JSON.parse(localStorage.getItem("adTypeDataSpotlight"))
        : [],
      adTypeDataFeatured: [],
      imageData: [],
      allCustomFields: localStorage.getItem("allCustomFields")
        ? JSON.parse(localStorage.getItem("allCustomFields"))
        : [],
      businessType: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["businessType"]
        : 0,
      isShowMap: false,
      paymentbutton: true,
      packages: "",
      package_details: {},
      pack: "",
      current_index: 0,
      paymentAmount: 0,
      showPackage: false,
      isLoading: false,
      checkedAttr: "",
      productImageData: [],
      liveStreaming: false,
      maxImages: 5,
      showDeliveryPrice: false,
      delivery: 0,
      deliveryCost: 0,
      freeDeliveryOptions: 0,
      transaction_type: "plan",
      transaction_type_other: "package",
      checkOutPopup: false,
      tokenReq: false,
      purchaseSubPopup: false,
      isFeatured: false,
      video_stream_amount: 0,
      video_stream_id: "",
      already_feature: "",
      term_duration: 0,
      inputValue: "",
      validationError: "",
    };
  }

  showMapHandler = () => {
    let { AddProduct } = this.state;
    AddProduct["isShowMap"] = !AddProduct["isShowMap"];
    this.setState({ AddProduct: AddProduct });
  };

  showPackage = () => {
    let { showPackage } = this.state;
    showPackage = !showPackage;
    this.setState({ showPackage: showPackage });
  };

  handleChange = (address) => {
    this.setState({ address });
  };

  componentWillMount = () => {
    var self = this;
    let subCategoryId = this.state.subCategoryId;
    SubCategory.postApi(
      "APIS_GET_SUB_CATEGORY_DETAIL_BY_Id",
      { params: { subCategoryId } },
      async (response) => {
        self.setState({ categoryType: response.categoryId.slug });
        self.setState({ categoryId: response.categoryId._id });
        self.setState({ recordType: response.categoryId.categoryName });
        self.setState({ categoryName: response.categoryId.categoryName });
        self.setState({ subCategoryName: response.subCategoryName });
        if (response.parentId) {
          self.setState({ parentCatName: response.parentId.subCategoryName });
          self.setState({ subCategoryId: response.parentId._id });
          self.setState({ thirdCategoryId: subCategoryId });
        }
        localStorage.setItem("singleSubCategoryData", JSON.stringify(response));
        self.setState({ singleSubCategoryData: response });
      },
      false
    );
    this.getFieldsValuesData();
    this.getAdTypePlans();
  };

  /*  getCurrentPackage = () => {
    SubCategory.postApi(
    "API_CURRENT_PACKAGE",
    {},
    async (response) => {
        if(response){
            this.setState({ current_package: response});
            this.getSelectedPackage(null, response.package_id.package_name, 0, response.package_id._id);
        }else{
            this.getSelectedPackage(null, this.state.packages[0].package_name, 0); 
        }
    },
    true
    );
  } */

  getSelectedPackage = async (e = null, package_name, i, id = "") => {
    if (e != null) {
      e.preventDefault();
    }

    SubCategory.postApi(
      "API_SELECT_PACKAGES",
      { package_name: package_name },
      async (response) => {
        /*  if(id != ""){
            i = response.map(function(x) {return x._id; }).indexOf(id);
        } */
        this.setState(
          {
            pack: response[i],
            package_details: response,
            current_index: i,
            term_duration: response[i].duration,
          },
          () => {
            this.getPaidFeatureLableData();
          }
        );
      },
      true
    );
  };

  getPaidFeatureLableData = () => {
    let { current_package, term_duration } = this.state;
    if (
      current_package &&
      current_package != "" &&
      current_package.feature_day &&
      current_package.feature_day.length > 0
    ) {
      let term = "";
      for (let index = 0; index < current_package.feature_day.length; index++) {
        const data = current_package.feature_day[index];
        if (data.duration == term_duration) {
          term = data;
        }
      }
      this.setState({ already_feature: term });
    }
  };

  componentDidMount = () => {
    var self = this;
    this.getUserByEmail();
    let subCategoryId = this.state.subCategoryId;

    SubCategory.postApi(
      "APIS_GET_SUB_CATEGORY_DETAIL_BY_Id",
      { params: { subCategoryId } },
      async (response) => {
        self.setState({ categoryType: response.categoryId.slug });
        self.setState({ categoryId: response.categoryId._id });
        self.setState({ recordType: response.categoryId.categoryName });
        self.setState({ categoryName: response.categoryId.categoryName });
        self.setState({ subCategoryName: response.subCategoryName });
        localStorage.setItem("allowBuyNow", JSON.stringify(response));
        self.setState({ allowBuyNow: response.allowBuyNow });
        localStorage.setItem("singleSubCategoryData", JSON.stringify(response));
        self.setState({ singleSubCategoryData: response });
      },
      false
    );

    this.getFieldsValuesData();
    this.getAdTypePlans();
    let productData = JSON.parse(localStorage.getItem("productData"));
    let deliveryCostPrice = localStorage.getItem("deliveryCost");
    let freeDelivery = localStorage.getItem("freeDeliveryOptions");
    let deliveryNo = localStorage.getItem("delivery");
    this.handleFreeDeliveryOptions1(freeDelivery);
    //  console.log("context", this.state.context);
    //  console.log("AddProductCustomField", this.state.AddProductCustomField);
  };

  getUserByEmail = () => {
    MyProfile.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          if (response.businessType == 0) {
            AddProduct.redirectTo("post-an-ad", true);
          }
        }
      },
      true
    );
  };

  getAdTypePlans() {
    var self = this;
    let subCategoryId = this.state.subCategoryId;

    SubCategory.getApi(
      "APIS_GET_PACKAGES",
      async (response) => {
        this.setState({ packages: response });
        //this.getCurrentPackage();
      },
      false
    );

    /* Plans.postApi(
      "APIS_GET_PLANS_BY_AD",
      { params: { subCategoryId: subCategoryId, ad_type: "Urgent" } },
      async (response) => {
        localStorage.setItem("adTypeDataUrgent", JSON.stringify(response));
        self.setState({ adTypeDataUrgent: response });
      },
      false
    );
    Plans.postApi(
      "APIS_GET_PLANS_BY_AD",
      { params: { subCategoryId: subCategoryId, ad_type: "Spotlight" } },
      async (response) => {
        localStorage.setItem("adTypeDataSpotlight", JSON.stringify(response));
        self.setState({ adTypeDataSpotlight: response });
      },
      false
    );
    Plans.postApi(
      "APIS_GET_PLANS_BY_AD",
      { params: { subCategoryId: subCategoryId, ad_type: "Featured" } },
      async (response) => {
        localStorage.setItem("adTypeDataFeatured", JSON.stringify(response));
        self.setState({ adTypeDataFeatured: response });
      },
      false
    ); */

    Plans.postApi(
      "APIS_GET_FEATURED_PLAN_BY_AD",
      { params: { subCategoryId: subCategoryId, ad_type: "Featured" } },
      async (response, status) => {
        //localStorage.setItem("adTypeDataFeatured", JSON.stringify(response));
        if (status == "success") {
          self.setState({ adTypeDataFeatured: response });
        }
      },
      true
    );
  }

  getFieldsValuesData() {
    var self = this;
    let subCategoryId = this.state.subCategoryId;
    Fields.getApiByParams(
      "APIS_GET_FIELDS_VALUES",
      this.state.subCategoryId,
      async (response) => {
        localStorage.setItem("allCustomFields", JSON.stringify(response));
        self.setState({ allCustomFields: response });
      },
      false
    );
  }

  setimageAsMainImage = (event) => {
    let { AddProduct } = this.state;
    var fileName = event.target.dataset.file;
    AddProduct["productMainImage"] = fileName;
    this.setState({ AddProduct: AddProduct });
    this.setState({ mainImage: fileName });
  };

  submitHandler = (event) => {
    event.preventDefault();
    this.submithandlerFunction();
  };

  submithandlerFunction = async (pay_process = false) => {
    let frontUrl = process.env.REACT_APP_FRONTEND_URL;
    const { AddProduct: AddProduct1 } = this.state;
    // console.log("AddProduct1",AddProduct1)
    //return
    if (!SubCategory.isLogin()) {
      if (
        AddProduct1["emailCheck"] === false &&
        AddProduct1["phoneCheck"] === false
      ) {
        SubCategory.toastAlert("error", "please Enter a valid contact details");
        return;
      }

      if (AddProduct1["emailCheck"] === true) {
        if (
          !validator.isEmail(AddProduct1["email"]) ||
          AddProduct1["email"] === ""
        ) {
          SubCategory.toastAlert("error", "please Enter a valid email address");
          return;
        }
      }
      if (AddProduct1["phoneCheck"] === true) {
        if (
          !validator.isMobilePhone(AddProduct1["phone"]) ||
          AddProduct1["phone"] === ""
        ) {
          SubCategory.toastAlert("error", "please Enter a valid phone number");
          return;
        }
      }
    } else {
      AddProduct1["user_id"] = SubCategory.getAuthUserId();
      this.setState({ AddProduct: AddProduct1 });
    }
    let customVaildationInput;
    if (this.state.allCustomFields && this.state.allCustomFields.length > 0) {
      customVaildationInput = this.state.allCustomFields
        .filter((element) => {
          if (element.isValidationRequired) {
            return element;
          }
        })
        .map((data) => data.slug);
    }
    let error = 0;
    if (customVaildationInput && customVaildationInput.length > 0) {
      customVaildationInput.forEach((element) => {
        if (!this.state.AddProductCustomField.hasOwnProperty(element)) {
          error = 1;
        }
      });
    }
    console.log('delivery', this.state.delivery, "freeDeliveryOptions", this.state.freeDeliveryOptions)
    if (this.state.delivery == 2) {
      if (this.state.freeDeliveryOptions == null || this.state.freeDeliveryOptions == 0) {
        document.getElementById("delivery-option-error").innerHTML =
          "<span class='validation-span'>Please select delivery option.</span>";
        SubCategory.toastAlert("error", "Please fill all required fields.");
        return;
      } else if (this.state.freeDeliveryOptions == 2 && this.state.deliveryCost == 0) {
        document.getElementById("delivery-option-error").innerHTML =
          "<span class='validation-span'>Price is required.</span>";
        SubCategory.toastAlert("error", "Please fill all required fields.");
        return;
      }
    }
    if (
      (await AddProduct.checkValidation(await AddProduct.validationRules(), {
        ...this.state.AddProduct,
        delivery: this.state.delivery,
      })) === true &&
      this.state.productImageData.length >= 3 &&
      error == 0
    ) {
      this.setState({ isLoading: true });
      AddProduct.postApi(
        "APIS_ADD_NEW_ADS",
        this.state,
        async (response, status) => {
          this.setState({ isLoading: false });

          if (status == "success") {
            if (pay_process === true) {
              AddProduct.postApi(
                "APIS_STRIPE_CHECKOUT",
                {
                  amount: this.state.paymentAmount,
                  buyer_id: this.state.id,
                  quantity: 1,
                  cancel_url: `${frontUrl}/edit-product/${response.productSlug}`,
                  success_url: `${frontUrl}/edit-product/${response.productSlug}`,
                  productName: this.state.AddProduct.productName,
                  categoryId: response._id,
                  //  slug: slug
                },
                async (response, status) => {
                  if (status === "success" && response) {
                    window.location.href = `${response.url}`;
                    // console.log("responseid", response.url)
                  } else {
                    console.error(
                      "Error creating Stripe session:",
                      response.error.message
                    );

                    this.setState({ products: { isSold: false } });
                  }
                }
              )
            } else {
              AddProduct.redirectTo("my-profile/activity", true);
            }

          } else {
            AddProduct.toastAlert(
              "error",
              "Your login session has been expaired. Please login again to continue."
            );
          }
        },
        true
      );
    } else {
      // console.log("submitdata", this.state);
      if (this.state.productImageData.length < 3) {
        document.getElementById("image-error").innerHTML =
          "<span class='validation-span'>Minimum 3 images required.</span>";
        return;
      }

      if (customVaildationInput && customVaildationInput.length > 0) {
        customVaildationInput.forEach((element) => {
          let divEle = document.querySelector("[name=" + element + "]");
          if (!this.state.AddProductCustomField.hasOwnProperty(element)) {
            if (!divEle.classList.contains("validation-error")) {
              divEle.classList.add("validation-error");
              if (!divEle.nextElementSibling) {
                let divElement = document.createElement("div");
                divElement.innerHTML =
                  "<span class='validation-span'>" +
                  divEle.getAttribute("data-name") +
                  " is required<span></div>";
                divEle.parentNode.appendChild(divElement);
              }
            }
          } /* else{
            if(divEle.classList.contains("validation-error")){
              divEle.classList.remove("validation-error");
              if(divEle.nextElementSibling){
                divEle.nextElementSibling.remove();
              }
            } 
          } */
        });
      }

      SubCategory.toastAlert("error", "Please fill all required fields.");
      return;
    }
  };

  planChangeHandler = (planType) => {
    let { AddProduct, isFeatured } = this.state;
    switch (planType) {
      case "Featured":
        AddProduct["plan_id"] = this.featuredPlan.current.value;
        this.checkPlan(
          "Featured",
          this.featuredPlan.current.value,
          AddProduct.productPrice,
          isFeatured
        );
        break;
      /*  case "Urgent":
        AddProduct["plan_id"] = this.urgentPlan.current.value;
        this.checkPlan("Urgent", this.urgentPlan.current.value,  AddProduct.productPrice, true);
        break;
      case "Spotlight":
        AddProduct["plan_id"] = this.spotlightPlan.current.value;
        this.checkPlan("Spotlight", this.spotlightPlan.current.value,  AddProduct.productPrice, true);
        break; */

      default:
        break;
    }

    this.setState({ AddProduct: AddProduct });
  };

  onChangeHanlde = (event) => {
    //console.log("event",event)
    if (event.target.name == "productName") {
      if (event.target.value.length <= 90) {
        this.setState({
          remainingTitleChars: 90 - event.target.value.length
        });
      }
    }
    if (event.target.name == "productDescription") {
      if (event.target.value.length <= 1000) {
        this.setState({
          remainingDescriptionChars: 1000 - event.target.value.length
        });
      }
      if (event.target.value.length <= 50) {
        this.state.validationError =
          "Input must be at least 50 characters long";
      } else {
        this.state.validationError = "";
      }
    }
    let { AddProduct } = this.state;
    if (event.target.value) {
      if (event.target.classList.contains("validation-error")) {
        event.target.classList.remove("validation-error");
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.remove();
        }
      }
    }

    // console.log("name -->", event.target.name);
    if (event.target.name === "productPrice") {
      if (event.target.validity.valid) {
        AddProduct[event.target.name] = event.target.value;
        this.setState({ isFeatured: false, paymentbutton: true });
      }
    } else {
      AddProduct[event.target.name] = event.target.value;
    }
    switch (event.target.value) {
      case "Featured":
        if (Number(AddProduct.productPrice) > 0) {
          this.setState({ isFeatured: event.target.checked, isLoading: true });
          Plans.postApi(
            "APIS_GET_FEATURED_PLAN_BY_AD",
            { params: { productPrice: AddProduct.productPrice } },
            async (response) => {
              //  console.log("response",response)
              this.setState({ adTypeDataFeatured: response, isLoading: false });
            },
            true
          );

          AddProduct["plan_id"] = this.featuredPlan.current.value;
          this.checkPlan(
            "Featured",
            this.featuredPlan.current.value,
            AddProduct.productPrice,
            event.target.checked
          );
          if (document.getElementById("Featured").checked == false) {
            this.setState({ maxImages: 5, productImageData: [], files: [] });
          } else {
            this.setState({ maxImages: 10, productImageData: [], files: [] });
          }
        } else {
          this.setState({ isFeatured: false });
          SubCategory.toastAlert("error", "Please fill price before featured.");
        }
        break;
      default:
        break;
    }
    this.setState({ AddProduct: AddProduct });
    //console.log("completeData -->", this.state);
    // console.log("session_id",)
  };

  handleDelivery = (event) => {
    var delivery = event.target.value;

    if (delivery) {
      if (event.target.classList.contains("validation-error")) {
        event.target.classList.remove("validation-error");
        if (event.target.parentElement.parentElement.nextElementSibling) {
          event.target.parentElement.parentElement.nextElementSibling.remove();
        }
      }

      switch (delivery) {
        case "1":
          this.setState({
            showDeliveryPrice: false,
            delivery: 1,
            freeDeliveryOptions: 0,
            deliveryCost: 0,
          });
          break;
        case "2":
          this.setState({
            delivery: 2,
          });
          break;
        default:
          this.setState({
            delivery: 0,
            showDeliveryPrice: false,
            deliveryCost: 0,
            freeDeliveryOptions: 0,
          });
          break;
      }
    }
  };

  handleFreeDeliveryOptions = (event) => {
    var freeDelivery = event.target.value;
    if (freeDelivery == 1 || freeDelivery == 2) {
      document.getElementById("delivery-option-error").innerHTML = "";
    }
    // if (freeDelivery == 1) {
    //   document.getElementById("delivery-option-error").innerHTML = "";
    // }else if (this.state.freeDeliveryOptions == 2 && this.state.deliveryCost == 0) {
    //   console.log("hi")
    //   document.getElementById("delivery-option-error").innerHTML =
    //       "<span class='validation-span'>Price is required.</span>";
    //       return;
    // }
    if (freeDelivery) {
      switch (freeDelivery) {
        case "1":
          this.setState({
            freeDeliveryOptions: 1,
            showDeliveryPrice: false,
            deliveryCost: 0,
          });
          break;
        case "2":
          this.setState({
            freeDeliveryOptions: 2,
            showDeliveryPrice: true,
          });
          break;
        default:
          this.setState({
            freeDeliveryOptions: 0,
            showDeliveryPrice: false,
            deliveryCost: 0,
          });
          break;
      }
    }
  };
  onNumberInputKeyDown = (event) => {
    //if (event.key === "e") event.preventDefault();
    // console.log("e",event)
    if (event.key === "e" || event.key === "-" || event.keyCode === 189 || event.which === 189) event.preventDefault();
  };
  handleFreeDeliveryOptions1 = (event) => {
    this.setState({
      freeDeliveryOptions: event,
      showDeliveryPrice: false,
      deliveryCost: 0,
    });
  };

  handleDeliveryCost = (event) => {
    //.log('event',event)
    if (Number(event.target.value) > 0) {
      document.getElementById("delivery-option-error").innerHTML = "";
      // if (event.target.classList.contains("validation-error")) {
      //   event.target.classList.remove("validation-error");
      //   if (event.target.nextElementSibling) {
      //     event.target.nextElementSibling.remove();
      //   }
      // }
    } else {
      document.getElementById("delivery-option-error").innerHTML =
        "<span class='validation-span'>Price is required.</span>";
      //   if (!event.target.classList.contains("validation-error")) {
      //     event.target.classList.add("validation-error");
      //     if (!event.target.nextElementSibling) {
      //       let div = document.createElement("div");
      //       div.innerHTML =
      //         "<span class='validation-span'>Price is required<span></div>";
      //       event.target.parentNode.appendChild(div);
      //     }
      //   }
    }

    this.setState({
      deliveryCost: event.target.value,
    });
  };

  checkPlan = async (plan_type, plan_id, product_price, status) => {
    if (status == true) {
      SubCategory.postApi(
        "API_CURRENT_PACKAGE",
        {},
        async (response, status) => {
          if (status == "success") {
            if (response) {
              this.setState({ current_package: response });
            }

            SubCategory.postApi(
              "API_CHECK_PLAN",
              {
                type: plan_type,
                plan_id: plan_id,
                productPrice: product_price,
                form_type: "add",
              },
              async (response2) => {
                if (!response2.status) {
                  this.getSelectedPackage(
                    null,
                    this.state.packages[0].package_name,
                    0
                  );
                }
                this.setState({
                  paymentbutton: response2.status,
                  paymentAmount: response2.plan_amount,
                });
              },
              true
            );
          }
        },
        true
      );
    } else {
      this.setState({ paymentbutton: true });
    }
  };

  onChangeHanldeCustomField = (event) => {
    let newUpdateCustomField = {};
    if (event.target.value) {
      if (event.target.classList.contains("validation-error")) {
        event.target.classList.remove("validation-error");
        if (event.target.nextElementSibling) {
          event.target.nextElementSibling.remove();
        }
      }
    }

    newUpdateCustomField.field_id = event.target.dataset.id;
    newUpdateCustomField.field_name = event.target.dataset.name;
    if (event.target.dataset.type == "Dropdown") {
      var index = event.nativeEvent.target.selectedIndex;
      var field_value_text = event.target[index].text;
      newUpdateCustomField.field_value = field_value_text;
      newUpdateCustomField.field_value_id = event.target.value;
    }
    if (event.target.dataset.type == "Checkbox") {
      if (event.target.checked) {
        newUpdateCustomField.field_value = "Yes";
      }
    } else {
      newUpdateCustomField.field_value = event.target.value;
    }
    newUpdateCustomField.field_type = event.target.dataset.type;
    let { AddProductCustomField } = this.state;
    if (newUpdateCustomField.field_value) {
      AddProductCustomField[event.target.name] = newUpdateCustomField;
    } else {
      delete AddProductCustomField[event.target.name];
    }

    this.setState({ AddProductCustomField: AddProductCustomField });
    // console.log("custom field", AddProductCustomField);
  };

  handleProductImages = async (event) => {
    if (this.state.maxImages < this.state.productImageData.length + 1) {
      alert("You can only add upto " + this.state.maxImages + " images.");
    } else {
      var self = this;
      var apiEndPoint = ApiEndPoints.getEndPoint("APIS_UPLOAD_IMAGES");
      var fmData = new formData();
      var error = 0;
      var totalImg =
        this.state.productImageData.length + event.target.files.length;
      if (this.state.maxImages < totalImg) {
        alert("You can only add upto " + this.state.maxImages + " images.");
        error = 1;
      } else {
        for (let i = 0; i < event.target.files.length; i++) {
          let files = event.target.files[i];
          // console.log("files", files);
          fmData.append("ProductImages", files);
        }
      }

      if (totalImg >= 3) {
        document.getElementById("image-error").innerHTML = "";
      }

      if (error == 0) {
        self.setState({ isLoading: true });
        var config = {
          method: "post",
          url: apiEndPoint,
          data: fmData,
        };
        await axios(config)
          .then(function (response) {
            if (response.data.success == 1) {
              self.setState({
                productImageData: [
                  ...self.state.productImageData,
                  ...response.data.allValue,
                ],
              });
              self.setState({
                productImageData: [
                  ...new Set(self.state.productImageData.map((data) => data)),
                ],
              });
              self.setState({ ["files"]: self.state.productImageData });
              for (var i = 0; i < self.state.productImageData.length; i++) {
                if (i == 0) {
                  self.setState({ mainImage: self.state.productImageData[i] });
                }
              }
              self.state.imageData = response.data.allValue;
              self.setState({ isLoading: false });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  onDropHandler = (acceptedFiles) => {
    const { maxImages, productImageData } = this.state;

    if (maxImages < productImageData.length + 1) {
      alert("You can only add upto " + maxImages + " images.");
    } else {
      let self = this;
      let apiEndPoint = ApiEndPoints.getEndPoint("APIS_UPLOAD_IMAGES");
      let fmData = new formData();
      let error = 0;
      let totalImg = productImageData.length + acceptedFiles.length;

      if (totalImg >= 3) {
        document.getElementById("image-error").innerHTML = "";
      }

      if (maxImages < totalImg) {
        alert("You can only add upto " + maxImages + " images.");
        error = 1;
      } else {
        for (let i = 0; i < acceptedFiles.length; i++) {
          let files = acceptedFiles[i];
          fmData.append("ProductImages", files);
        }
      }

      if (error == 0) {
        self.setState({ isLoading: true });
        let config = {
          method: "post",
          url: apiEndPoint,
          data: fmData,
        };

        axios(config)
          .then(function (response) {
            if (response.data.success == 1) {
              self.setState({
                productImageData: [
                  ...self.state.productImageData,
                  ...response.data.allValue,
                ],
              });
              self.setState({
                productImageData: [
                  ...new Set(self.state.productImageData.map((data) => data)),
                ],
              });
              self.setState({ ["files"]: self.state.productImageData });
              for (var i = 0; i < self.state.productImageData.length; i++) {
                if (i == 0) {
                  self.setState({
                    mainImage: self.state.productImageData[i],
                  });
                }
              }
              self.state.imageData = response.data.allValue;
              self.setState({ isLoading: false });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  };

  myDetailHandler = (e) => {
    let { AddProduct } = this.state;
    AddProduct[e.target.name] = e.target.checked;
    this.setState({ AddProduct: AddProduct });
  };

  deleteHandler = (index) => {
    console.log("index", index);
    const { files, productImageData } = this.state;
    console.log("files--before", files, productImageData)
    files.splice(index, 1);
    productImageData.splice(index, 1);
    if (files && files.length > 0) {
      let { AddProduct } = this.state;
      var fileName = files[0];
      AddProduct["productMainImage"] = fileName;
      this.setState({ AddProduct: AddProduct });
      this.setState({ mainImage: fileName });
    }
    console.log("files", files, productImageData)
    this.setState({ files, productImageData });
  };

  checkValidations = async () => {
    const { AddProduct: AddProduct1 } = this.state;
    if (!SubCategory.isLogin()) {
      if (
        AddProduct1["emailCheck"] === false &&
        AddProduct1["phoneCheck"] === false
      ) {
        SubCategory.toastAlert("error", "please Enter a valid contact details");
        return;
      }

      if (AddProduct1["emailCheck"] === true) {
        if (
          !validator.isEmail(AddProduct1["email"]) ||
          AddProduct1["email"] === ""
        ) {
          SubCategory.toastAlert("error", "please Enter a valid email address");
          return;
        }
      }
      if (AddProduct1["phoneCheck"] === true) {
        if (
          !validator.isMobilePhone(AddProduct1["phone"]) ||
          AddProduct1["phone"] === ""
        ) {
          SubCategory.toastAlert("error", "please Enter a valid phone number");
          return;
        }
      }
    } else {
      AddProduct1["user_id"] = SubCategory.getAuthUserId();
      this.setState({ AddProduct: AddProduct1 });
    }

    if (
      (await AddProduct.checkValidation(await AddProduct.validationRules(), {
        ...this.state.AddProduct,
        delivery: this.state.delivery,
      })) === true
    ) {
      this.setState({ checkOutPopup: false });
    } else {
      SubCategory.toastAlert("error", "Please fill all required fields.");
      return;
    }
  };

  afterPaymentCheckPackage = () => {
    let { AddProduct } = this.state;
    let product_price = AddProduct.productPrice;
    let plan_id = this.featuredPlan.current.value;
    let plan_type = "Featured";
    SubCategory.postApi(
      "API_CHECK_PLAN",
      {
        type: plan_type,
        plan_id: plan_id,
        productPrice: product_price,
        form_type: "add",
      },
      async (response2) => {
        if (!response2.status) {
          this.getSelectedPackage(null, this.state.packages[0].package_name, 0);
        }
        this.setState({
          paymentbutton: response2.status,
          paymentAmount: response2.plan_amount,
        });
      },
      true
    );
  };

  render() {
    var context = this;
    const {
      imageData,
      files,
      allCustomFields,
      adTypeDataSpotlight,
      adTypeDataFeatured,
      adTypeDataUrgent,
      packages,
      package_details,
      pack,
      remainingTitleChars,
      remainingDescriptionChars,
      current_package,
      isLoading,
      maxImages,
      delivery,
      showDeliveryPrice,
    } = this.state;

    const config = {
      reference: new Date().getTime().toString(),
      email: "kritika@yopmail.com",
      amount: this.state.paymentAmount * 100,
      publicKey: process.env.REACT_APP_PAYSTACK_CLIENT_KEY,
      callback: function (response) {
        var reference = response.reference;
        alert("Payment complete! Reference: " + reference);
      },
    };

    const onSuccess = (ref) => {
      let paymentIntent = window.btoa(JSON.stringify(ref));
      this.setState({ checkOutPopup: false });

      AddProduct.postApi(
        "API_UPDATE_TRANSACTION_STATUS",
        {
          plan_id: this.state.AddProduct.plan_id,
          intent_id: ref.id,
          payment_intent: paymentIntent,
          status: "active",
        },
        async (response, status) => {
          if (status == "success") {
            //this.setState({ current_package: response });
            this.submithandlerFunction();
          }
        },
        true
      );
    };

    const onClose = () => {
      this.setState({ isLoading: false });
      this.setState({ checkOutPopup: false });
    };

    // let productData = JSON.parse(localStorage.getItem("productData"));
    // let deliveryCostPrice = localStorage.getItem("deliveryCost");
    // let freeDelivery = localStorage.getItem("freeDeliveryOptions");
    // let deliveryNo = localStorage.getItem("delivery");
    // let allFields = localStorage.getItem("AddProductCustomField");
    // let allPhotos = localStorage.getItem("productImageData");

    // console.log("productData", allPhotos);

    const saveDataHandler = () => {
      let slug = this.state.singleSubCategoryData.slug;

      // localStorage.setItem("delivery", this.state.delivery);
      // localStorage.setItem("deliveryCost", this.state.deliveryCost);
      // localStorage.setItem("productImageData", this.state.productImageData);
      // localStorage.setItem(
      //   "freeDeliveryOptions",
      //   this.state.freeDeliveryOptions
      // );
      // localStorage.setItem(
      //   "AddProductCustomField",
      //   JSON.stringify(this.state.AddProductCustomField)
      // );
      // localStorage.setItem(
      //   "productData",
      //   JSON.stringify(this.state.AddProduct)
      // );
      this.setState({ isFeatured: false });
      AddProduct.postApi(
        "APIS_ADD_NEW_ADS",

        this.state,

        async (response, status) => {
          // console.log("resp", status, response);

          if (status == "success") {
            const { postageDetail, delivery } = this.state;
            let frontUrl = process.env.REACT_APP_FRONTEND_URL;
            let pay_process = false;

            if (delivery === 2) {
              if (
                (await CheckoutModel.checkValidation(
                  await CheckoutModel.validationRules(),
                  postageDetail
                )) === true
              ) {
                pay_process = true;
                // this.setState({orderPaymentModel : true});
              }
            } else {
              pay_process = true;
              // this.setState({orderPaymentModel : true});
            }
            if (pay_process === true) {
              AddProduct.postApi(
                "APIS_STRIPE_CHECKOUT",
                {
                  amount: this.state.paymentAmount,
                  buyer_id: this.state.id,
                  quantity: 1,
                  cancel_url: `${frontUrl}/edit-product/${response.productSlug}`,
                  success_url: `${frontUrl}/my-profile/activity`,
                  productName: this.state.AddProduct.productName,
                  categoryId: response._id,
                  //  slug: slug
                },
                async (response, status) => {
                  if (status === "success" && response) {
                    window.location.href = `${response.url}`;
                    // console.log("responseid", response.url)
                  } else {
                    console.error(
                      "Error creating Stripe session:",
                      response.error.message
                    );

                    this.setState({ products: { isSold: false } });
                  }
                }
              );
            } else {
              return;
            }
          } else {
          }
          // console.log("response", response);
        }
      );
    };
    // console.log("product", AddProduct);

    const PaystackHookExample = () => {
      let pay_process = true;
      if (!this.state.paymentbutton) {
        return (
          <div>
            <button
              type="button"
              className="payment-btn"
              onClick={() => {
                this.checkValidations();
                this.submithandlerFunction(pay_process);
              }}
            >
              Made Payment
            </button>
          </div>
        );
      } else {
        return (
          <button type="submit" className="btnx btn-secondaryx right_img list_btn">
            <span>
              List Item <i className="fas fa-arrow-right"></i>
            </span>
          </button>
        );
      }
    };

    const onLoading = (value) => {
      this.setState({ isLoading: value });
    };

    // const stripeCheckout = async () => {
    //   let slug = this.state.singleSubCategoryData.slug

    //   console.log("stripe check video id",this.state.video_stream_id)
    //   console.log("stripe check pack id",this.state.pack)
    //   let frontUrl =  process.env.REACT_APP_FRONTEND_URL;
    //   let buyer_id = Order.getAuthUserId();
    //   let total_amount = this.state.sellerType === 2 ? ((this.state.businessType === 1) ? Number(this.state.pack.business_amount) : Number(this.state.pack.soletrader_amount)) : Number(this.state.pack.private_amount);
    //   total_amount += Number(this.state.video_stream_amount);

    //   AddProduct.postApi(
    //       "APIS_STRIPE_CHECKOUT",
    //       {
    //         amount: this.state.paymentAmount,
    //         buyer_id: this.state.id,
    //         quantity: 1,
    //         transaction_type:this.state.transaction_type_other,
    //         quantity: 1,
    //         cancel_url: `${frontUrl}/post-an-ad/${slug}`,
    //         success_url: `${frontUrl}/post-an-ad/${slug}`,
    //         productName:this.state.packages[0].package_name,
    //         pack:this.state.pack,
    //         video_stream_id:this.state.video_stream_id,
    //         slug: slug
    //       },
    //       async (response, status) => {
    //         if (status == "success" && response) {
    //           console.log("response",response);
    //           window.location.href = `${response.url}`
    //         } else {
    //           console.log("error")
    //         }

    //       }
    //     );
    //   console.log("addproduct",AddProduct)

    // }
    const onSuccess2 = (ref) => {
      let paymentIntent = window.btoa(JSON.stringify(ref));
      AddProduct.postApi(
        "API_UPDATE_TRANSACTION",
        {
          package_id: this.state.pack._id,
          video_stream_id:
            this.state.video_stream_id && this.state.video_stream_id != ""
              ? this.state.video_stream_id
              : null,
          intent_id: ref.id,
          payment_intent: this.state.session_id,
          status: "active",
        },
        async (response, status) => {
          if (status == "success") {
            this.setState({ current_package: response });
            //this.setState({ paymentbutton: true });
            this.setState({ purchaseSubPopup: false });
            this.afterPaymentCheckPackage();
            this.setState({ isLoading: false });
          }
        },
        true
      );
    };

    const onClose2 = () => {
      this.setState({ isLoading: false });
      this.setState({ purchaseSubPopup: false });
    };

    // const PaystackHookExample2 = () => {
    //   return (
    //     <div>
    //       <button
    //         type="button"
    //         className="payment-btn"
    //         onClick={() => {
    //           // this.setState({purchaseSubPopup:true});
    //         stripeCheckout()
    //           }}
    //       >
    //         Purchase Subscription
    //       </button>
    //     </div>
    //   );
    // };

    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper" id="loader_imgs">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="product-header bg-light-dark">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="breadcrumb-bx">
                  <div className="breadcrumb-bx-inner">
                    <Link to="/post-an-ad" className="btnx btn-primaryx">
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </Link>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        List an Item
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light-dark">
          <form onSubmit={this.submitHandler} method="post" id="add-ads-form">
            <div className="container">
              <h2 className="same-bx-heading text-center">List an Item</h2>
              <div className="white_box">
                <div className="row align-items-bottom">
                  <div className="col">
                    <h4>
                      <span>
                        <img src="/images/click_icon.png" />
                      </span>
                      Category
                    </h4>

                  </div>
                  <div className="col edit">
                    <div className="w-100 text-right">
                      <Link
                        to="/post-an-ad"
                        className="number-reveal-btn right_img"
                      >
                        <span className="fon-size-12">
                          Edit <img src="/images/edit-icon.png" alt="images" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="category_ul">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      {this.state.categoryName}
                    </li>
                    {this.state.parentCatName && (
                      <li className="breadcrumb-item">
                        {this.state.parentCatName}
                      </li>
                    )}
                    <li className="breadcrumb-item">
                      {this.state.subCategoryName}
                    </li>
                  </ol>
                </div>
              </div>
              <div className="white_box">
                <h4>
                  Listing Title<sup>*</sup>
                  <p className="text-muted">Maximum 90 characters</p>
                </h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="text"
                        // value={this.state.productName || (productData && this.setState.productData?.productName)}
                        value={this.state.productName}
                        onChange={this.onChangeHanlde}
                        name="productName"
                        className="form-control-line New_control font-size-16"
                        placeholder="Title"
                        maxLength="90"
                      />
                      <p>{remainingTitleChars} characters remaining</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="white_box">
                <h4>
                  Delivery<sup>*</sup>
                </h4>
                <p>
                  Select an option to <label>reach your customers</label>
                </p>
                <div className="row">
                  <div className="col-sm-8">
                    <div className="ad_box">
                      <div className="form-group check-box big">
                        <ul>
                          <li>
                            <input
                              type="radio"
                              name="delivery"
                              value={1}
                              id="meetBuyer"
                              className="form-control-line New_control"
                              onChange={this.handleDelivery}
                            />
                            <label htmlFor="meetBuyer">
                              Allow Buy Now - local collection or arrange to meet buyer{" "}
                            </label>
                          </li>
                          <li>
                            <input
                              type="radio"
                              name="delivery"
                              value={2}
                              id="freeDelivery"
                              className="form-control-line New_control"
                              onChange={this.handleDelivery}
                            />
                            <label htmlFor="freeDelivery">
                              Allow Buy Now - Ship items and reach buyers nationwide{" "}
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {delivery === 2 && (
                      <ul>
                        <li>
                          <div className="check-box big">
                            <input
                              type="radio"
                              name="freeDeliveryOptions"
                              id="offerFreeDelivery"
                              value={1}
                              onChange={this.handleFreeDeliveryOptions}
                            />
                            <label htmlFor="offerFreeDelivery">
                              I/We offer free shipping
                            </label>
                          </div>
                        </li>
                        <li>
                          <div className="check-box big">
                            <input
                              type="radio"
                              name="freeDeliveryOptions"
                              value={2}
                              id="willCharge"
                              onChange={this.handleFreeDeliveryOptions}
                            />
                            <label htmlFor="willCharge">I/We will charge</label>
                          </div>
                          {showDeliveryPrice === true && (
                            <input
                              type="number"
                              value={this.state.deliveryCost}
                              onChange={this.handleDeliveryCost}
                              onKeyDown={this.onNumberInputKeyDown}
                              name="deliveryCost"
                              min="0"
                              pattern="[0-9]+([.,][0-9]+)?"
                              inputMode="numeric"
                              className="form-control-line New_control"
                              placeholder="Price"
                              step="any"
                            />
                          )}
                        </li>
                        <div id="delivery-option-error"></div>
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <div className="white_box">
                <h4>
                  Price<sup>*</sup>
                </h4>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="number"
                        defaultValue={this.state.productPrice}
                        onChange={this.onChangeHanlde}
                        onKeyDown={this.onNumberInputKeyDown}
                        name="productPrice"
                        min="0"
                        className="form-control-line New_control"
                        placeholder="Price"
                        pattern="[0-9]+([.,][0-9]+)?"
                        inputMode="numeric"
                        title="Please enter only numbers" // Message to display if pattern mismatch
                        step="any"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="white_box formob_textcenterr">
                <div className="row">
                  <div className="col-lg-6">
                    <h4>
                      Description<sup>*</sup>
                      <p className="text-muted">Maximum 1000 characters</p>
                    </h4>
                  </div>
                  <div className="col-lg-6 text-right"></div>
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    defaultValue={this.state.productDescription}
                    // value={this.state.inputValue}
                    onChange={this.onChangeHanlde}
                    // onValidate={this.onValidateHandle}
                    name="productDescription"
                    className="form-control-line New_control font-size-16"
                    placeholder="Enter as much information possible"
                    maxLength="1000"
                  />
                  <p>{remainingDescriptionChars} characters remaining</p>
                  {this.state.validationError && (
                    <div style={{ color: "red" }}>
                      {this.state.validationError}
                    </div>
                  )}{" "}
                </div>
              </div>
              <div className="white_box special_makead_stand">
                <h4 className="pb-0">Make your Listing standout</h4>
                <div className="row">
                  <div className="col-sm-8">
                    <div className="ad_box">
                      <ul>
                        <li>
                          <div className="check-box big">
                            <input
                              type="checkbox"
                              name="adType"
                              value="Featured"
                              id="Featured"
                              defaultChecked={this.state.isFeatured}
                              onChange={this.onChangeHanlde}
                            />
                            <label htmlFor="Featured">
                              <span className="badge badge-primary">
                                Featured
                              </span>{" "}
                              Have your Item appear at the top of the category
                              listing for 7 days, 14 days or when the item is
                              sold{" "}
                            </label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="drdon_right">
                      <div className="form-group select_box">
                        {adTypeDataFeatured &&
                          adTypeDataFeatured.length > 0 && (
                            <select
                              className="form-control-line"
                              ref={this.featuredPlan}
                              onChange={() =>
                                this.planChangeHandler("Featured")
                              }
                            >
                              {adTypeDataFeatured.map(function (rowfield) {
                                return (
                                  <option
                                    className="item"
                                    value={rowfield._id}
                                    key={rowfield._id}
                                  >
                                    {rowfield.duration != 0 &&
                                      rowfield.duration}{" "}
                                    {rowfield.label} -{" "}
                                    {rowfield?.user_package
                                      ? `${rowfield.user_package?.number_of_ad} Paid`
                                      : General.showPriceDecimal(
                                        rowfield.amount
                                      )}
                                  </option>
                                );
                              })}
                            </select>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="white_box Upload_box mb-0">
                <div className=" row">
                  <div className="col-lg-6">
                    <h4 className="p-0">
                      Photos<sup>*</sup>
                    </h4>
                    <p>You can add up to {maxImages} images</p>
                  </div>
                  <div className="col-lg-6">
                    <div className="file_upload_btn text-right">
                      <input
                        id="fileupload"
                        className="fuilddesh"
                        name="img"
                        placeholder="Product Images"
                        type="file"
                        multiple
                        accept="image/*"
                        onChange={this.handleProductImages}
                      />

                      <label
                        htmlFor="fileupload"
                        className="number-reveal-btn right_img"
                      >
                        <span>
                          Upload Photos
                          <img src="/images/edit-icon.png" alt="images" />
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <Dropzone
                  /* accept={["image/jpeg", "image/jpg", "image/png"]}  */
                  onDrop={this.onDropHandler}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div
                        className="drop_file text-center"
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <h3>Drop Your Files Here</h3>
                        <div className="upload_manyclear text-center">
                          <p>
                            Upload clear images as possible
                          </p>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                <div id="image-error" className="text-center"></div>
              </div>
              <div className="uplodadGallery">
                {files &&
                  files.map((data, index) => {
                    return (
                      <div className="uploadGalleryBox" key={index}>
                        <div className="upload-img-wall position-relative">
                          <img
                            src={General.showImage(
                              "products/resize-180-180",
                              data
                            )}
                            alt="uploaded image"
                          />
                          <span className="delete_btn" onClick={() => this.deleteHandler(index)}>
                            <i className="fa fa-trash"></i>
                          </span>
                        </div>
                        <div
                          className="text-center py-2 text"
                          style={{ cursor: "pointer" }}
                        >
                          {context.state.mainImage == data ? (
                            <a data-file={data}>Default Image</a>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                onClick={context.setimageAsMainImage}
                                data-file={data}
                              />
                              <span
                                style={{
                                  color: "#fff",
                                  fontWeight: 600,
                                  fontSize: "14px",
                                }}
                              >
                                Mark as default
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {/* <div>
                {!this.state.paymentbutton && (
                  <div className="white_box text-center">
                    <div className="check-box big">
                      <input
                        id="planCheck"
                        type="checkbox"
                        name="purchasePackage"
                        onChange={this.showPackage}
                        value={this.state.showPackage}
                        checked={this.state.showPackage}
                      />
                      <label htmlFor="planCheck" className="mb-0">
                        It seems plans not added in your package Wanna add the
                        package?
                      </label>
                    </div>
                  </div>
                )}
                {this.state.showPackage && !this.state.paymentbutton && (
                  <div className="white_box text-center">
                    <div className="product-discmydetails">
                      <div className="Jcompny_actin">
                        <ul className="nav nav-tabs fororange_colorbg new_ctab_inntab03 specialshap_likedetailP_innerT cuttan_newimg border-0 p-0 justify-content-center mb-4">
                          {packages && packages.length > 0 ? (
                            packages.map((packs, index) => (
                              <li className="nav-item" key={index}>
                                <a
                                  className={`number-reveal-btn nav-link ${
                                    index == 0 ? "active" : ""
                                  }`}
                                  data-toggle="tab"
                                  href={"#subtabpackage" + index}
                                  onClick={(e) => {
                                    this.getSelectedPackage(
                                      e,
                                      packs.package_name,
                                      0
                                    );
                                  }}
                                >
                                  <img src="/images/pre-1.png" alt="icon" />{" "}
                                  <span>{packs.package_name}</span>
                                </a>
                              </li>
                            ))
                          ) : (
                            <div className="alert alert-danger col-lg-12">
                              <strong>Info!</strong> Nothing here.
                            </div>
                          )}
                        </ul>
                      </div>

                      {this.state.pack && (
                        <div className="tab-content subtab_tabcont03 border-top pt-4">
                          <div className="tab-pane allcom_rodtab active">
                            <div className="subtab_prodlist_NEW_forpadd pt-0">
                              <div className="container">
                                <div className="row">
                                  <div className="col-lg-3">
                                    <div className="subcateleft_imgsec">
                                      <img
                                        src="/images/account-settings-limg.png"
                                        alt="icon"
                                      />
                                    </div>
                                  </div>

                                  <div className="col-lg-9">
                                    <div className="formnotification_inner withbg_white border-radius border-bottom_1">
                                      <div className="noti_enable repeatSec_innerr border-0">
                                        <div className="box_perentrepet">
                                          <div className="buttonon_off">
                                            <div className="btnoff_onlable">
                                              Price
                                            </div>
                                            <div className="btnoff_onlable">
                                              {this.state.businessType === 1
                                                ? General.showPriceDecimal(
                                                    pack.business_amount
                                                  )
                                                : this.state.businessType === 2
                                                ? General.showPriceDecimal(
                                                    pack.soletrader_amount
                                                  )
                                                : General.showPriceDecimal(
                                                    pack.private_amount
                                                  )}
                                            </div>
                                            <div className="btnoff_onlableRight"></div>
                                          </div>
                                        </div>

                                        <div className="box_perentrepet">
                                          <div className="buttonon_off">
                                            <div className="btnoff_onlable">
                                              Ad's Featured
                                            </div>
                                            <div className="btnoff_onlable">
                                              {pack.ad_featured}
                                            </div>
                                            {this.state.already_feature &&
                                              this.state.already_feature !=
                                                "" && (
                                                <div className="btnoff_onlable">
                                                  <div className="already_paid">
                                                    {`${this.state.already_feature.number_of_ad} - Paid`}
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </div>

                                        <div className="box_perentrepet">
                                          <div className="buttonon_off">
                                            <div className="btnoff_onlable">
                                              Video streaming
                                            </div>

                                            <div className="btnoff_onlable">
                                              <div className="form-group select_box">
                                                <select
                                                  className="form-control-line"
                                                  name="video_stream"
                                                  onChange={(e) => {
                                                    let index =
                                                      e.target.selectedIndex;
                                                    let optionElement =
                                                      e.target.childNodes[
                                                        index
                                                      ];
                                                    let price =
                                                      optionElement.getAttribute(
                                                        "price"
                                                      );
                                                    this.setState({
                                                      video_stream_amount:
                                                        price,
                                                      video_stream_id:
                                                        e.target.value,
                                                    });
                                                  }}
                                                >
                                                  <option
                                                    className="item"
                                                    value=""
                                                  >
                                                    See full list
                                                  </option>
                                                  {pack.video_stream &&
                                                    pack.video_stream.length >
                                                      0 &&
                                                    pack.video_stream.map(
                                                      (stream) => (
                                                        <option
                                                          className="item"
                                                          value={stream._id}
                                                          key={stream._id}
                                                          price={stream.price}
                                                        >{`${stream.slot} ${
                                                          stream.label
                                                        } - ${General.showPriceDecimal(
                                                          stream.price
                                                        )}`}</option>
                                                      )
                                                    )}
                                                </select>
                                              </div>
                                            </div>
                                            {current_package &&
                                              current_package?.number_of_video_stream && (
                                                <div className="btnoff_onlable">
                                                  <div className="already_paid">
                                                    {`${current_package.number_of_video_stream} Slot - Paid`}
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                        </div>
                                      </div>

                                      <div className="select_btns">
                                        <div className="row">
                                          <div className="col-md-12">
                                            <div className="form-group">
                                              <div className="selectStatus_btns">
                                                {package_details &&
                                                package_details.length > 0 ? (
                                                  package_details.map(
                                                    (term, index) => (
                                                      <div
                                                        className="selectStatus_box"
                                                        key={term._id}
                                                      >
                                                        <input
                                                          type="radio"
                                                          id={
                                                            `statusUpcoming_` +
                                                            index
                                                          }
                                                          name={`radio-${index}`}
                                                          onChange={(e) =>
                                                            this.setState(
                                                              {
                                                                pack: this.state
                                                                  .package_details[
                                                                  index
                                                                ],
                                                                current_index:
                                                                  index,
                                                                term_duration:
                                                                  this.state
                                                                    .package_details[
                                                                    index
                                                                  ].duration,
                                                              },
                                                              () =>
                                                                this.getPaidFeatureLableData()
                                                            )
                                                          }
                                                          checked={
                                                            index ==
                                                            this.state
                                                              .current_index
                                                          }
                                                        />
                                                        <label
                                                          htmlFor={
                                                            `statusUpcoming_` +
                                                            index
                                                          }
                                                        >
                                                          {term.duration} days
                                                        </label>
                                                      </div>
                                                    )
                                                  )
                                                ) : (
                                                  <div className="alert alert-danger col-lg-12">
                                                    <strong>Info!</strong>{" "}
                                                    Nothing here.
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <PaystackHookExample2 />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div> */}

              {allCustomFields && allCustomFields.length > 0 && (
                <div className="white_box test">
                  <h4>Details</h4>
                  <div className="row select_box arrow_remove">
                    {allCustomFields.map(function (row) {
                      return (
                        <React.Fragment key={row._id}>
                          {row.fieldType == "Input" ? (
                            <div className="col-lg-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name={row.slug}
                                  data-id={row._id}
                                  data-name={row.fieldName}
                                  data-type={row.fieldType}
                                  className="form-control-line"
                                  onChange={context.onChangeHanldeCustomField}
                                  placeholder={row.fieldName}
                                  data-vaildation={row.isValidationRequired}
                                />
                              </div>
                            </div>
                          ) : row.fieldType == "Checkbox" ? (
                            <div className="custom_cheak mt-3 mb-3">
                              <ul>
                                <li>
                                  <div className="check-box big">
                                    <input
                                      type="checkbox"
                                      name={row.slug}
                                      id={row.slug}
                                      data-id={row._id}
                                      data-name={row.fieldName}
                                      data-type={row.fieldType}
                                      className="form-control-line"
                                      onChange={
                                        context.onChangeHanldeCustomField
                                      }
                                      placeholder={row.fieldName}
                                      value="Yes"
                                      data-vaildation={row.isValidationRequired}
                                    />
                                    <label htmlFor={row.slug}>
                                      {row.fieldName}
                                    </label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          ) : row.fieldType == "Textarea" ? (
                            <div className="">
                              <div className="row">
                                <div className="col-lg-6">
                                  <h4>{row.fieldName}</h4>
                                </div>
                              </div>
                              <div className="form-group">
                                <textarea
                                  name={row.slug}
                                  data-id={row._id}
                                  data-name={row.fieldName}
                                  data-type={row.fieldType}
                                  className="form-control-line"
                                  onChange={context.onChangeHanldeCustomField}
                                  placeholder={row.fieldName}
                                  data-vaildation={row.isValidationRequired}
                                />
                              </div>
                            </div>
                          ) : row.fieldType == "Dropdown" ? (
                            row.slug == "categorytype" ? (
                              row.fieldValues.length < 10 && (
                                <div className="col-lg-4">
                                  <div className="form-group select_box">
                                    <select
                                      className="form-control-line"
                                      name={row.slug}
                                      data-id={row._id}
                                      data-name={row.fieldName}
                                      data-type={row.fieldType}
                                      onChange={
                                        context.onChangeHanldeCustomField
                                      }
                                      data-vaildation={row.isValidationRequired}
                                    >
                                      <option value="">
                                        Select {row.fieldName}
                                      </option>
                                      {row.fieldValues.sort((a, b) => a.name.localeCompare(b.name)).map(function (rowfield) {
                                        return (
                                          <option
                                            className="item"
                                            value={rowfield._id}
                                            key={rowfield._id}
                                          >
                                            {rowfield.name}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                              )
                            ) : (
                              <div className="col-lg-4">
                                <div className="form-group select_box">
                                  <select
                                    className="form-control-line"
                                    name={row.slug}
                                    data-id={row._id}
                                    data-name={row.fieldName}
                                    data-type={row.fieldType}
                                    onChange={context.onChangeHanldeCustomField}
                                    data-vaildation={row.isValidationRequired}
                                  >
                                    <option value="">
                                      Select {row.fieldName}
                                    </option>
                                    {row.fieldValues.sort((a, b) => a.name.localeCompare(b.name)).map(function (rowfield) {
                                      return (
                                        <option
                                          className="item"
                                          value={rowfield._id}
                                          key={rowfield._id}
                                        >
                                          {rowfield.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                            )
                          ) : row.fieldType == "Radio" ? (
                            <div className="row radioMain">
                              <p className="pt-3">{row.fieldName}</p>
                              <div className=" mt-3">
                                <ul className="radioList">
                                  {row.fieldValues.map(function (rowfield) {
                                    return (
                                      <li key={row._id}>
                                        <div className="">
                                          <input
                                            type="radio"
                                            name={row.slug}
                                            value={rowfield.name}
                                            data-id={row._id}
                                            data-name={row.fieldName}
                                            data-type={row.fieldType}
                                            className="form-control-line"
                                            onChange={
                                              context.onChangeHanldeCustomField
                                            }
                                            id="Company"
                                            data-vaildation={
                                              row.isValidationRequired
                                            }
                                          />
                                          <label htmlFor="Company">
                                            {rowfield.name}
                                          </label>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </div>
                            </div>
                          ) : (
                            <> </>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              )}

              {!SubCategory.isLogin() && (
                <>
                  <div className="white_box adpost_ycont_details">
                    <h4 className="pb-0">
                      Your contact details<sup>*</sup>
                    </h4>
                    <p className="mt-1">
                      Please select at least one option to be contected by:
                    </p>
                    <div className="row">
                      <div className="col-lg-2 col-md-2">
                        <div className="row">
                          <div className="col-md-12 fuladd_postlist">
                            <div className="custom_cheak mt-3">
                              <ul>
                                <li>
                                  <div className="check-box big">
                                    <input
                                      type="checkbox"
                                      name="emailCheck"
                                      value="true"
                                      onChange={this.myDetailHandler}
                                      id="Free02"
                                    />
                                    <label htmlFor="Free02">Email:</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-md-12 fuladd_postlist">
                            <div className="custom_cheak mt-3">
                              <ul>
                                <li>
                                  <div className="check-box big">
                                    <input
                                      type="checkbox"
                                      name="phoneCheck"
                                      value="true"
                                      onChange={this.myDetailHandler}
                                      id="Exchange03"
                                    />
                                    <label htmlFor="Exchange03">Phone:</label>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4 col-md-4">
                        <div className="form-group">
                          <input
                            type="text"
                            name="email"
                            onChange={this.onChangeHanlde}
                            placeholder="Email"
                            defaultValue={AddProduct["email"]}
                            className="form-control-line"
                          />
                        </div>

                        <div className="form-group icon_input">
                          <input
                            type="text"
                            name="phone"
                            onChange={this.onChangeHanlde}
                            defaultValue={AddProduct["phone"]}
                            placeholder="Phone Number"
                            className="form-control-line"
                          />
                        </div>
                      </div>
                    </div>
                    <p>
                      Your contact name shown on this ad is{" "}
                      <strong>Jyde.</strong>
                    </p>
                    <p>
                      (You can change your name and email in{" "}
                      <a href="#" className="specialc_link">
                        My details
                      </a>
                      )
                    </p>
                  </div>
                </>
              )}

              <div className="white_box lastbottom_byselect">
                <div className="row">
                  <div className="col-lg-10 col-md-9">
                    <AdsAddFormTerm />
                  </div>
                  <div className="col-lg-2 col-md-3">
                    <div className="w-100 text-center post_an postanpage_bottom_advert">
                      <PaystackHookExample />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <PaymentPopUp
          parentState={this.state}
          onClose={onClose}
          onSuccess={onSuccess}
          onLoading={onLoading}
        />
        <PurchaseSubscriptionPopUp
          parentState={this.state}
          onClose={onClose2}
          onSuccess={onSuccess2}
          onLoading={onLoading}
        />
      </React.Fragment>
    );
  }
}

export default PostAdForm;
