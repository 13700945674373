import Model from "Models/Model";

class MyProfile extends Model {
  dbSchema() {
    return {
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      city: "",
      state: "",
      address: "",
      username: "",
      companyName: "",
      aboutCompany: "",
      startWorkingTime: "",
      endWorkingTime: "",
      changePassword: "",
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      messaging: false,
      reviews: false,
      agriculture: false,
      sale: false,
      jobs: false,
      services: false,
      motors: false,
      property: false,
    };
  }

  validationRules() {
    return {
      firstName: [
        { lib: "Custom", func: "NotEmpty", msg: "First Name is required" },
      ],
      lastName: [
        { lib: "Custom", func: "NotEmpty", msg: "Last Name is required" },
      ],
      companyName: [
        { lib: "Custom", func: "NotEmpty", msg: "Company Name is required" },
      ],
      representitiveName: [
        { lib: "Custom", func: "NotEmpty", msg: "Representitive Name is required" },
      ],
      email: [
        { lib: "Custom", func: "NotEmpty", msg: "Email is required" },
        // {lib:"Validator", func:"isEmail", msg:"This is not a valid email address"},
      ],
      confirmEmail: [
        { lib: "Custom", func: "NotEmpty", msg: "Email is required" },
        // {lib:"Validator", func:"isEmail", msg:"This is not a valid email address"},
      ],
      currentEmail: [
        { lib: "Custom", func: "NotEmpty", msg: "Email is required" },
        // {lib:"Validator", func:"isEmail", msg:"This is not a valid email address"},
      ],
      newEmail: [
        { lib: "Custom", func: "NotEmpty", msg: "Email is required" },
        // {lib:"Validator", func:"isEmail", msg:"This is not a valid email address"},
      ],
      mobileNumber: [
        { lib: "Custom", func: "NotEmpty", msg: "Mobile Number is required" },
        {
          lib: "Validator",
          func: "isNumeric",
          msg: "Please enter valid phone number",
        },
        {
          lib: "Validator",
          func: "isLength",
          msg: "Mobile number must be 10 digit long",
          option: { min: 10, max: 10 },
        },
      ],
      currentPassword: [
        {
          lib: "Custom",
          func: "NotEmpty",
          msg: "Current password is required",
        },
      ],
      newPassword: [
        { lib: "Custom", func: "NotEmpty", msg: "New password is required" },
        {
          lib: "Validator",
          func: "isStrongPassword",
          msg: "Your password must be at least 8 characters, and contain at least one upper and lower case letter, one number and one special character.",
          option: {
            minLength: 8,
            minLowercase: 1,
            minUppercase: 1,
            minNumbers: 1,
            minSymbols: 1,
          },
        },
      ],
      confirmPassword: [
        {
          lib: "Custom",
          func: "NotEmpty",
          msg: "Confirm password is required",
        },
      ],
      businessType: [
        { lib: "Validator", func: "isInt", msg: "Business type is required", option: { gt: 0 } }
      ],
      companyName: [
        { lib: "Custom", func: "NotEmpty", msg: "Business name is required" }
      ],
      username: [
        { lib: "Custom", func: "NotEmpty", msg: "Username is required" }
      ],
      registrationNumber: [
        { lib: "Custom", func: "NotEmpty", msg: "CRN Registration number is required" }
      ],
      businessEmail: [
        { lib: "Custom", func: "NotEmpty", msg: "Business Email is required" },
        { lib: "Validator", func: "isEmail", msg: "This is not a valid email address" },
      ],
      businessPhoneNumber: [
        { lib: "Custom", func: "NotEmpty", msg: "Business Mobile Number is required" },
        {
          lib: "Validator",
          func: "isNumeric",
          msg: "Please enter valid phone number",
        },
        {
          lib: "Validator",
          func: "isLength",
          msg: "Mobile number must be 10 digit long",
          option: { min: 10, max: 10 },
        },
      ],
    };
  }
}

export default new MyProfile();
