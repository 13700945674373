import General from "Helpers/General";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Modal from "react-modal";
import MyProfile from "Models/MyProfile";

export class UpcomingLiveSchedule extends Component {
  state = {
    userId: MyProfile.getAuthUserId(),
    showModal: false,
    currentLiveSchedule: '',
    modalProducts: [],
    registerUser: [],
    preBidDetails: [],
    registerUserCount: 0,
    preBidDetailsCount: 0,
  };

  render() {
    const { liveSchedule, canEdit } = this.props;
    const { userId, showModal, modalProducts, registerUser, registerUserCount, preBidDetails, preBidDetailsCount, currentLiveSchedule } = this.state;
    console.log("modalProducts", preBidDetails, preBidDetailsCount);
    return (
      <>
        {liveSchedule.productId &&
          liveSchedule.productId.length > 0 &&
          liveSchedule.productId.map((product, index) => {
            if (index === 0) {
              return (
                <div
                  className="col-lg-12 listside_newperent"
                  key={`upcomingLive_${product._id}`}
                >
                  <div className="product-listing-product desktop_view">
                    <div className="row">
                      <div className="col-md-3">
                        <div className="product-img-bx">
                          <Link
                            to={`/livestreamingProduct/${liveSchedule._id}`}
                          >
                            {product.adType && product.adType === "Featured" && (
                              <div className="specialtags">
                                <span
                                  className={
                                    (product.adType === "Featured"
                                      ? "black"
                                      : "green") + "_special"
                                  }
                                >
                                  {product.adType}{" "}
                                </span>
                              </div>
                            )}
                            {product.productMainImage ? (
                              <img
                                src={General.showImage(
                                  "products/resize-180-180",
                                  product.productMainImage
                                )}
                                onError={
                                  (e) => e.target.src = '/images/no-image.jpg'
                                }
                                alt={product.productName}
                              />
                            ) : (
                              <img src="/images/no-image.jpg" alt="product" />
                            )}

                            <div className="totalimg_sectab">
                              {/*  <Link
                                to={`/product/${product._id}`}
                                className="totlimg_qt"
                              > */}
                              {product.productImages
                                ? product.productImages.length
                                : 0}{" "}
                              <i className="fas fa-camera"></i>
                              {/*  </Link> */}
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-9">
                        <div className="product-outer-bx productBig-outer-bx">
                          <div className="product-name-bx content_gobottom_new">
                            {/* {liveSchedule?.createdAt && (
                              <Moment fromNow>{liveSchedule?.createdAt}</Moment>
                            )} */}
                            <Link to={`/livestreamingProduct/${liveSchedule._id}`}>
                              <h4>{product.productName}</h4>
                            </Link>
                            <h5 className="durationTime">Slot duration: {liveSchedule?.duration} mins (<Moment format="h:mm a">{liveSchedule?.time}</Moment>
                              to
                              <Moment format="h:mm a">{liveSchedule?.endTime}</Moment>)</h5>
                            <h6>
                              <span>
                              </span>
                            </h6>
                            <ul className="listofsubtabbx">
                              {product.userId === userId ?
                                <>
                                  <li>
                                    {liveSchedule?.productId?.length} item and{" "}
                                    {liveSchedule?.length}{" "}
                                    minutes per item assigned
                                  </li>
                                  <li>
                                    Register User : {liveSchedule?.preBidDetailsCount}
                                  </li>
                                </> :
                                <>
                                  <li>
                                    Auction/lot duration : {liveSchedule?.length} mins
                                  </li>
                                  <li>
                                    Seller : {liveSchedule?.userId.firstName + " " + liveSchedule?.userId.lastName}
                                  </li>
                                </>
                              }
                            </ul>
                            <div className="subtab_edit subtab_edit_mobile ">
                              <div className="subtab_editlabl">
                                {(product.userId === userId && Number(new Date(liveSchedule.time).getTime()) > Number(Date.now())) &&
                                  <div className="dropdown">
                                    <div
                                      id="dropdownAdActionButton"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <i className="fas fa-ellipsis-v"></i>
                                    </div>
                                    <div
                                      className="dropdown-menu dropdown-ad-actions"
                                      aria-labelledby="dropdownAdActionButton"
                                    >
                                      {canEdit === true && (
                                        <>
                                          <Link
                                            to="#!"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            className="dropdown-item"
                                            onClick={(e) =>
                                              this.props.editHandler(
                                                e,
                                                liveSchedule._id
                                              )
                                            }
                                          >
                                            Edit
                                          </Link>
                                          <Link
                                            to="#!"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title=""
                                            className="dropdown-item"
                                            onClick={(e) =>
                                              this.props.deleteHandler(
                                                e,
                                                liveSchedule._id
                                              )
                                            }
                                          >
                                            Delete
                                          </Link>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                }
                                <div className="subtab_editlabl btn_div">
                                  <span
                                    className="labltable lablesct view_btn"
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      this.setState({
                                        currentLiveSchedule: liveSchedule?.time ?? '',
                                        modalProducts:
                                          liveSchedule?.productId ?? [],
                                        // registerUserCount:
                                        //   liveSchedule?.registerdBidderCount ?? 0,
                                        // registerUser:
                                        //   liveSchedule?.registerdBidder ?? [],
                                        preBidDetailsCount:
                                          liveSchedule?.preBidDetailsCount ?? 0,
                                        preBidDetails:
                                          liveSchedule?.preBidDetails ?? [],
                                        showModal: true,
                                      })
                                    }
                                  >
                                    <i className="far fa-eye"></i> View
                                  </span>

                                  <div className="subtab_editlabl">
                                    <span className="labltable lablesct">
                                      {liveSchedule?.isActive
                                        ? "Active"
                                        : "Inactive"}
                                    </span>
                                  </div>
                                </div>
                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>



                  <div className="product-listing-product mobile_view mobile_box position-relative background-color">
                    <div className="product_listing_product_block">
                      <div className="product-img-bx">
                        <Link
                          to={`/livestreamingProduct/${liveSchedule._id}`}
                        >
                          {product.adType && product.adType === "Featured" && (
                            <div className="specialtags">
                              <span
                                className={
                                  (product.adType === "Featured"
                                    ? "black"
                                    : "green") + "_special"
                                }
                              >
                                {product.adType}{" "}
                              </span>
                            </div>
                          )}
                          {product.productMainImage ? (
                            <img
                              src={General.showImage(
                                "products/resize-180-180",
                                product.productMainImage
                              )}
                              onError={
                                (e) => e.target.src = '/images/no-image.jpg'
                              }
                              alt={product.productName}
                            />
                          ) : (
                            <img src="/images/no-image.jpg" alt="product" />
                          )}

                          <div className="totalimg_sectab">
                            {/*  <Link
                                to={`/product/${product._id}`}
                                className="totlimg_qt"
                              > */}
                            {product.productImages
                              ? product.productImages.length
                              : 0}{" "}
                            <i className="fas fa-camera"></i>
                            {/*  </Link> */}
                          </div>
                        </Link>
                      </div>
                      <div className="product-outer-bx productBig-outer-bx">
                        <div className="product-name-bx content_gobottom_new">
                          {/* {liveSchedule?.createdAt && (
                            <Moment fromNow>{liveSchedule?.createdAt}</Moment>
                          )} */}
                          <Link to={`/livestreamingProduct/${liveSchedule._id}`}>
                            <h4>{product.productName}</h4>
                          </Link>
                          <h5 className="durationTime">Slot duration: {liveSchedule?.duration} mins (<Moment format="h:mm a">{liveSchedule?.time}</Moment>
                            to <Moment format="h:mm a">{liveSchedule?.endTime}</Moment>)</h5>
                          <h6>
                            <span>
                            </span>
                          </h6>
                          <ul className="listofsubtabbx">
                            {product.userId === userId ?
                              <>
                                <li>
                                  {liveSchedule?.productId?.length} item and{" "}
                                  {liveSchedule?.length}{" "}
                                  minutes per item assigned
                                </li>
                                <li>
                                  Register User : {liveSchedule?.preBidDetailsCount}
                                </li>
                              </> :
                              <>
                                <li>
                                  Auction/lot duration : {liveSchedule?.length} mins
                                </li>
                                <li>
                                  Seller : {liveSchedule?.userId.firstName + " " + liveSchedule?.userId.lastName}
                                </li>
                              </>
                            }
                          </ul>
                          <div className="subtab_edit subtab_edit_mobile ">
                            <div className="subtab_editlabl">
                              {(product.userId === userId && Number(new Date(liveSchedule.time).getTime()) > Number(Date.now())) &&
                                <div className="dropdown">
                                  <div
                                    id="dropdownAdActionButton"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                  >
                                    <i className="fas fa-ellipsis-v"></i>
                                  </div>
                                  <div
                                    className="dropdown-menu dropdown-ad-actions"
                                    aria-labelledby="dropdownAdActionButton"
                                  >
                                    {canEdit === true && (
                                      <>
                                        <Link
                                          to="#!"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title=""
                                          className="dropdown-item"
                                          onClick={(e) =>
                                            this.props.editHandler(
                                              e,
                                              liveSchedule._id
                                            )
                                          }
                                        >
                                          Edit
                                        </Link>
                                        <Link
                                          to="#!"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title=""
                                          className="dropdown-item"
                                          onClick={(e) =>
                                            this.props.deleteHandler(
                                              e,
                                              liveSchedule._id
                                            )
                                          }
                                        >
                                          Delete
                                        </Link>
                                      </>
                                    )}
                                  </div>
                                </div>
                              }
                              <div className="subtab_editlabl btn_div">
                                <span
                                  className="labltable lablesct view_btn"
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.setState({
                                      currentLiveSchedule: liveSchedule?.time ?? '',
                                      modalProducts:
                                        liveSchedule?.productId ?? [],
                                      // registerUserCount:
                                      //   liveSchedule?.registerdBidderCount ?? 0,
                                      // registerUser:
                                      //   liveSchedule?.registerdBidder ?? [],
                                      preBidDetailsCount:
                                        liveSchedule?.preBidDetailsCount ?? 0,
                                      preBidDetails:
                                        liveSchedule?.preBidDetails ?? [],
                                      showModal: true,
                                    })
                                  }
                                >
                                  <i className="far fa-eye"></i> View
                                </span>

                                <div className="subtab_editlabl">
                                  <span className="labltable lablesct">
                                    {liveSchedule?.isActive
                                      ? "Active"
                                      : "Inactive"}
                                  </span>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        <Modal
          isOpen={showModal}
          className="popupModal stripePaymentModal"
          ariaHideApp={false}
          onRequestClose={() =>
            this.setState({ showModal: false, modalProducts: [] })
          }
          style={{
            content: {
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "60%",
            },
          }}
        >
          <button onClick={() => this.setState({ showModal: false, modalProducts: [] })} className="shipCloseBtn">
            <i className="fa fa-times"></i>
          </button>
          <div className="scrollblePopUp upcomingModal">
            {modalProducts &&
              modalProducts.map((product) => (
                <div
                  className="col-lg-12 listside_newperent"
                  key={`upcomingLive_${product?._id}`}
                >
                  <div className="product-listing-product listing_product_modal">
                    <div className="row">
                      <div className="col-4 px-0">
                        <div className="product-img-bx">
                          <Link to={`/product/${product?._id}`}>
                            {product?.adType && product?.adType === "Featured" && (
                              <div className="specialtags">
                                <span
                                  className={
                                    (product?.adType === "Featured"
                                      ? "black"
                                      : "green") + "_special"
                                  }
                                >
                                  {product?.adType}{" "}
                                </span>
                              </div>
                            )}
                            {product?.productMainImage ? (
                              <img
                                src={General.showImage(
                                  "products/resize-180-180",
                                  product.productMainImage
                                )}
                                onError={
                                  (e) => e.target.src = '/images/no-image.jpg'
                                }
                                alt={product?.productName}
                              />
                            ) : (
                              <img src="/images/no-image.jpg" alt="product" />
                            )}
                            <div className="totalimg_sectab">
                              {product.productImages
                                ? product.productImages.length
                                : 0}{" "}
                              <i className="fas fa-camera"></i>
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-8 px-0">
                        <div className="product-outer-bx productBig-outer-bx">
                          <div className="product-name-bx content_gobottom_new">
                            {/* <Moment fromNow>{product.time}</Moment> */}
                            <Link to={`/product/${product._id}`}>
                              <h4 className="productName_track">{product?.productName}</h4>
                            </Link>
                            <h5>{product?.location}</h5>
                            <h6>
                              <span>
                                {General.showPriceDecimal(product.starting_price)}
                              </span>
                            </h6>
                            <ul className="listofsubtabbx">
                            </ul>
                            <div className="subtab_edit">
                              <div className="subtab_editlabl">
                              </div>
                              <p className="subtab_editlabl">
                                <span className="labltable lablesct">
                                  {product?.active === true
                                    ? "Active"
                                    : "Inactive"}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            <div
              className="col-lg-12 listside_newperent"
            >
              <p className="font-weight-bold">
                <span>Bidders : {preBidDetailsCount}</span>
              </p>
              <hr></hr>
              <p className="font-weight-bold">
                Auction :{" "}
                <Moment format="DD MMM YYYY">
                  {currentLiveSchedule?.time}
                </Moment>{" "}
                <Moment format="hh:mm a">
                  {currentLiveSchedule?.time}
                </Moment>{" "}
                {/* to{" "}
                <Moment format="hh:mm a">
                  {currentLiveSchedule?.endTime}
                </Moment>{" "} */}
                BST
              </p>
              <div className="table-responsive product_list_table">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Bidders</th>
                      <th>Bidders Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {preBidDetails && preBidDetails.map((bidder, index) => (
                      <tr key={`bidder_${index}`}>
                        <td>
                          <div>
                            {bidder?.userId?.profilePicture ?
                              <img
                                src={General.showImageNew(
                                  "profile",
                                  bidder?.userId
                                    ?.profilePicture
                                )}
                                alt=""
                                onError={
                                  (e) => e.target.src = '/images/user.png'
                                }
                                width="30"
                                className="mCS_img_loaded"
                              /> : <img
                                src="/images/user.png"
                                alt={
                                  bidder?.userId
                                    ?.firstName +
                                  " " +
                                  bidder?.userId
                                    ?.lastName
                                }
                                width="30"
                                className="mCS_img_loaded"
                              />
                            }
                          </div>
                          <p>
                            <small>
                              {bidder?.userId?.firstName + " " + bidder?.userId?.lastName}
                            </small>
                          </p>
                        </td>
                        <td>
                          <p>
                            <small>
                              {General.showPriceDecimal(bidder.amount)}
                              {/* You won the bid {General.showPriceDecimal(bidder.amount)} */}
                            </small>
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default UpcomingLiveSchedule;
