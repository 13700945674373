import React, { Component } from "react";
import { Link } from "react-router-dom";
import Product from "Models/Product";
import Order from "Models/Order";
import MyProfile from "Models/MyProfile";
import CheckoutModel from "Models/CheckoutModel";
import General from "Helpers/General";
import QB, { playCallAudio } from "Helpers/Quickblox";
var qs = require("qs");

class Thankyou extends Component {
  constructor(props) {
    super(props);

    if (!Product.isLogin()) {
      Product.redirectTo("");
    }
    let session_id = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).session_id;
    let session_resp = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true,
    }).response;
    console.log("session_id", session_id);

    window.scrollTo(0, 0);

    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      id: "",
      name: "",
      firstName: "",
      lastName: "",
      orderData: {},
      isLoading: true,
      session_id: session_id ? session_id : "",
      session_resp: session_resp ? session_resp : "",
    };
  }

  componentDidMount = () => {
    Product.postApi(
      "API_GET_USER_BY_EMAIL",
      { email: localStorage.getItem("username") },
      async (response) => {
        if (response) {
          this.setState({ name: response.firstName + " " + response.lastName });
          this.setState({ firstName: response.firstName });
          this.setState({ lastName: response.lastName });
          this.setState({ postageDetail: response.postageDetail });
          this.setState({ homeDetail: response.homeDetail });
          this.setState({ id: response._id });
          console.log("postageDetail", response.postageDetail, response.homeDetail)
        }
      },
      false
    );
    this.getOrderDetailById();
    this.setState({ session_id: this.state.session_id });
    this.setState({ session_resp: this.state.session_resp });
    console.log("stripe_code", this.state);

  };

  componentDidUpdate = (prevProps) => {
    if (this.props.match.params.orderId !== prevProps.match.params.orderId) {
      this.getOrderDetailById();
    }
  };

  getOrderDetailById = () => {
    const orderId = this.props.match.params.orderId;
    Product.postApi(
      "APIS_ORDER_DETAIL",
      { orderId: orderId },
      async (response, status) => {
        console.log("response", response);
        if (status === "success") {
          this.setState({ orderData: response });
          console.log("check session id avail", this.state.session_id);
          if (this.state.session_resp == "success") {
            this.setState({ isLoading: true });
            this.updateOrderDetails();
          }
        } else {
          Product.redirectTo("");
          //this.props.history.goBack();
        }
      },
      true
    );
  };
  updateOrderDetails = () => {
    let frontUrl = process.env.REACT_APP_FRONTEND_URL;
    console.log("session_id", this.state.session_id);
    const orderId = this.props.match.params.orderId;
    console.log("orderId", orderId);
    Product.postApi(
      "APIS_ORDER_UPDATE",
      {
        orderId: orderId,
        session_id: this.state.session_id,
        session_resp: this.state.session_resp,
      },
      async (response, status) => {
        console.log("response", response);
        if (status === "success") {
          const currentUrl = window.location.href;
          const newUrl = currentUrl.split("?")[0];
          window.history.replaceState({ path: newUrl }, "", newUrl);
          // window.location.href = `${frontUrl}/thankyou/${response._id}`;
          var seller_email = response.sellerData.email;
          var searchParams = { login: seller_email };
          let msg =
            "Your product " + response.productData.productName + " has been purchased.";
          console.log("msg", msg)
          QB.users.get(searchParams, function (error, user) {
            console.log("user thankyou", user)
            if (!error) {
              let params = {
                type: 3,
                occupants_ids: [user.id],
              };
              console.log("params", params)
              QB.chat.dialog.create(params, (error, dialog) => {
                if (!error) {
                  console.log("dialog", dialog)
                  let message = {
                    type: "chat",
                    body: msg,
                    extension: {
                      save_to_history: 1,
                      dialog_id: dialog._id,
                    },
                    markable: 1,
                  };
                  QB.chat.send(user.id, message);
                } else {
                  console.log("Dialog Create Error", error);
                }
              });
            }
          });
          this.setState({ isLoading: false });
        } else {
          console.log("err");
          // Product.redirectTo("");
          //this.props.history.goBack();
        }
      },
      true
    );
  };

  render() {
    const { orderData, isLoading } = this.state;

    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        {orderData && Object.keys(orderData).length > 0 && (
          <section className="repeat-section product-detail-main-bx bg-light-dark onmobilp_topnon  mobiledisplay_oneline">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-12 col-lg-8 m-auto">
                  <div className="checkout_card mb-3">
                    <div className="checkout_inner_details">
                      <div>
                        <h3 className="font-weight-bold text-success mb-3">
                          Thank you, your order has been placed
                        </h3>
                      </div>
                      <div className="user_details">
                        <div className="left_order_detail">
                          <div className="product_name">
                            <p className="font-weight-bold text-dark mb-2">
                              An email confirmation has been sent to you and
                              seller.{" "}
                              {orderData.delivery === 1 ? (
                                <span>
                                  Please contact seller to arrange your pick up
                                </span>
                              ) : (
                                <span>
                                  We'll notify you when your item has been
                                  shipped
                                </span>
                              )}
                            </p>
                            <p className="font-weight-bold text-dark">
                              Order Number{" "}
                              <span>: {orderData.order_number}</span>
                            </p>
                          </div>
                        </div>
                        <div className="right_prise">
                          <p>
                            {General.showPriceDecimal(orderData.total_amount)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="checkout_card mb-3">
                    <div className="checkout_inner_details">
                      <div className="seller_name">
                        <p>
                          Seller{" "}
                          <span>
                            :{" "}
                            {orderData?.product_id.userId &&
                              orderData?.product_id.userId.firstName}{" "}
                            {orderData?.product_id.userId &&
                              orderData?.product_id.userId.lastName}
                          </span>
                        </p>
                        .
                      </div>
                      <div className="user_details">
                        <div className="left_user_details">
                          <div className="img_user">
                            {orderData?.product_id.productMainImage ? (
                              <img
                                src={General.showImage(
                                  "products/resize-180-180",
                                  orderData.product_id.productMainImage
                                )}
                                alt=""
                              />
                            ) : (
                              <img src="/images/no-image.jpg" alt="" />
                            )}
                          </div>
                          <div className="product_name">
                            <h5>{orderData?.product_id?.productName}</h5>
                            {orderData.delivery === 1 && (
                              <div className="other_details">
                                <span>{orderData?.product_id?.location}</span>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="right_prise">
                          <p>
                            {General.showPriceDecimal(orderData.total_amount)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}

export default Thankyou;
