import React, { Component } from "react";

class AdsAddFormTerm extends Component {

  render() {
    return (
      <div className="byselectingside"><p>By selecting List item you agree you've read and accepted our <a href="#" className="list_item_link"> Terms of Use</a> and  <a href="#" className="list_item_link"> Listing Rules </a> Please see our <a href="#" className="list_item_link">Privacy Notice</a> for information regarding the processing of your data.</p></div>
    );
  }
}

export default AdsAddFormTerm;