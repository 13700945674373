import General from "Helpers/General";
import LiveStreamingProductItem from "Models/LiveStreamingProductItem";
import Master from "Models/Master";
import React, { Component } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import Modal from 'react-modal';

export class LiveReturnModalComponent extends Component {

  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
        retrunReson: "",
        otherReson: "",
        allReson: [],
    };
    this.closeReturnModal.bind(this);
  }

  componentDidMount = () => {
    Master.postApiByParams(
      "APIS_GET_SOME_RESONS",
      { type : 'return-reason' },
      async (response) => {
        if(response.status === 'success'){
          this.setState({ allReson: response.data.resonData });
        }
      },
      true
    );
  };

  closeReturnModal = () => {
    let { closeModal } = this.props;
    closeModal({returnModalIsOpen:false});
  }

  submitRetrunFormHandler = (e) => {
    e.preventDefault();
    const { retrunReson, otherReson } = this.state;

    if (retrunReson.trim() === "") {
      document.getElementById("return-reson").style.display = "block";
      return;
    }

    if (retrunReson === 'other') {
      if (otherReson.trim() === "") {
        document.getElementById("other-reson").style.display = "block";
        return;
      }
    }

    LiveStreamingProductItem.postApi(
      "APIS_SUBMIT_LIVE_AUCTION_RETURN_RESON",
      {
        id: this.props.id,
        reson_id: retrunReson,
        other_reson: otherReson,
        status:5
      },
      async (response,status) => {
        if (status === "success") {
          let { handler } = this.props;
          let buyerName = response.buyer_id.firstName+" "+response.buyer_id.lastName;
          let return_msg = buyerName+" requested for return for "+response.productId.productName;
          handler({other_reson:response?.other_reson,reson:response.reson_id,seller_email:response?.userId.email,msg:return_msg});
        }
      },
      true
    );
  };

  handleReturnReson = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };
  
  render() {
    const customStyles = {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
      },
    };

    const { returnModalIsOpen } = this.props;

    return (
      <Modal
      isOpen={returnModalIsOpen}
      style={customStyles}
      contentLabel="Example Modal"
      ariaHideApp={false}
      className="shipModal"
      onRequestClose={() =>
        this.closeReturnModal()
      }
    >
      <button onClick={() => this.closeReturnModal()} className="shipCloseBtn">
          <i className="fa fa-times"></i>
      </button>
      <form className="form-horizontal" onSubmit={this.submitRetrunFormHandler}>
        <div className="form-group row">
          <label className="control-label col-sm-12" htmlFor="expdate">Reson :</label>
          <div className="col-12">
            <select
              name="retrunReson"
              className="custom-select form-control"
              value={this.state.retrunReson}
              onChange={this.handleReturnReson}
            >
              <option value="">Select Option  </option>
              {this.state.allReson && (
                this.state.allReson.map((data)=>{
                return <option value={data._id} key={data._id}>{ data.name }</option> ;
                })
              )}
              <option value="other">Other</option>
            </select>
            <p id="return-reson" className="text-danger" style={{display:"none"}}>Return Reson is required*</p>
          </div>
        </div>
        {this.state.retrunReson === 'other' && (<>
          <div className="form-group row">
            <label className="control-label col-sm-12">Other Reson:</label>
            <div className="col-sm-12">
            <textarea
              name="otherReson"
              value={this.state.otherReson}
              onChange={this.handleReturnReson}
              className="form-control"
              rows="3"
              placeholder="other reson.."
            ></textarea>
            <p id="other-reson" className="text-danger" style={{display:"none"}}>Ohter Reson is required*</p>
            </div>
          </div>
        </>)}
        <div className="form-group">
          <div className="col-sm-12 text-center">
            <button type="submit" className="btn themeBtn">Submit</button>
          </div>
        </div>
      </form>
    </Modal>
    );
  }
}

export default LiveReturnModalComponent;
