import ApiEndPoints from "Helpers/ApiEndPoints";
import CheckValidation from "Helpers/CheckValidation";
import Storage from "Helpers/Storage";
import { toast } from "react-toastify";
import Lodash from "lodash";
import QS from "qs";
import $ from "jquery";
import jwt_decode from "jwt-decode";

class Model {
  getAlert(msg = "") {
    // alert(msg);
  }

  dbSchema(type = "") {}

  dbDictionary() {
    return [];
  }

  validationRules() {
    return {};
  }

  toastAlert(type, msg) {
    switch (type) {
      case "info":
        toast.info(msg);
        break;
      case "success":
        toast.success(msg);
        break;
      case "warning":
        toast.warn(msg);
        break;
      case "error":
        toast.error(msg);
        break;
      default:
        toast.error(msg);
    }
  }

  async checkValidation(FormRules, FormData) {
    var errors = await CheckValidation.validateFormData(FormRules, FormData);

    $(document).find(".validation-error").removeClass("validation-error");
    $(document).find(".validation-span").remove();

    if (errors !== true) {
      this.showValidationError(errors);
    }
    return errors;
  }

  showValidationError(errors) {
    Object.keys(errors).map(function (key) {
      //console.log($("[name='" + key + "']:visible"));
      $("[name='" + key + "']")
        .addClass("validation-error")
        .parents("div:first")
        .append(
          "<div><span class='validation-span'>" +
            Lodash.head(errors[key]) +
            "</span></div>"
        );
      return false;
    });
  }

  redirectTo(path = "", wait = false) {
    if (wait == true) {
      setTimeout(function () {
        window.location = `/${path}`;
      }, 100);
    } else {
      window.location = `/${path}`;
    }
  }

  // async loginHeader(){
  //   return {
  // "x-access-token" : ""
  //   };
  // }

  async postApi(apiKey, keys = {}, callback, login = true, file = false) {
    var apiEndPoint = ApiEndPoints.getEndPoint(apiKey);
    var options = {
      method: "POST",
      // mode : 'no-cors',
      // cache: 'no-cache',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(keys),
    };

    if (file) {
      options["headers"] = Lodash.omit(options["headers"], "Accept");
      options["headers"] = Lodash.omit(options["headers"], "Content-Type");
    }

    if (login) {
      options["headers"] = await this.loginHeader();
    }
    $("#loader_img").show();
    await fetch(apiEndPoint, options).then((response) => {
      $("#loader_img").hide();
      var self = this;
      response.json().then((apiData) => {
        
        if (Lodash.has(apiData, "token")) {
          self.setToken(apiData.token);
        }
        if (Lodash.has(apiData, "status")) {
          if (apiData.message) {
            self.toastAlert(apiData.status, apiData.message);
          }
        }

        if (Lodash.has(apiData, "validations")) {
          self.showValidationError(apiData.validations);
        }
        callback(apiData.data, apiData.status);
      });
    });
  }

  async getApi(apiKey, callback, keys = {}, login = true) {
    var apiEndPoint = ApiEndPoints.getEndPoint(apiKey);
    var options = {
      method: "GET",
      // mode : 'no-cors',
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    if (login) {
      options["headers"] = await this.loginHeader();
    }

    if (Lodash.hasIn(keys, "params")) {
      if (Lodash.isString(keys.params)) {
        apiEndPoint += keys.params;
      } else {
        apiEndPoint += QS.stringify(keys.params);
      }
    }
    $("#loader_img").show();
    await fetch(apiEndPoint, options).then((response) => {
      $("#loader_img").hide();
      response.json().then((apiData) => {
        callback(apiData.data);
      });
    });
  }

  async getApiByParams(apiKey, key, callback, login = true) {
    var apiEndPointAPI = ApiEndPoints.getEndPoint(apiKey);
    var apiEndPoint = apiEndPointAPI + "/" + key;
    var options = {
      method: "GET",
      cache: "no-cache",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    if (login) {
      options["headers"] = await this.loginHeader();
    }
    $("#loader_img").show();
    await fetch(apiEndPoint, options).then((response) => {
      $("#loader_img").hide();
      response.json().then((apiData) => {
        callback(apiData.data);
      });
    });
  }

  async postApiByParams(
    apiKey,
    keys = {},
    callback,
    login = true,
    file = false
  ) {
    var apiEndPoint = ApiEndPoints.getEndPoint(apiKey);
    var options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(keys),
    };
    if (file) {
      options["headers"] = Lodash.omit(options["headers"], "Accept");
      options["headers"] = Lodash.omit(options["headers"], "Content-Type");
    }
    if (login) {
      options["headers"] = await this.loginHeader();
    }
    $("#loader_img").show();
    await fetch(apiEndPoint, options).then((response) => {
      $("#loader_img").hide();
      var self = this;
      response.json().then((apiData) => {
        // if(Lodash.has(apiData, "token")){
        //   self.setToken(apiData.token);
        // }
        // if (Lodash.has(apiData, "status")) {
        //   self.toastAlert(apiData.status, apiData.message);
        // }
        // if(Lodash.has(apiData, "validations")){
        //   self.showValidationError(apiData.validations);
        // }

        callback(apiData, apiData.status);
      });
    });
  }

  async postApiLoader(apiKey, keys = {}, callback, login = true, file = false) {
    var apiEndPoint = ApiEndPoints.getEndPoint(apiKey);
    var options = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(keys),
    };

    if (file) {
      options["headers"] = Lodash.omit(options["headers"], "Accept");
      options["headers"] = Lodash.omit(options["headers"], "Content-Type");
    }

    if (login) {
      options["headers"] = await this.loginHeader();
    }

    await fetch(apiEndPoint, options).then((response) => {
      var self = this;
      response.json().then((apiData) => {
        if (Lodash.has(apiData, "token")) {
          self.setToken(apiData.token);
        }
        if (Lodash.has(apiData, "status")) {
          if (apiData.message) {
            self.toastAlert(apiData.status, apiData.message);
          }
        }

        if (Lodash.has(apiData, "validations")) {
          self.showValidationError(apiData.validations);
        }
        callback(apiData.data, apiData.status);
      });
    });
  }

  async putApi(apiKey, callback, keys = {}, login = true) {
    var apiEndPoint = ApiEndPoints.getEndPoint(apiKey);
    var options = { method: "PUT" };
    if (login) {
      options["headers"] = await this.loginHeader();
    }

    if (Lodash.hasIn(keys, "params")) {
      apiEndPoint += QS.stringify(keys.params);
    }

    await fetch(apiEndPoint, options).then((response) => {
      response.json().then((apiData) => {
        callback(apiData.record);
      });
    });
  }

  getLodash(func, value = "", key = "") {
    if (value && key) {
      return Lodash[func](value, key);
    } else {
      return Lodash[func](value);
    }
  }

  async loginHeader() {
    // if(!this.isLogin()){
    //   this.redirectTo("login");
    // }
    return {
      "Content-Type": "application/json",
      "x-access-token": this.getToken(),
    };
  }

  setToken(token) {
    Storage.setLocalData("AuthIdentity", token);
  }

  getToken() {
    return Storage.getLocalData("AuthIdentity");
  }

  isLogin() {
    return Storage.getLocalData("AuthIdentity") ? true : false;
  }

  getAuthUserId() {
    if (this.isLogin()) {
      var decoded = jwt_decode(this.getToken());
      return decoded.response._id;
    } else {
      return "";
    }
  }

  logout() {
    const senderNamew = localStorage.getItem("AuthIdentity");
    const base64Url = senderNamew.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const UserId = JSON.parse(window.atob(base64));

    fetch(process.env.REACT_APP_SERVER + `/logout`, {
      method: "POST",
      body: JSON.stringify({
        id: UserId.response._id,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }).then(async (response) => {
      Storage.clearLocalData("AuthIdentity");
      window.location = `/login`;
    });
  }
}

export default Model;
