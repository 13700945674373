import React, { Component } from "react";
import General from "Helpers/General";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MyProfile from "Models/MyProfile";
import Moment from "react-moment";
import moment from "moment";
import Product from "Models/Product";
import DatePicker from "react-date-picker";
import { Link, Redirect } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import ScheduleProductModel from "Models/ScheduleProduct";
import { LinearProgress, TextField } from "@material-ui/core";
import ScheduleProductList from './ScheduleProductList';
import UpcomingLiveSchedule from "./UpcomingLiveSchedule";

export class UpcomingLiveScheduleList extends Component {

  constructor(props) {

      super(props);

      this.state = {
          headerLogo: localStorage.getItem("HeaderLogo"),
          userId: localStorage.getItem("UserData")
          ? JSON.parse(localStorage.getItem("UserData"))["_id"]
          : "",
          editLiveSchedule:false,
          showEditProductDropdown : false,
          editScheduleDate: "",
          editScheduleTime: "",
          editScheduleLength: "",
          isAllowPreBid: false,
          liveAuctionDurations: [],
          editScheduleSelectedProducts: [],
          editScheduleSelectedProductsId: [],
          upcomingLiveSchedule: [],
          products: localStorage.getItem("MyProducts")
            ? JSON.parse(localStorage.getItem("MyProducts"))
            : [],
          maxProductSize : 6,
          isLoading : false
      }

  }

  componentDidMount = () => {
    this.getAllScheduleProducts();
    this.getLiveAuctionDuration();
  };

  getAllScheduleProducts = () => {
    MyProfile.getApi(
      "API_GET_LIVE_SCHEDULE",
      async (response) => {
        this.setState({ upcomingLiveSchedule: response.upcomingLiveSchedule });
        this.setState({ isLoading: false });
      },
      true
    );
  };

  getLiveAuctionDuration = () => {
    Product.getApi("API_GET_LIVEAUCTION_DURATION", async (response, status) => {
      if (response) {
        this.setState({ liveAuctionDurations: response });
      }
    });
  };

  scheduleOnChangeHandler = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if(this.state.editScheduleDate && name == "editScheduleTime"){
      var date  = new Date(this.state.editScheduleDate);
      let timeArr = value.split(":");
      let hour = timeArr[0];
      let min = timeArr[1];
      date.setHours(hour);
      date.setMinutes(min);
      if(date <= new Date){
        return;
      }
    }

    this.setState({ [name]: value });

    if(name == "editScheduleLength"){
      let maxProCount = (value == "") ? 6 : 30/Number(value);
      this.setState({maxProductSize : maxProCount});
    }

  };

  checkBoxChangeHandler = (e) => {
    //e.preventDefault();
    const { name, checked } = e.target;
    this.setState({ [name]: checked });
  };

  changeStatusHandler = (e, id) => {
    e.preventDefault();

    confirmAlert({
      title: "Confirm to change status",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ isLoading: true });
            MyProfile.postApi(
              "API_CHANGE_STATUS_LIVE_SCHEDULE",
              { id },
              async (response) => {
                this.getAllScheduleProducts();
                this.props.getLiveStreamingProducts();
              },

              true
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  hideEditForm = (e) => {
    this.setState({ editLiveSchedule: false });
  };

  showEditProductDropdownHandler = async (event) => {
    event.preventDefault();
    let {
      editScheduleId,
      editScheduleDate,
      editScheduleTime,
      editScheduleLength
    } = this.state;

    if (
      (await ScheduleProductModel.checkValidation(
        await ScheduleProductModel.validationRules(),
        {
          editScheduleDate,
          editScheduleLength,
        }
      )) === true
    ) {
      if (editScheduleDate === "" || editScheduleTime === "") {
        MyProfile.toastAlert("error", "Please Select Date and Time");
      } else {
        this.setState({isLoading : true});

        if(typeof editScheduleDate !== "string"){
          editScheduleDate = editScheduleDate.getFullYear()  + "-" + (editScheduleDate.getMonth()+1) + "-" + editScheduleDate.getDate();
        }

        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        MyProfile.postApi(
        "API_LIVE_SCHEDULE_TIME_AVAILABLE_BY_USER",
        {
            id : editScheduleId,
            date: editScheduleDate,
            time: editScheduleTime,
            timezone:timezone
        },
        async (response,status) => {
            if (status == "success") {
                this.setState({ showEditProductDropdown: true });
                this.setState({isLoading : false});
            }else{
              this.setState({isLoading : false});
            }
        },
        true
        );
      }
    }
  };
  hideEditProductDropdownHandler = (e) => {
    e.preventDefault();
    this.setState({ showEditProductDropdown: false });
  };

  editLiveScheduleHandler = (e, id) => {
    const liveScheduleProduct = this.state.upcomingLiveSchedule.find(
      (product) => product._id === id
    );

    let productIds = [];

    liveScheduleProduct.productId.forEach((val) => {
      productIds.push(val._id);
    });

    MyProfile.postApi(
      "API_GET_USER_UPCOMING_AUCTION_PRODUCTS",
      {productIds:productIds},
      async (response) => {

        const selectedProducts = response.filter((item) => {
          return productIds.indexOf(item._id) !== -1;
        });

        let maxProCount = 30/Number(liveScheduleProduct.length);
        this.setState({
          editScheduleId: id,
          editScheduleSelectedProductsId: productIds,
          editScheduleSelectedProducts: selectedProducts,
          selectedEditLiveSchedule: liveScheduleProduct,
          editScheduleDate: moment(liveScheduleProduct.time).format("YYYY-MM-DD"),
          editScheduleTime: moment(liveScheduleProduct.time).format("HH:mm"),
          editScheduleLength: liveScheduleProduct.length,
          isAllowPreBid : liveScheduleProduct.isAllowPreBid,
          maxProductSize: maxProCount,
          products: response,
        });

        this.setState({ editLiveSchedule: true });

      },
      true
    );
    this.setState({ isLoading: false });
  };

  deleteLiveScheduleHandler = (e, id) => {
    e.preventDefault();

    confirmAlert({
      title: "Confirm to delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({ isLoading: true });
            MyProfile.postApi(
              "API_DELETE_LIVE_SCHEDULE",
              { id },
              async (response) => {
                this.props.getLiveStreamingProducts();
                this.getAllScheduleProducts();
                this.setState({ isLoading: false });
              },

              true
            );
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  scheduleEditProductHandler = (event, value) => {
    event.preventDefault();

    const {
      editScheduleLength
    } = this.state;

    let maxAd =  30/Number(editScheduleLength);

    let selectedProdcuts = [];
    let selectedProdcutIds = [];

    value.forEach((val) => {
      selectedProdcuts.push(val);
      selectedProdcutIds.push(val._id);
    });

    if(selectedProdcuts.length > maxAd){
      let msg = "You can select maximum "+ maxAd +" products";
      MyProfile.toastAlert("error", msg);
      return;
    }else{
      this.setState({
        editScheduleSelectedProducts: selectedProdcuts,
        editScheduleSelectedProductsId: selectedProdcutIds,
      });
    }
  };

  editScheduleCategoryHandler = (event, value) => {
    event.preventDefault();
    const { products } = this.state;
    let selectedCategoryIds = [];

    value.forEach((val) => {
      selectedCategoryIds.push(val._id);
    });

    const categoryProducts = products.filter((item) => {
      return selectedCategoryIds.indexOf(item.categoryId._id) !== -1;
    });

    this.setState({
      editSelectedCategories: selectedCategoryIds,
      editSelectedCategoryProducts: categoryProducts,
    });
  };

  updateLiveScheduleHandler = (e) => {
    e.preventDefault();
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let {
      products,
      editScheduleId,
      editScheduleDate,
      editScheduleTime,
      editScheduleLength,
      editScheduleSelectedProductsId,
      editScheduleSelectedProducts,
      isAllowPreBid
    } = this.state;

    var maxAd =  30/Number(editScheduleLength);

    let error = 0;

    if(editScheduleSelectedProducts && editScheduleSelectedProducts.length > 0){
      if(editScheduleSelectedProducts.length > maxAd){
        error++;
        let msg = "You can select maximum "+ maxAd +" products";
        MyProfile.toastAlert("error", msg);
      }else{
        for (let index = 0; index < editScheduleSelectedProducts.length; index++) {
          const product = editScheduleSelectedProducts[index];
          if(typeof product.starting_price == 'undefined' || product.starting_price <= 0){
            document.getElementById("starting_price_error_"+index).style.display = "block";
            error++;
          }else{
            document.getElementById("starting_price_error_"+index).style.display = "none";
          }

          if(typeof product.selling_estimate == 'undefined' || product.selling_estimate <= 0){
            document.getElementById("selling_estimate_error_"+index).style.display = "block";
            error++;
          }else{
            document.getElementById("selling_estimate_error_"+index).style.display = "none";

            if(Number(product.starting_price) > Number(product.selling_estimate)){
              document.getElementById("selling_estimate_price_error_"+index).style.display = "block";
              error++;
            }else{
              document.getElementById("selling_estimate_price_error_"+index).style.display = "none";
            }
          }
        }
      }
    }else{
      error++;
      MyProfile.toastAlert("error", "You can select minimum 1 or maximum 6 products");
    }

    if(error > 0){
      this.setState({isLoading:false});
      return;
    }else{

        if(typeof editScheduleDate !== "string"){
          editScheduleDate = editScheduleDate.getFullYear()  + "-" + (editScheduleDate.getMonth()+1) + "-" + editScheduleDate.getDate();
        }

        MyProfile.postApi(
          "API_UPDATE_LIVE_SCHEDULE",
          {
            id: editScheduleId,
            date: editScheduleDate,
            time: editScheduleTime,
            length: editScheduleLength,
            productId: editScheduleSelectedProductsId,
            products : editScheduleSelectedProducts,
            timezone:timezone,
            isAllowPreBid: isAllowPreBid
          },
          async (response) => {
            if (response) {
              this.setState({
                editScheduleId: "",
                editScheduleDate: "",
                editScheduleTime: "",
                editScheduleLength: "",
                isAllowPreBid : false,
                editScheduleSelectedProducts: [],
                editScheduleSelectedProductsId: [],
                showEditProductDropdown: false,
                editLiveSchedule: false,
              });
              this.getAllScheduleProducts();
              this.props.getAllScheduleProducts();
              this.props.getLiveStreamingProducts();
            }
          },
          true
        );
      
    }
  };

  render() {
    const { liveAuctionDurations, editScheduleSelectedProducts, editScheduleSelectedProductsId, upcomingLiveSchedule, products, editScheduleLength, isLoading } = this.state;
    return (
      <>
      {isLoading && (
        <div className="loader-wrapper" id="loader_img">
          <div className="loader">
            <img src={this.state.headerLogo} alt="" />
            <div className="material-spinner"></div>
          </div>
        </div>
      )}
       <div
        className="tab-pane active show"
        id="subtabpackage03"
        role="tabpanel"
        >
            {this.state.editLiveSchedule === true ? (
            <>
                {this.state.showEditProductDropdown ===
                false ? (
                <div className="Schedule_live Schedule_live_block ">
                    <div className="Schedule_live_heading text-left text-dark pb-3">
                    Schedule Live Streaming Auction{" "}
                    </div>
                    <div className="pb-2 live_Video">
                    <strong>
                        Time of live Video
                    </strong>
                    </div>
                    <div className="d-md-flex ">
                    <div className="Schedule_date_time mb-3 mb-md-0">
                        
                        <DatePicker
                        name="editScheduleDate"
                        onChange={(value) =>
                            this.setState({
                            editScheduleDate: value,
                            editScheduleTime : ""
                            })
                        }
                        minDate={new Date()}
                        value={
                            new Date(
                            this.state.editScheduleDate
                            )
                        }
                        />
                    </div>
                    <div className="Schedule_date_time mb-3 mb-md-0">
                        <TextField
                        type="time"
                        name="editScheduleTime"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={
                            this.scheduleOnChangeHandler
                        }
                        value={
                            this.state.editScheduleTime
                        }
                        style={{ width: "100%" }}
                        />
                    </div>
                    </div>

                    <div className="py-3">
                      <div className="Schedule_live_heading text-left text-dark auction_tooltip pb-3">
                        <span>Auction length Per advert</span>
                        <div className="tooltips">
                            <i className="fas fa-info-circle"></i>
                            <span className="tooltiptext">Note: Single advery auction will be
                                sold to the higest bidder at the end
                                of the auction see{" "}
                                <a href="#"> term & Condition </a>
                                you can only add maximum of {this.state.maxProductSize} ad's for 1 hour slot.
                            </span>
                        </div>
                      </div>
                      <div className="header-input-icon header-input-icon_block">
                          <select
                          className="header-input profile_input"
                          name="editScheduleLength"
                          onChange={
                              this
                              .scheduleOnChangeHandler
                          }
                          defaultValue={
                              this.state.editScheduleLength
                          }
                          >
                          <option
                              className="item"
                              value=""
                          >
                              Select Length
                          </option>
                          {liveAuctionDurations &&
                          liveAuctionDurations.map(
                              (d) =>(
                              <option
                                  value={d.duration}
                                  key={d._id}
                              >
                                  {d.duration} mins
                              </option>
                              )
                          )}
                          </select>
                      </div>
                    </div>
                    <div className="Schedule_live Schedule_live_block ">
                      <div className="Schedule_live_heading text-left text-dark pb-3">
                        Allow Pre-bidding{" "}
                      </div>
                      <label className="switch">
                        <input type="checkbox" name="isAllowPreBid" onChange={ this.checkBoxChangeHandler } checked={ this.state.isAllowPreBid } />
                        <span className="slider round"></span>
                      </label>
                    </div>

                    <div className="note_block mt-5">
                      Allow pre-bidding on the auction before going live <br/>
                      Note : If not enabled, buyers will only be able to bid on the auction during live aution,  
                      <a href="#"> see terms & Condition </a>
                    </div>

                    <div className="bottonsend text-center">
                    <button
                        type="button"
                        onClick={this.hideEditForm}
                        className="btnx btn-secondaryx mr-2"
                    >
                        Cancel
                    </button>
                    <button
                        type="button"
                        onClick={ this.showEditProductDropdownHandler
                        }
                        className="btnx btn-secondaryx"
                    >
                        Next
                    </button>
                    </div>
                </div>
                ) : (
                <>
                    <div className="py-3 row align-items-start next_block">
                    <div className="Schedule_live_heading text-left   col-lg-5">
                        {" "}
                        Add Ad listing to live
                        Streaming{" "}
                    </div>
                    <div className="col-lg-7">
                        <div className="header-input-icon header-input-icon_block w-100 ">
                        <Autocomplete
                            multiple
                            onChange={
                            this.scheduleEditProductHandler
                            }
                            options={products}
                            getOptionLabel={(
                            option
                            ) => option.productName}
                            value={
                            editScheduleSelectedProducts
                            }
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                label="Select Products"
                            />
                            )}
                        />
                        </div>
                    </div>{" "}
                    </div>
                    <div className="text-center">Or</div>
                    <div className="py-3 row align-items-start next_block">
                    <div className="Schedule_live_heading text-left   col-lg-5">
                        Post Ad Listing To Add To Live Auction
                    </div>

                    <div className="col-lg-7">
                        <div className="header-input-icon header-input-icon_block w-100 ">
                        <Link
                            to={`/post-an-ad?from_page=auction`}
                        ><button className="btn-sm btn-secondary-sm">
                                List an Item
                            </button>
                        </Link>
                        </div>
                    </div>
                    </div>
                    {this.state
                    .editSelectedCategoryProducts &&
                    this.state
                        .editSelectedCategoryProducts
                        .length > 0 && (
                        <div className="py-3 row align-items-start next_block">
                        <div className="Schedule_live_heading text-left   col-lg-5"></div>

                        <div className="col-lg-7">
                            <div className="header-input-icon header-input-icon_block w-100 ">
                            <Autocomplete
                                multiple
                                options={
                                this.state.editSelectedCategoryProducts
                                }
                                getOptionLabel={(
                                option
                                ) =>
                                option.productName
                                }
                                onChange={
                                this.scheduleEditProductHandler
                                }
                                renderInput={(
                                params
                                ) => (
                                <TextField
                                    {...params}
                                    variant="standard"
                                    label="Select Products"
                                />
                                )}
                            />
                            </div>
                            <div className="note_block pt-2">
                            Note: minimum of 1
                            products of maximum of 6
                            </div>
                        </div>
                        </div>
                    )}
                    {/* </div> */}

                    {editScheduleSelectedProducts &&
                    editScheduleSelectedProducts.map(
                        (product,index) => (
                        <ScheduleProductList
                            key={`product_${index}`}
                            index={index}
                            product={product}
                            scheduleLength={editScheduleLength}
                        />
                        )
                    )}

                    <div className="bottonsend text-center">
                    <button
                        type="button"
                        className="btnx btn-secondaryx mr-3"
                        onClick={
                        this
                            .hideEditProductDropdownHandler
                        }
                    >
                        Back
                    </button>
                    <button
                        type="button"
                        onClick={
                        this
                            .updateLiveScheduleHandler
                        }
                        className="btnx btn-secondaryx"
                    >
                        Update
                    </button>
                    </div>
                    
                </>
                )}
            </>
            ) : (
            <>
                {upcomingLiveSchedule &&
                upcomingLiveSchedule.length > 0 ? (
                upcomingLiveSchedule.map(
                    (product) => (
                    <UpcomingLiveSchedule
                        key={product._id}
                        liveSchedule={product}
                        editHandler={
                        this.editLiveScheduleHandler
                        }
                        deleteHandler={
                        this.deleteLiveScheduleHandler
                        }
                        changeStatusHandler={
                        this.changeStatusHandler
                        }
                        canEdit={true}
                    />
                    )
                )
                ) : (
                <div className="alert alert-danger col-lg-12">
                    <strong>Info!</strong> Nothing
                    here.
                </div>
                )}
            </>
            )}
        </div>
      </>
    );
  }
}

export default UpcomingLiveScheduleList;
