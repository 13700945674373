import React, { Component } from "react";
import SubCategory from "Models/SubCategory";
import PostAdForm from "Components/PostAnAdComponent/PostAdForm";
import ContentLoader from "react-content-loader";
import { Link } from "react-router-dom";

class PostAnAdComponent extends Component {
  constructor(props) {
    super(props);

    const subCat = localStorage.getItem("PostsubCategories")
      ? Object.assign(
          {},
          ...JSON.parse(localStorage.getItem("PostsubCategories")).filter(
            (item) => item.slug === props.match.params.subCategoryId
          )
        )
      : {};

        this.state = {
          subCategoryId: subCat ? subCat._id : "",
          categoryType: subCat && subCat.categoryId ? subCat.categoryId.slug : "",
          categoryId: subCat && subCat.categoryId ? subCat.categoryId._id : "",
          singleSubCategoryData: "",
        };
  }

  componentDidMount = () => {
    var self = this;
    let subCategoryId = this.state.subCategoryId;
    if (subCategoryId) {
      SubCategory.postApi(
        "APIS_GET_SUB_CATEGORY_DETAIL_BY_Id",
        { params: { subCategoryId } },
        async (response) => {
          self.setState({ categoryType: response.categoryId.slug });
          self.setState({ categoryId: response.categoryId._id });
          self.setState({ singleSubCategoryData: response });
        },
        false
      );
    }
  };

  render() {
    const { subCategoryId, categoryType } = this.state;
    return (
      <React.Fragment>
        {subCategoryId ? (
          <PostAdForm subCategoryId={subCategoryId} />
        ) : (
          <>
            <section className="product-header bg-light-dark">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-12">
                    <div className="breadcrumb-bx">
                      <div className="breadcrumb-bx-inner">
                        <Link to="/" className="btnx btn-primaryx">
                          <i className="fas fa-long-arrow-alt-left"></i> Back
                        </Link>
                        <ol className="breadcrumb mb-0 ml-3">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            List an Item
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="bg-light-dark">
              <ContentLoader
                speed={2}
                width={400}
                height={460}
                viewBox="0 0 400 460"
                backgroundColor="#f3f3f3"
                foregroundColor="#ecebeb"
                // {...props}
              >
                <rect x="130" y="5" rx="2" ry="2" width="150" height="10" />
                <rect x="20" y="20" rx="2" ry="2" width="350" height="50" />
                <rect x="20" y="75" rx="2" ry="2" width="350" height="70" />
                <rect x="20" y="150" rx="2" ry="2" width="350" height="50" />
                <rect x="20" y="205" rx="2" ry="2" width="350" height="30" />
                <rect x="20" y="240" rx="2" ry="2" width="350" height="50" />
                <rect x="20" y="295" rx="2" ry="2" width="350" height="50" />
                <rect x="20" y="350" rx="2" ry="2" width="350" height="70" />
                <rect x="20" y="435" rx="2" ry="2" width="350" height="70" />
                {/* <rect x="58" y="34" rx="2" ry="2" width="140" height="10" /> */}
              </ContentLoader>
            </section>
          </>
        )}
      </React.Fragment>
    );
  }
}

export default PostAnAdComponent;
