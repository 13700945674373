import React, { Component } from "react";
import { useState } from 'react';
import { connect } from "react-redux";
import { Picker } from "emoji-mart";
import General from "Helpers/General";
import Quickblox from "Models/Quickblox";
import Product from "Models/Product";
import ScheduleCall from "Models/ScheduleCall";
import QB, { playCallAudio, stopCallAudio } from "Helpers/Quickblox";
import Moment from "react-moment";
import Countdown from "react-countdown";
import moment from "moment";
import Zoom from "react-medium-image-zoom";
import { SetOutgoingCallState, SetQuickbloxSession, SetScheduleMeetingState } from "Helpers/Actions";
import { Link } from "react-router-dom";
const axios = require("axios");

class MessagesComponent extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.msgDivRef = React.createRef();
    this.state = {
      headerLogo: localStorage.getItem("HeaderLogo"),
      // contactList: props.contactList,
      contactList: [],
      isLoading: false,
      selectedUser: null,
      selectedChannel: null,
      selectedUserDetails: null,
      addPosted: 0,
      messages: [],
      textMessage: "",
      image: null,
      selectEmo: false,
      searchText: "",
      email: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData")).email
        : "",
      firstName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData")).firstName
        : "",
      lastName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData")).lastName
        : "",
      timerInterval: null,
      minutes: 15, // Initial call duration in minutes
      seconds: 0,  // Initial call duration in seconds
      scheduleCallList: [],
      callTimeOver: false,
      toUserId: "",
      fromUserId: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["_id"]
        : "",
      fromUserName: localStorage.getItem("UserData")
        ? JSON.parse(localStorage.getItem("UserData"))["firstName"] + " " + JSON.parse(localStorage.getItem("UserData"))["lastName"]
        : "",
      callAccepted: false
    };
  }

  componentDidMount = () => {
    QB.chat.onMessageListener = this.onMessage;
    console.log("contactList", this.state.contactList);
    // this.disconnectFromChatServer();
    // this.configureQuickblox();
    this.setState({ isLoading: true });
    setTimeout(() => {
      this.setState({ isLoading: true });
      this.getChatDataList();
    }, 5000);
    // this.connectToChatServer({ userId: result.id, password: "password" }, data);
  };

  componentWillUnmount() {
    // Call the method to disconnect from QuickBlox chat service when the component unmounts
    // this.disconnectFromChatServer();
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.scheduleMeetingState.updateInList == true) {
      this.getScheduledEvents();
      this.props.SetScheduleMeetingState({ updateInList: false });
    }
  };

  countermsg = () => {
    QB.chat.dialog.list({ limit: 1 }, (error, dialogs) => {
      if (!error) {
        let dialog = dialogs.items[0];
        let unreadCount = dialog.unread_messages_count;
        this.props.SetQuickbloxUnreadMessage(unreadCount);
        this.setState({ unreadCount: unreadCount });
      }
    });
  }

  getChatDataList = () => {
    var self = this;
    const { email } = this.state;
    Quickblox.postApi(
      "API_QUICKBLOX_GET_USER_LOGIN", { login_id: email },
      async (response) => {
        if (response) {
          if (response.user.id) {
            console.log("getChatDataList", response.user);
            try {
              QB.chat.roster.add(response.user.id, function (res) {
                console.log("getChatDataList", response.user);
                self.getFriendList();
                self.getUsersList();
              });
            } catch (error) {
              console.log("chat error", error);
            }
          }
        } else {
          console.log("getChatDataList");
        }
        console.log("response-node", response);
      },
      true
    );
  };

  getFriendList = () => {
    QB.chat.roster.get((contactlist) => {
      const ids = Object.keys(contactlist);
      const newIds = ids.map((id) => Number(id));
      this.getUsersList(newIds);
    });
  };

  getUsersList = (ids = []) => {
    QB.users.listUsers(
      {
        page: 1,
        per_page: 200,
      },
      (err, result) => {
        console.log("result-getUsersList", result);
        if (!err) {

          if (localStorage.getItem("QB_USER")) {
            const LoggedInUser = JSON.parse(localStorage.getItem("QB_USER"));

            let contacts = [];
            let tempArr = [];
            for (let userObj of result.items) {
              if (LoggedInUser.id !== userObj.user.id && !tempArr.includes(userObj.user.id)) {
                contacts.push(userObj);
                tempArr.push(userObj.user.id);
              }
            }

            const FriendList = contacts.filter(
              (contact) => ids.includes(contact.user.id)
            );
            this.setState({
              contactList: FriendList
            });
            console.log("FriendList", FriendList);
            localStorage.setItem("QB_CONTACT_LIST", JSON.stringify(FriendList));
            this.setState({ isLoading: false });
            // this.props.SetContactList(FriendList);
          }
        } else {
          console.log("err-getUsersList", err);
        }
      }
    );
  };

  addEmoji = (emoji) => {
    this.setState({
      textMessage: this.state.textMessage + emoji.native,
      selectEmo: false,
    });
  };

  closeMessageBoxHandler = () => {
    this.setState({
      selectedUser: null,
      selectedChannel: null,
      selectedUserDetails: null,
      messages: [],
      textMessage: "",
      image: null,
      selectEmo: false,
    });
  };

  adPostedStatus = () => {
    //console.log("hi adpost")
    Product.postApi(
      "API_GET_AD_POSTED_STATUS",
      { userId: this.state.selectedUserDetails[0]._id },
      async (response, status) => {
        if (status === "success") {
          this.setState({ addPosted: 1 });
        } else {
          this.setState({ addPosted: 0 });
        }
      },
      true
    );
  }

  changeStatus = (id, status, name, touserid) => {
    ScheduleCall.postApi(
      "API_SET_CALL_STATUS",
      {
        id: id,
        callStatus: status,
        name: name,
        touserid: touserid
      },
      async (response, data) => {
        if (data === "success") {
          this.getScheduledEvents();
        }
      },
      true
    );
  }

  deleteScheduledCall = (id) => {
    ScheduleCall.postApi(
      "API_DELETE_SCHEDULED_CALL",
      {
        id: id
      },
      async (response, data) => {
        if (data === "success") {
          this.getScheduledEvents();
        }
      },
      true
    );
  }

  handleSelectedProfile = (email) => {
    fetch(process.env.REACT_APP_ApiBaseUrl + `getSelectedUser`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
      .then(async (response) => {
        if (response && response !== undefined) {
          let data = await response.json();
          if (data && data.length) {
            this.setState({ selectedUserDetails: data });
            this.getScheduledEvents();
            this.adPostedStatus();
          }
        }
      }).catch((err) => {
        console.log(err)
      })
  };

  getScheduledEvents = () => {
    // console.log("getevnt touserId", this.state.selectedUserDetails[0]._id, this.state.fromUserId);
    ScheduleCall.postApi(
      "API_GET_SCHEDULED_CALL",
      {
        touserId: this.state.selectedUserDetails[0]._id,
        fromUserId: this.state.fromUserId
      },
      async (response) => {
        this.setState({ scheduleCallList: response });
        // console.log("success",response)
        if (response.length > 0) {
          let scheduleDate = response[0].scheduleDate;
          let scheduleEndTime = response[0].scheduleEndTime;
          let endDate = this.parseScheduledEndTime(scheduleDate, scheduleEndTime);
          const currentTime = new Date();
          if (currentTime > endDate) {
            this.setState({ callTimeOver: true });
          } else if (response[0].callStatus == 1) {
            this.setState({ callAccepted: true })
          }
        }


      },
      true
    );

  }

  // scheduleCallContactList = () => {
  //   if(this.state.scheduleCallList){
  //     console.log("hello");
  //   }
  // }

  selectChannelHandler = (userId) => {
    // console.log("userId",userId)
    const selectedUser = JSON.parse(
      localStorage.getItem("QB_CONTACT_LIST")
    ).filter((u) => u.user.id === userId);

    if (selectedUser && selectedUser.length > 0) {
      // console.log("selectedUser",selectedUser)
      this.setState({ selectedUser: selectedUser[0].user });
      this.handleSelectedProfile(selectedUser[0].user.login);
    }

    let params = {
      type: 3,
      occupants_ids: [userId],
    };

    QB.chat.dialog.create(params, (error, dialog) => {
      if (!error) {
        // console.log("dialog",dialog)
        this.setState({ selectedChannel: dialog });
        //   load Messages
        this.loadMessages(dialog._id);
      } else {
        console.log("Dialog Create Error", error);
      }
    });
  };

  loadMessages = (dialogId) => {
    let params = {
      chat_dialog_id: dialogId,
      sort_asc: "date_sent",
    };

    QB.chat.message.list(params, (err, messages) => {
      if (!err) {

        this.setState({ messages: messages.items }, () => {
          if (messages.items.length > 0) {
            this.msgDivRef.current?.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest",
            });
          }
        });
      } else {
        console.log("Message Load Error");
      }
    });
  };

  sendMessageHandler = (e) => {
    e.preventDefault();
    if (this.state.textMessage || this.state.image) {
      const inputFile = document.getElementsByName("image")[0].files[0];
      if (inputFile) {
        let fileParams = {
          name: inputFile.name,
          file: inputFile,
          type: inputFile.type,
          size: inputFile.size,
          public: false,
        };

        QB.content.createAndUpload(fileParams, (error, result) => {
          // console.log("result",result)
          let message = {
            type: "chat",
            body: this.state.textMessage,
            extension: {
              save_to_history: 1,
              dialog_id: this.state.selectedChannel._id,
              attachments: [{ id: result.uid, type: fileParams.type }],
            },
            markable: 1,
          };

          try {
            message.id = QB.chat.send(this.state.selectedUser?.id, message);
            this.setState({ textMessage: "", image: "" });
            this.fileInputRef.current.value = "";
            this.loadMessages(this.state.selectedChannel._id);
          } catch (e) {
            console.log(e);
            if (e.name === "ChatNotConnectedError") {
              // not connected to chat
            }
          }
        });
      } else {
        let message = {
          type: "chat",
          body: this.state.textMessage,
          extension: {
            save_to_history: 1,
            dialog_id: this.state.selectedChannel._id,
          },
          markable: 1,
        };

        try {
          message.id = QB.chat.send(this.state.selectedUser?.id, message);
          this.setState({ textMessage: "", image: "" });
          this.fileInputRef.current.value = "";
          this.loadMessages(this.state.selectedChannel._id);
        } catch (e) {
          console.log(e);
          if (e.name === "ChatNotConnectedError") {
            // not connected to chat
          }
        }
      }
    } else {
      // else part here
    }
  };

  onMessage = (userId, message) => {
    if (this.state.selectedUser?.id === userId && this.state.selectedChannel) {
      this.loadMessages(this.state.selectedChannel._id);
    }
  };

  startVideoCall = () => {
    if (this.state.selectedUser) {
      this.initiateCall(this.state.selectedUser.id, 1);
    }
  };

  startAudioCall = () => {
    if (this.state.selectedUser) {
      this.initiateCall(this.state.selectedUser.id, 2);
    }
  };

  initiateCall = (userId, callType) => {

    var calleesIds = [userId]; // Users' ids
    var sessionType = (callType === 2) ? QB.webrtc.CallType.AUDIO : QB.webrtc.CallType.VIDEO;
    var additionalOptions = {};

    var session = QB.webrtc.createNewSession(
      calleesIds,
      sessionType,
      null,
      additionalOptions
    );

    let mediaParams = {
      audio: true,
      video: session.callType === 2 ? false : { min: 320, ideal: 720, max: 1024 },
      options: {
        muted: true,
        mirror: (session.callType === 1) ? true : false,
      },
      elemId: "localVideoElem",
    };

    this.props.SetQuickbloxSession(session);

    session.getUserMedia(mediaParams, (error, stream) => {
      if (error) {
        this.props.SetQuickbloxSession(null);
        console.log("Get user stream error => ", error);
        alert(error);
      } else {
        if (stream) {
          session.attachMediaStream('localVideoElem', stream);
          const callee = JSON.parse(localStorage.getItem("QB_USER"));
          session.call({ callee: callee?.full_name, caller_id: callee?.id }, (error1) => {
            if (!error1) {
              this.props.SetOutgoingCallState(true);
              playCallAudio();
            } else {
              console.log("error1", error1);
            }
          });
        }
      }
    });
  };

  combinedTimestamp = (date, time) => {
    const inputDate = date;
    const inputTime = time;

    // Combine date and time using moment.js
    const combinedTimestamp = moment(inputDate + ' ' + inputTime, "YYYY-MM-DD HH:mm").toDate();
    return combinedTimestamp;
  }

  callTimer = (days, hours, minutes, seconds) => {

    if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
      // console.log("Time is 00:00:00");
      return false;
    } else {
      const timeLeftString = days + "d " + hours + "h:" + minutes + "m:" + seconds + "s to go";
      return timeLeftString;
    }
  }


  _handleKeyPress = (e) => {
    if (e.charCode === 13) {
      this.sendMessageHandler(e);
    }
  };

  scheduleCall = () => {
    this.props.SetScheduleMeetingState({ state: true, toQbUserId: this.state.selectedUser.id, toUserId: this.state.selectedUserDetails[0]._id, username: this.state.selectedUserDetails[0].firstName + " " + this.state.selectedUserDetails[0].lastName, toUserEmail: this.state.selectedUserDetails[0].email });
  };
  parseScheduledEndTime = (scheduleDate, scheduleEndTime) => {
    const dateArr = scheduleDate.split('T');
    const daeTime = dateArr[0] + ' ' + scheduleEndTime;
    const endDate = new Date(daeTime);
    return endDate;
  }

  render() {
    const {
      contactList,
      selectedChannel,
      selectedUser,
      messages,
      selectEmo,
      textMessage,
      selectedUserDetails,
      minutes,
      seconds,
      scheduleCallList,
      isLoading
    } = this.state;
    // console.log("contactList", contactList, this.state);

    return (
      <React.Fragment>
        {isLoading && (
          <div className="loader-wrapper" id="loader_img">
            <div className="loader">
              <img src={this.state.headerLogo} alt="" />
              <div className="material-spinner"></div>
            </div>
          </div>
        )}
        <section className="product-header">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="breadcrumb-bx">
                  <div className="breadcrumb-bx-inner">
                    <a
                      href={void (0)}
                      className="btnx btn-primaryx"
                      onClick={() => this.props.history.goBack()}
                    >
                      <i className="fas fa-long-arrow-alt-left"></i> Back
                    </a>
                    <ol className="breadcrumb mb-0 ml-3">
                      <li className="breadcrumb-item">
                        <Link to="/">Home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Chat
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="chatmainS pagemain_chat02">
          <div className="container">
            <div className="row">
              <div className="col-sm-9 chatdeskfull_rig">
                <div className="transparent-layer"></div>
                <div className="mesagebt_mobS">
                  <a
                    href={void (0)}
                    className="btnunique_mess"
                    onClick={() => this.props.history.goBack()}
                  >
                    <i className="fas fa-long-arrow-alt-left"></i>{" "}
                    &nbsp;Messages
                  </a>
                </div>
                <div className="chatandlist">
                  <div className="rightchat_list px-3 px-md-0">
                    <div className="chatTeam_head">
                      <div className="listleftH">
                        {/* <div className="down_filtdrop">
                          <select className="">
                            <option value="">Unread</option>
                            <option value="">Active</option>
                          </select>
                        </div> */}
                      </div>
                      <div className="listrightH">
                        <a
                          href={void (0)}
                          data-toggle="collapse"
                          data-target="#demo"
                          className="icons_messager"
                        >
                          <i className="fas fa-search"></i>
                        </a>
                        <div className="downserch collapse" id="demo">
                          <input
                            type="text"
                            className=""
                            placeholder="Search..."
                            onChange={(e) =>
                              this.setState({ searchText: e.target.value })
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="chat_teambx mCustomScrollbar chat_box_mobile"
                      data-mcs-theme="dark"
                    >
                      {contactList ? (
                        contactList
                          .filter((contact) =>
                            contact.user.full_name.includes(
                              this.state.searchText
                            )
                          )
                          .map((channel, index) => (
                            <div
                              className="chatteammem_bx"
                              key={index}
                              onClick={() =>
                                this.selectChannelHandler(channel.user.id)
                              }
                            >
                              <div className="chatuser">
                                <div className="userimgC">
                                  <img
                                    src={
                                      channel.user.custom_data &&
                                        JSON.parse(channel.user.custom_data)
                                          .profilePicture
                                        ? General.showImageNew(
                                          "profile",
                                          JSON.parse(channel.user.custom_data)
                                            .profilePicture
                                        )
                                        : "/images/user.png"
                                    }
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="chatusername">
                                <p>{channel.user.full_name}</p>
                              </div>
                              <div className="chatu_time">
                                <p>
                                  {moment(channel.user.last_request_at).format(
                                    "HH:mm"
                                  )}
                                </p>
                              </div>
                              <a href={void (0)} className="onhovelink"></a>
                            </div>
                          ))
                      ) : (
                        <p>There is no channel</p>
                      )}
                    </div>
                  </div>

                  {selectedUser && selectedChannel && (
                    <div className="chatboxL">
                      <div className="chatbox_head">
                        <div className="chatleftH_title">
                          <h6>
                            <a
                              href={void (0)}
                              className="backonlymes_chatmob"
                              onClick={this.closeMessageBoxHandler}
                            >
                              <i className="fas fa-arrow-left"></i>{" "}
                            </a>{" "}
                            <img src="/images/messanger.png" alt="Icon" />{" "}
                            <span className="catbbss_titl">
                              {selectedUser?.full_name}
                            </span>
                            <p>
                              <span>Last seen</span>{" "}
                              {moment(selectedUser?.last_request_at).fromNow()}{" "}
                              <span>
                                |{" "}
                                {moment(selectedUser?.last_request_at).format(
                                  "MMM Do, h:mm:ss a"
                                )}
                              </span>
                            </p>
                          </h6>
                        </div>
                        <div className="chatclose">
                          {(this.state.addPosted == 1 && selectedUserDetails && selectedUserDetails.length > 0 && selectedUserDetails[0]?.isOnline == true && selectedUserDetails[0]?.goesOffline == 1) &&
                            <div>
                              <a href={void (0)} onClick={this.startVideoCall}>
                                <img src="/images/videoicon-white.png" alt="Icon" />
                              </a>{" "}
                              &nbsp;
                              <a href={void (0)} onClick={this.startAudioCall}>
                                <img
                                  src="/images/phone-icon-white.png"
                                  alt="Icon"
                                />
                              </a>
                            </div>
                          }
                          <a
                            href={void (0)}
                            id="chatmenu_showP"
                            className="backonlymes_chatmob"
                          >
                            <i className="fas fa-ellipsis-v"></i>
                          </a>
                        </div>
                      </div>
                      <div
                        className="chatingbx mCustomScrollbar"
                        data-mcs-theme="dark"
                      >
                        <div className="chatmain_perent">
                          <div className="sent_sidemain-top1">
                            {messages && messages.length ? (
                              messages.map((msg, index) => (
                                <div ref={this.msgDivRef} key={index}>
                                  <div className="time_ofchat">
                                    {moment(msg.created_at).format(
                                      "MMM Do, h:mm:ss a"
                                    )}
                                  </div>
                                  {msg.sender_id ===
                                    JSON.parse(localStorage.getItem("QB_USER"))
                                      .id ? (
                                    <div className="myside_chat">
                                      <div className="chatboc_imgg"></div>
                                      <div className="chat_textdesc">
                                        <span className="chat_hello">
                                          {msg.message}
                                          {msg.attachments &&
                                            msg.attachments.length > 0 && (
                                              <Zoom>
                                                <img
                                                  src={QB.content.privateUrl(
                                                    msg.attachments[0].id
                                                  )}
                                                  width={50}
                                                  height={50}
                                                  alt=""
                                                />
                                              </Zoom>
                                            )}
                                        </span>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="sent_sidemain">
                                      <div className="chatboc_imgg">
                                        <img
                                          src={
                                            selectedUser &&
                                              selectedUser.custom_data &&
                                              JSON.parse(selectedUser.custom_data)
                                                .profilePicture
                                              ? General.showImageNew(
                                                "profile",
                                                JSON.parse(
                                                  selectedUser.custom_data
                                                ).profilePicture
                                              )
                                              : "/images/user.png"
                                          }
                                          alt=""
                                        />
                                      </div>
                                      <div className="chat_textdesc">
                                        <span className="chat_hello">
                                          {msg.message}
                                          {msg.attachments &&
                                            msg.attachments.length > 0 && (
                                              <Zoom>
                                                <img
                                                  src={QB.content.privateUrl(
                                                    msg.attachments[0].id
                                                  )}
                                                  width={50}
                                                  height={50}
                                                  alt=""
                                                />
                                              </Zoom>
                                            )}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))
                            ) : (
                              <p className="start_conversation">
                                Once you start a new conversation, you’ll see it
                                listed here.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="chatbox_footer .bg-light-dark">
                        <div className="writesomt_full">
                          {/* <input
                            type="text"
                            name="text"
                            autocomplete="off"
                            onChange={(e) =>
                              this.setState({ textMessage: e.target.value })
                            }
                            onKeyPress={this._handleKeyPress}
                            value={textMessage}
                            placeholder="Write something . . ."
                          /> */}

                          <textarea
                            className="w-100 border-0 py-2"
                            rows="2"
                            name="text"
                            placeholder="Write something . . ."
                            autoComplete="off"
                            value={textMessage}
                            onChange={(e) =>
                              this.setState({ textMessage: e.target.value })
                            }
                            onKeyPress={this._handleKeyPress}
                          ></textarea>
                        </div>
                        <div className="writesomt_full_send">
                          <div className="fulsend_left">
                            <a
                              href={void (0)}
                              onClick={() =>
                                this.setState({
                                  selectEmo: !selectEmo,
                                })
                              }
                              className="writesomt_smiley"
                            >
                              <i
                                className="far fa-smile"
                                style={{
                                  color: selectEmo ? "#249ee4" : "",
                                }}
                              ></i>
                            </a>
                            {selectEmo && (
                              <Picker
                                onEmojiSelected
                                onSelect={this.addEmoji}
                                style={{
                                  position: "absolute",
                                  bottom: "5px",
                                  // right: "10px",
                                }}
                              />
                            )}
                            {/* <a href>
                              <i className="fas fa-paperclip"></i>
                            </a> */}
                            <div className="file-upload-button">
                              <input
                                type="file"
                                name="image"
                                ref={this.fileInputRef}
                                onChange={(e) =>
                                  this.setState({ image: e.target.value })
                                }
                                id="validationCustom01"
                              />
                              <button className="btn">
                                {this.state.image ? (
                                  <i
                                    className="fa fa-paperclip"
                                    style={{ color: "red" }}
                                  ></i>
                                ) : (
                                  <i
                                    className="fa fa-paperclip"
                                    aria-hidden="true"
                                  ></i>
                                )}
                              </button>
                            </div>
                          </div>
                          <div className="fulsend_right">
                            <button onClick={this.sendMessageHandler}>
                              <i className="fas fa-paper-plane"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {selectedUserDetails && (
                <div className="col-sm-3 prdetail_rightchat">
                  <div className="mesagebt_mobS">
                    <a href={void (0)} className="btnunique_mess">
                      <i className="fas fa-long-arrow-alt-left"></i> &nbsp;Chat
                      Details
                    </a>
                  </div>
                  <div className="product-detail-owner-bx">
                    <div className="detail-owner-header chatnewmotor_second">
                      <Link
                        to={`/user/all-products/${selectedUserDetails[0]._id}`}
                        className="see-all-btn font-size-14"
                      >
                        All Items <i className="fas fa-arrow-right"></i>
                      </Link>
                      {/* <time>Posted, 15 days, 12:30</time> */}
                    </div>

                    <div className="detail-owner-name-bx clearfix">
                      <div className="detail-owner-img">
                        {selectedUserDetails[0].profilePicture ? (
                          <img
                            src={General.showImageNew(
                              "profile",
                              selectedUserDetails[0].profilePicture
                            )}
                            alt={selectedUserDetails[0].profilePicture}
                          />
                        ) : (
                          <img src="/images/user.png" alt="" />
                        )}
                        {/* <span className="live-dot"></span> */}
                      </div>
                      <div className="detail-owner-name">
                        <h2>
                          {selectedUserDetails[0].firstName +
                            " " +
                            selectedUserDetails[0].lastName}
                        </h2>
                        <p>
                          <span>Last Seen:</span>{" "}
                          {moment(selectedUser.last_request_at).fromNow()}
                        </p>
                        {/* <div className="rated-star">
                          <span>Rated</span>
                          <span>
                            <strong>5.0</strong>
                          </span>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div> */}
                      </div>
                    </div>
                    {(scheduleCallList && scheduleCallList.length > 0) &&

                      <div className="listing_card">
                        <h3 className="box_heading">Upcoming Video Chat</h3>

                        <ul className="chat_list_box">

                          {scheduleCallList.map((call, index) => (
                            //console.log("scheduleCallList",scheduleCallList)
                            //  call.scheduleEndTime < new Date() ? (
                            <li>
                              <span className="user_name">{call.fullname}</span>
                              <div className="right_block_details">
                                {call?.scheduleDate && (
                                  <Moment format="YYYY-MM-DD">{call?.scheduleDate}</Moment>
                                )}
                                <span>{call.scheduleTime} - {call.scheduleEndTime}</span>
                                {(call.callStatus == 0 && call.to_user_id == this.state.fromUserId) &&
                                  <div className="accept_denied_btn">
                                    <a href={void (0)} onClick={() => this.changeStatus(call._id, 1, this.state.fromUserName, this.state.selectedUserDetails[0]._id)}>Accept</a>
                                    <a className="denied_btn" href={void (0)} onClick={() => this.changeStatus(call._id, 2, this.state.fromUserName, this.state.selectedUserDetails[0]._id)}>Denied</a>
                                  </div>}
                                {
                                  (call.callStatus == 1) &&
                                  <div className="">
                                    <span>
                                      <Countdown
                                        date={this.combinedTimestamp(call.scheduleDate, call.scheduleTime)}
                                        renderer={({ days, hours, minutes, seconds }) => (
                                          <>
                                            {this.callTimer(days, hours, minutes, seconds) ?
                                              (<><span className="accept_label btn-success">Accepted</span><br />
                                                <time>
                                                  {this.callTimer(days, hours, minutes, seconds)}
                                                </time>
                                              </>) : ((() => {
                                                const currentTime = new Date();
                                                const scheduledEndTime = this.parseScheduledEndTime(call.scheduleDate, call.scheduleEndTime);
                                                if (currentTime < scheduledEndTime) {
                                                  return (
                                                    <div className="call_btn">
                                                      <a className="chatbox_head" href={void (0)} onClick={this.startVideoCall}>
                                                        <img src="/images/videoicon-white.png" alt="Icon" />
                                                      </a>{" "}
                                                      &nbsp;
                                                      <a className="chatbox_head" href={void (0)} onClick={this.startAudioCall}>
                                                        <img src="/images/phone-icon-white.png" alt="Icon" />
                                                      </a>
                                                    </div>
                                                  );
                                                } else {
                                                  return null;
                                                }
                                              })())}
                                          </>
                                        )}
                                      />
                                    </span>
                                  </div>

                                }
                                {
                                  (call.callStatus == 2) &&
                                  <div className="">
                                    <span className="accept_label btn-danger">Denied</span>
                                  </div>
                                }
                              </div>
                              {call.from_user_id == this.state.fromUserId && <div class="dropdown">
                                <button class="btn btn-secondary dropdown-toggle btn_drop" type="button" data-toggle="dropdown" aria-expanded="false">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  </svg>
                                </button>
                                <div class="dropdown-menu">
                                  <a class="dropdown-item" href={void (0)} onClick={() => this.deleteScheduledCall(call._id)}>Delete</a>
                                </div>
                              </div>}
                            </li>

                            //  ) : null
                          ))}
                        </ul>
                      </div>
                    }
                  </div>
                  {
                    (((!(!this.state.callTimeOver && this.state.callAccepted)) || this.state.callTimeOver) && this.state.addPosted == 1 && selectedUserDetails[0]?.isOnline == false) &&
                    <div className="vedio_chat_btn">
                      <a className="chat_btn" href={void (0)} onClick={() => this.scheduleCall()}>Schedule Video Chat</a>
                    </div>
                  }
                </div>
              )}

            </div>
          </div>
        </section>
        <section className="bg-light-dark">
          <div></div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contactList: state.MESSAGE_BOX.contactList,
    scheduleMeetingState: state.VIDEO_CALL.scheduleMeetingState
  };
};

export default connect(mapStateToProps, {
  SetOutgoingCallState,
  SetScheduleMeetingState,
  SetQuickbloxSession,
})(MessagesComponent);
